import axios from 'axios';
import { clearChatSession } from '../utils/chatUtils';

const axiosInstance = axios.create({
  baseURL: 'https://server.meetdoris.com',
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'X-Client-Type': 'web'
  },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const CHAT_ERRORS = {
  CONNECTION: 'Failed to connect to chat service',
  THREAD_CREATE: 'Failed to create chat thread',
  THREAD_END: 'Failed to end chat session',
  MESSAGE_SEND: 'Failed to send message',
  SESSION_EXPIRED: 'Chat session has expired',
  CONCURRENT_RUN: 'A message is currently being processed',
};

axiosInstance.interceptors.request.use(
  (config) => {
    try {
      const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrf_access_token='))
        ?.split('=')[1];

      if (csrfToken) {
        config.headers['X-CSRF-TOKEN'] = csrfToken;
      }

      // Add timeout for chat endpoints
      if (config.url.includes('/api/chat')) {
        config.timeout = config.timeout || 180000; // 3 minute timeout for chat requests
      }
    } catch (error) {
      console.error('Error retrieving tokens:', error);
    }
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const isChatRequest = originalRequest.url.includes('/api/chat');

    // Handle chat-specific errors
    if (isChatRequest) {
      if (error.response?.status === 409) {
        console.warn('Chat concurrent run detected');
        return Promise.reject(new Error(CHAT_ERRORS.CONCURRENT_RUN));
      }

      if (error.response?.status === 404) {
        console.warn('Chat thread not found');
        clearChatSession();
        return Promise.reject(new Error('Chat session expired or not found'));
      }

      if (error.code === 'ECONNABORTED') {
        console.error('Chat request timed out');
        return Promise.reject(new Error('Chat request timed out. Please try again.'));
      }
    }

    // Handle token refresh
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => axiosInstance(originalRequest))
          .catch((err) => {
            if (isChatRequest) {
              clearChatSession();
            }
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const response = await axiosInstance.post('/api/user/refresh_token');
        processQueue(null);
        isRefreshing = false;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError);
        isRefreshing = false;
        if (isChatRequest) {
          clearChatSession();
        }
        console.warn('Token refresh failed, redirecting to login');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    // Other error handling
    if (error.response) {
      console.error('API error response:', {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers,
        endpoint: originalRequest.url,
      });

      if (error.response.status === 403) {
        console.warn('Forbidden! You don\'t have permission for this action.');
        if (isChatRequest) {
          clearChatSession();
        }
      } else if (error.response.status === 500) {
        console.error('Server error! Please try again later.');
        if (isChatRequest) {
          return Promise.reject(new Error('Chat service unavailable. Please try again later.'));
        }
      }
    } else if (error.request) {
      console.error('No response from API:', error.request);
      if (isChatRequest) {
        return Promise.reject(new Error(CHAT_ERRORS.CONNECTION));
      }
    } else {
      console.error('Error setting up the API request:', error.message);
    }

    // Enhance error messages for chat requests
    if (isChatRequest) {
      let errorMessage = 'An error occurred with the chat service.';
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }
      return Promise.reject(new Error(errorMessage));
    }

    return Promise.reject(error);
  }
);

// Add request retry functionality for chat endpoints
axiosInstance.chat = {
  async retryRequest(config, maxRetries = 3) {
    let retries = 0;
    while (retries < maxRetries) {
      try {
        return await axiosInstance(config);
      } catch (error) {
        retries++;
        if (retries === maxRetries) {
          throw error;
        }
        // Exponential backoff
        await new Promise(resolve => setTimeout(resolve, Math.pow(2, retries) * 1000));
      }
    }
  }
};

export default axiosInstance;