import { useState, useEffect } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { Button } from "../components/ui/button"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/ui/dialog"
import { useAuth } from "../contexts/AuthContext"
import axiosInstance from "../api/axiosInstance"
import {
  ChevronLeft,
  ChevronRight,
  Plus,
  LogOut,
  Sparkles,
  Calendar,
  Target,
  CheckSquare,
  HeartPulse,
  BarChart3,
  Settings,
  Sun,
  Moon,
  LayoutDashboard,
    HelpCircle,
} from "lucide-react"
import TaskForm from "../components/TaskForm"
import { cn } from "../lib/utils"
import { useTheme } from "../ThemeProvider"

const MAX_FREE_TASKS = 5

function Sidebar({ isCollapsed, toggleCollapse }) {
  const { auth, logout } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const { theme, toggleTheme } = useTheme()
  const [tasks, setTasks] = useState([])

  const fetchTasks = async () => {
    try {
      const response = await axiosInstance.get("/api/tasks")
      const allTasks = [...response.data.scheduled, ...response.data.unscheduled]
        .filter(task => !task.completed)
      setTasks(allTasks)
    } catch (err) {
      console.error("Error fetching tasks:", err)
    }
  }

  useEffect(() => {
    fetchTasks()
  }, [])

  const isAtTaskLimit = !auth.isSubscribed && tasks.length >= MAX_FREE_TASKS

  const handleAddTaskClick = () => {
    if (isAtTaskLimit) {
      navigate("/subscribe")
    } else {
      setCreateModalOpen(true)
    }
  }

  const isActivePath = (path) => {
    return location.pathname === path
  }

  const mainMenuItems = [
    {
      path: "/dashboard",
      icon: LayoutDashboard,
      text: "Dashboard",
    },
    { path: "/taskboard", icon: CheckSquare, text: "Taskboard" },
    { path: "/calendar", icon: Calendar, text: "Calendar" },
    { path: "/analytics", icon: BarChart3, text: "Insights" },
    { path: "/settings", icon: Settings, text: "Settings" },
  ]

  const handleLogout = async () => {
    try {
      await logout()
      console.log("Logout successful")
    } catch (error) {
      console.error("Error during logout:", error)
    }
  }

  const handleModalClose = () => {
    setCreateModalOpen(false)
  }

  return (
    <div
      className={cn(
        "sidebar flex h-screen flex-col justify-between p-4 pr-6 transition-all duration-300",
        isCollapsed ? "w-20" : "w-60"
      )}
    >
      <div>
        {/* Logo */}
        <div className="mb-4 mt-4 flex justify-center">
          <img
            src={`${process.env.PUBLIC_URL}/inverselogo.png`}
            alt="Sidebar Logo"
            className={cn(
              "h-auto transition-all duration-300",
              isCollapsed ? "w-10" : "w-[150px]"
            )}
          />
        </div>

        {/* Add Task Button */}
        <Button
          variant="secondary"
          className={cn(
            "mb-1 mt-4 w-full transition-all duration-200 hover:bg-secondary hover:text-secondary-foreground"
          )}
          onClick={handleAddTaskClick}
        >
          {isCollapsed ? (
            <Plus className="h-5 w-5" />
          ) : (
            <>
              <Plus className="mr-2 h-5 w-5" />
              {isAtTaskLimit ? "Upgrade" : "Add Task"}
            </>
          )}
        </Button>

        {/* Menu Items */}
        <div className="mt-4 flex flex-col space-y-1">
          <div className="h-px bg-accent/20" />

          {/* Collapse Button */}
          <button
            onClick={toggleCollapse}
            className={cn(
              "flex w-full items-center rounded-lg px-3 py-2 text-primary-foreground no-underline",
              "transition-all duration-200 hover:bg-secondary hover:text-secondary-foreground",
              isCollapsed ? "justify-center" : ""
            )}
          >
            {isCollapsed ? (
              <ChevronRight className="h-5 w-5" />
            ) : (
              <>
                <ChevronLeft className="h-5 w-5" />
                <span className="ml-2">Collapse Sidebar</span>
              </>
            )}
          </button>

          <div className="h-px bg-accent/20" />

          {/* Main Menu Items */}
          {mainMenuItems.map((item) => {
            const Icon = item.icon
            return (
              <Link
                key={item.path}
                to={item.path}
                className={cn(
                  "flex w-full items-center rounded-lg px-3 py-2 text-primary-foreground no-underline",
                  "transition-all duration-200 hover:bg-secondary hover:text-secondary-foreground",
                  isActivePath(item.path)
                    ? "bg-secondary text-secondary-foreground"
                    : "text-sidebar-item",
                  isCollapsed ? "justify-center" : ""
                )}
              >
                <Icon className="h-5 w-5 shrink-0" />
                {!isCollapsed && <span className="ml-2">{item.text}</span>}
              </Link>
            )
          })}
        </div>
      </div>

      {/* Bottom Section */}
      <div className="space-y-2">
        {/* Upgrade Button - only shown when expanded and not subscribed */}
        {!auth.isSubscribed && !isCollapsed && (
          <Button
            variant="default"
            className={cn(
              "w-full bg-primary text-primary-foreground transition-all duration-200 hover:bg-primary/90",
              isActivePath('/subscribe') && "bg-primary/90"
            )}
            onClick={() => navigate("/subscribe")}
          >
            Upgrade
          </Button>
        )}

        {/* Help Guide Button */}
        <Button
          variant="secondary"
          className={cn(
            "w-full transition-all duration-200 hover:bg-secondary hover:text-secondary-foreground",
            isCollapsed ? "flex justify-center" : ""
          )}
          onClick={() => window.open('https://meetdoris.helpscoutdocs.com/category/4-getting-started', '_blank')}
        >
          {isCollapsed ? (
            <HelpCircle className="h-5 w-5" />
          ) : (
            <>
              <HelpCircle className="mr-2 h-5 w-5 shrink-0" />
              Help Guide
            </>
          )}
        </Button>

        {/* Bottom Buttons Container */}
        <div className={cn(
          "flex gap-2",
          isCollapsed ? "flex-col" : ""
        )}>
          <Button
            variant="secondary"
            className={cn(
              "transition-all duration-200 hover:bg-secondary hover:text-secondary-foreground",
              isCollapsed ? "flex justify-center" : "flex-1"
            )}
            onClick={handleLogout}
          >
            {isCollapsed ? (
              <LogOut className="h-5 w-5" />
            ) : (
              <>
                <LogOut className="mr-2 h-5 w-5 shrink-0" />
                Logout
              </>
            )}
          </Button>

          <Button
            variant="secondary"
            size={isCollapsed ? "default" : "icon"}
            className={cn(
              "transition-all duration-200 hover:bg-secondary hover:text-secondary-foreground",
              isCollapsed ? "flex justify-center" : ""
            )}
            onClick={toggleTheme}
          >
            {theme === "dark" ? (
              <Sun className="h-5 w-5" />
            ) : (
              <Moon className="h-5 w-5" />
            )}
          </Button>
        </div>
      </div>

      {/* Task Form Modal */}
      <Dialog open={createModalOpen} onOpenChange={setCreateModalOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Add a New Task</DialogTitle>
          </DialogHeader>
          <TaskForm
            onClose={handleModalClose}
            refreshTasks={() => {
              handleModalClose()
              if (location.pathname === "/taskboard" || location.pathname === "/dashboard") {
                window.location.reload()
              }
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Sidebar