import { Button } from "../ui/button"
import { Input } from "../ui/input"
import { Send, Loader2, Trash2, RefreshCw } from "lucide-react"
import { cn } from "../../lib/utils"
import { useAuth } from "../../contexts/AuthContext"
import { Progress } from "../ui/progress"

const MessageUsageIndicator = ({ usage }) => {
  const { auth } = useAuth();

  if (!usage) return null;

  const {
    used,
    limit = auth.isSubscribed ? 500 : 30,
    remaining = limit - used,
    reset_in_days,
    reset_in_hours
  } = usage;

  const progressValue = (used / limit) * 100;

  return (
    <div className="mt-2 space-y-1.5">
      <div className="flex justify-between text-xs text-muted-foreground px-1">
        <span>{remaining}/{limit} messages remaining</span>
        <span>Resets in {reset_in_days}d {reset_in_hours}h</span>
      </div>
      <Progress value={progressValue} className="h-1" />
    </div>
  );
};

const ChatInput = ({ value, onChange, onSubmit, isProcessing, onDeleteChat, onCheckStatus, usage }) => (
  <div className="flex flex-col w-full max-w-[800px]">
    <form onSubmit={onSubmit} className="flex items-center gap-2 w-full">
      <div className="relative flex-1">
        <Input
          placeholder={isProcessing ? "Processing..." : "Message Doris..."}
          value={value}
          onChange={onChange}
          disabled={isProcessing}
          className="w-full rounded-full"
        />
      </div>
      <div className="flex gap-2">
        <Button
          type="submit"
          size="icon"
          disabled={!value.trim() || isProcessing}
          className="h-10 w-10 shrink-0"
          variant="ghost"
        >
          {isProcessing ? (
            <Loader2 className="h-4 w-4 animate-spin" />
          ) : (
            <Send className="h-4 w-4" />
          )}
        </Button>
        <Button
          variant="outline"
          size="icon"
          onClick={onDeleteChat}
          className="h-10 w-10 shrink-0"
          title="Delete Chat"
        >
          <Trash2 className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          size="icon"
          onClick={onCheckStatus}
          className="h-10 w-10 shrink-0"
          title="Check Connection"
        >
          <RefreshCw className={cn("h-4 w-4", isProcessing && "animate-spin")} />
        </Button>
      </div>
    </form>
    {usage && <MessageUsageIndicator usage={usage} />}
  </div>
)

export default ChatInput