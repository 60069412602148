import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  Plus,
  FileEdit,
  Trash2,
  FileText,
} from "lucide-react"
import Layout from "../components/Layout"
import ProjectForm from "../components/ProjectForm"
import axiosInstance from "../api/axiosInstance"
import { useAuth } from "../contexts/AuthContext"
import { cn } from "../lib/utils"
import projectsIllustration from "../assets/projects_empty.png"

import { Button } from "../components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../components/ui/dialog"
import { Card, CardContent, CardHeader } from "../components/ui/card"
import { Progress } from "../components/ui/progress"

const calculateCompletionPercentage = (tasks) => {
  const totalMinutes = tasks.reduce(
    (total, task) => total + task.estimated_hours * 60 + task.estimated_minutes,
    0
  );
  const completedMinutes = tasks.reduce(
    (completed, task) =>
      task.completed
        ? completed + task.estimated_hours * 60 + task.estimated_minutes
        : completed,
    0
  );
  return totalMinutes > 0 ? (completedMinutes / totalMinutes) * 100 : 0;
};

const EmptyState = ({ onCreateClick }) => (
  <Card className="mx-auto mt-4 max-w-3xl p-6 text-center">
    <div className="flex flex-col items-center gap-6">
      <img
        src={projectsIllustration}
        alt="Projects illustration"
        className="h-auto w-full max-w-3xl"
      />

      <p className="max-w-3xl text-muted-foreground">
        Create projects to organize your work efficiently. Break
        down complex tasks, set deadlines and let our advanced scheduling algorithm
        place these directly onto your calendar.
      </p>

      <Button
        variant="default"
        size="lg"
        onClick={onCreateClick}
        className="gap-2"
      >
        <Plus className="h-5 w-5" />
        Create Your First Project
      </Button>
    </div>
  </Card>
)

const getProjectStatus = (project) => {
    const now = new Date()
    const startDate = project.start_time ? new Date(project.start_time) : new Date()
    const deadline = new Date(project.deadline)

    if (now < startDate) {
      return "to-start"
    } else if (now > deadline) {
      return "overdue"
    } else {
      return "in-progress"
    }
  }

// Convert the hex color to an RGBA with 20% opacity
function getBgColor(hexColor) {
  if (!hexColor || hexColor.length !== 7) return 'rgba(0,0,0,0.1)';
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, 0.4)`;
}

const ProjectCard = ({ project, onEditProject, onDeleteProject }) => {
  const completion = calculateCompletionPercentage(project.tasks)

  return (
    <Card className="overflow-visible">
      <CardHeader className="border-b border-border p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div
              className="h-4 w-4 rounded-full"
              style={{ backgroundColor: project.color }}
            />
            <h3 className="font-semibold leading-none">{project.name}</h3>
          </div>
          <div className="flex gap-1">
          <Button
              variant="ghost"
              size="icon"
              onClick={() => onEditProject(project)}
            >
              <FileEdit className="h-4 w-4" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => onDeleteProject(project)}
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </CardHeader>

      <CardContent className="p-4">
        <div className="mb-4 space-y-2">
          <Progress value={completion} className="h-2" />
          <div className="flex justify-between text-sm text-muted-foreground">
            <span>Progress</span>
            <span>{Math.round(completion)}%</span>
          </div>
        </div>

        <div className="space-y-2">
          <h4 className="text-sm font-medium text-muted-foreground">
            Incomplete tasks:
          </h4>
          <div className="space-y-1">
            {project.tasks
              .filter((task) => !task.completed)
              .map((task) => (
                <div
                  key={task.name}
                  className="flex items-center gap-2 text-sm text-muted-foreground"
                >
                  <span className="truncate">• {task.name}</span>
                </div>
              ))}
          </div>
        </div>

        <div className="mt-4 flex items-center justify-between border-t border-border pt-4 text-sm">
          <div className="flex items-center gap-2">
            <FileText className="h-4 w-4" />
            <span>{project.tasks.length} Tasks</span>
          </div>
          <span>
            Deadline: {new Date(project.deadline).toLocaleDateString()}
          </span>
        </div>
      </CardContent>
    </Card>
  )
}


const KanbanColumn = ({ title, projects, onEditProject, onDeleteProject, type }) => {
  const columnStyles = {
    "to-start": "bg-[var(--kanban-to-start)]",
    "in-progress": "bg-[var(--kanban-in-progress)]",
    "overdue": "bg-[var(--kanban-overdue)]",
    "completed": "bg-[var(--kanban-completed)]",
  }

  return (
    <div className={cn("h-full rounded-lg p-4", columnStyles[type])}>
        <h2 className="text-lg font-semibold">
          {title} ({projects.length})
        </h2>
      <div className="custom-scrollbar mt-4 space-y-4 overflow-y-auto">
        {projects.map((project) => (
          <div key={project.id} className="relative">
            <ProjectCard
              project={project}
              onEditProject={onEditProject}
              onDeleteProject={onDeleteProject}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const CompletedColumn = ({ projects, onEditProject, onDeleteProject }) => {
  return (
    <div className="mt-6 w-full rounded-lg bg-[var(--kanban-completed)] p-4">
      <h2 className="mb-4 text-lg font-semibold">
        Completed Projects ({projects.length})
      </h2>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {projects.map((project) => (
          <ProjectCard
            key={project.id}
            project={project}
            onEditProject={onEditProject}
            onDeleteProject={onDeleteProject}
          />
        ))}
      </div>
    </div>
  )
}


function ProjectsPage({ onNewClick }) {
  const { auth } = useAuth()
  const [projects, setProjects] = useState([])
  const [open, setOpen] = useState(false)
  const [editProject, setEditProject] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [projectToDelete, setProjectToDelete] = useState(null)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setEditProject(null)
  }

  const handleEditProject = async (project) => {
    try {
      const response = await axiosInstance.get(`/api/projects/${project.id}`);
      setEditProject(response.data);
      setOpen(true);
    } catch (err) {
      console.error('Failed to fetch project details:', err);
      setError('Failed to fetch project details.');
    }
  };

  const handleDeleteProject = async () => {
    try {
      await axiosInstance.delete(`/api/projects/${projectToDelete.id}`);
      setProjects((prevProjects) =>
        prevProjects.filter((proj) => proj.id !== projectToDelete.id)
      );
      setDeleteDialogOpen(false);
      setProjectToDelete(null);
    } catch (err) {
      console.error('Failed to delete project:', err);
      setError('Failed to delete project.');
    }
  };

  const handleOpenDeleteDialog = (project) => {
    setProjectToDelete(project);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setProjectToDelete(null);
  };

  const fetchProjects = async () => {
    try {
      const response = await axiosInstance.get('/api/projects');
      setProjects(response.data.projects);
      setLoading(false);
    } catch (err) {
      setError(`Failed to fetch projects. Error: ${err.message}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const organizeProjects = () => {
    const uncompleted = projects.filter((p) => !p.completed)
    return {
      yetToStart: uncompleted.filter((p) => getProjectStatus(p) === "to-start"),
      inProgress: uncompleted.filter((p) => getProjectStatus(p) === "in-progress"),
      overdue: uncompleted.filter((p) => getProjectStatus(p) === "overdue"),
      completed: projects.filter((p) => p.completed),
    }
  }

  if (loading) {
    return (
      <div className="flex h-[50vh] items-center justify-center">
        <div className="flex items-center gap-2">
          <div className="h-4 w-4 animate-spin rounded-full border-2 border-primary border-t-transparent" />
          <span>Loading projects...</span>
        </div>
      </div>
    )
  }

  if (error) {
    return <p className="text-destructive">{error}</p>
  }

  const { yetToStart, inProgress, overdue, completed } = organizeProjects()

  return (
    <>
      <div className="flex flex-col">
        <div className="mt-4 px-6">
          {projects.length === 0 ? (
            <EmptyState onCreateClick={handleOpen} />
          ) : (
            <>
              <div className="grid h-[900px] grid-cols-1 gap-6 md:grid-cols-3">
                <KanbanColumn
                  title="To Start"
                  projects={yetToStart}
                  onEditProject={handleEditProject}
                  onDeleteProject={handleOpenDeleteDialog}
                  type="to-start"
                />
                <KanbanColumn
                  title="In Progress"
                  projects={inProgress}
                  onEditProject={handleEditProject}
                  onDeleteProject={handleOpenDeleteDialog}
                  type="in-progress"
                />
                <KanbanColumn
                  title="Overdue"
                  projects={overdue}
                  onEditProject={handleEditProject}
                  onDeleteProject={handleOpenDeleteDialog}
                  type="overdue"
                />
              </div>

              {completed.length > 0 && (
                <CompletedColumn
                  projects={completed}
                  onEditProject={handleEditProject}
                  onDeleteProject={handleOpenDeleteDialog}
                />
              )}
            </>
          )}
        </div>

        {/* Add/Edit Project Dialog */}
        <Dialog open={open} onOpenChange={handleClose}>
          <DialogContent className="sm:max-w-[600px]">
            <DialogHeader>
              <DialogTitle>
                {editProject ? "Edit Project" : "Add a New Project"}
              </DialogTitle>
            </DialogHeader>
            <ProjectForm
              onClose={handleClose}
              refreshProjects={fetchProjects}
              project={editProject}
            />
          </DialogContent>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteDialogOpen}
          onOpenChange={() => {
            setDeleteDialogOpen(false)
            setProjectToDelete(null)
          }}
        >
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Delete Project</DialogTitle>
              <DialogDescription>
                Are you sure you want to delete this project? This action is
                irreversible.
              </DialogDescription>
            </DialogHeader>
            <DialogFooter className="flex justify-between sm:justify-between">
              <Button
                variant="outline"
                onClick={() => {
                  setDeleteDialogOpen(false)
                  setProjectToDelete(null)
                }}
              >
                Cancel
              </Button>
              <Button variant="destructive" onClick={handleDeleteProject}>
                Delete
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </>
  )
}

export default ProjectsPage