import React, { useState } from 'react'
import axiosInstance from '../api/axiosInstance'
import { Button } from "../components/ui/button"
import { Textarea } from "../components/ui/textarea"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../components/ui/dialog"

function FeedbackForm() {
  const [feedbackText, setFeedbackText] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('')

  const handleFeedbackChange = (event) => {
    setFeedbackText(event.target.value)
  }

  const handleSubmitFeedback = async () => {
    try {
      await axiosInstance.post('/api/feedback', { feedbackText })
      setModalMessage(
        'Your feedback has been successfully submitted. We appreciate your input!'
      )
      setFeedbackText('')
    } catch (error) {
      if (error.response?.status === 429) {
        setModalMessage(
          'You can only submit feedback once per minute. Please try again later.'
        )
      } else {
        setModalMessage('Failed to submit feedback. Please try again later.')
      }
    } finally {
      setOpenModal(true)
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <Textarea
        placeholder="Got suggestions? Enter them here."
        value={feedbackText}
        onChange={handleFeedbackChange}
        className="min-h-[100px] resize-none"
      />
      <Button
        variant="default"
        onClick={handleSubmitFeedback}
        disabled={!feedbackText.trim()}
        className="w-full"
      >
        Submit Feedback
      </Button>

      <Dialog open={openModal} onOpenChange={setOpenModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Feedback Status</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p className="text-sm text-muted-foreground">
              {modalMessage}
            </p>
          </div>
          <DialogFooter>
            <Button variant="secondary" onClick={() => setOpenModal(false)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default FeedbackForm