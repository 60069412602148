import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card"
import { useToast } from "../components/ui/toast"
import { Check, X } from "lucide-react"
import { cn } from "../lib/utils"
import axiosInstance from "../api/axiosInstance"

function ResetPasswordPage() {
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passwordErrors, setPasswordErrors] = useState({
    minLength: false,
    number: false,
    upperCase: false,
    lowerCase: false,
    specialChar: false,
  })
  const navigate = useNavigate()
  const { token } = useParams()
  const { toast } = useToast()

  useEffect(() => {
    setPasswordErrors(checkPasswordStrength(password))
  }, [password])

  const checkPasswordStrength = (password) => ({
    minLength: password.length >= 8,
    number: /[0-9]/.test(password),
    upperCase: /[A-Z]/.test(password),
    lowerCase: /[a-z]/.test(password),
    specialChar: /[!@#$%^&*]/.test(password),
  })

  const handlePasswordReset = async (event) => {
    event.preventDefault()

    const errors = checkPasswordStrength(password)
    if (!errors.minLength || !errors.number || !errors.upperCase || !errors.lowerCase || !errors.specialChar) {
      toast({
        variant: "destructive",
        description: "Your password does not meet all the requirements."
      })
      setPasswordErrors(errors)
      return
    }

    if (password !== confirmPassword) {
      toast({
        variant: "destructive",
        description: "Passwords do not match."
      })
      return
    }

    try {
      await axiosInstance.post(`/api/user/reset-password/${token}`, {
        password,
      })
      toast({
        description: "Your password has been reset successfully."
      })
      navigate("/login")
    } catch (error) {
      toast({
        variant: "destructive",
        description: "Failed to reset password. Please try again."
      })
    }
  }

  return (
    <div className="flex min-h-screen items-center justify-center px-4 py-5">
      <div className="w-full max-w-md">
        <Card className="border-none">
          <CardHeader>
            <CardTitle>Reset Your Password</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handlePasswordReset} className="space-y-4">
              <div className="space-y-4">
                <Input
                  type="password"
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <Input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>

              <div className="space-y-2">
                <p className="text-sm font-medium text-muted-foreground">Password Requirements:</p>
                <ul className="space-y-1 text-sm">
                  {Object.entries({
                    'At least 8 characters long': passwordErrors.minLength,
                    'At least one number': passwordErrors.number,
                    'At least one uppercase letter': passwordErrors.upperCase,
                    'At least one lowercase letter': passwordErrors.lowerCase,
                    'At least one special character (!@#$%^&*)': passwordErrors.specialChar,
                  }).map(([text, isValid]) => (
                    <li
                      key={text}
                      className={cn(
                        "flex items-center space-x-2",
                        isValid ? "text-primary" : "text-muted-foreground"
                      )}
                    >
                      {isValid ? (
                        <Check className="h-4 w-4" />
                      ) : (
                        <X className="h-4 w-4" />
                      )}
                      <span>{text}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <Button type="submit" className="w-full">
                Reset Password
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default ResetPasswordPage