import React, { useState, useEffect } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { Timer } from 'lucide-react'
import axiosInstance from '../api/axiosInstance'
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../components/ui/dialog"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip"
import { Label } from "../components/ui/label"

const COLORS = [
  '#F3A160', // Custom orange
  '#2E3060', // Custom dark blue
  '#C6D8AF', // Custom light green
  '#6CCFF6', // Custom cyan blue
  '#9999C3', // Custom soft purple
  '#FFCC99', // Light peach
  '#A3D9FF', // Soft sky blue
  '#FF9999', // Light red
  '#B5A1FF', // Light violet
  '#FFE599', // Soft yellow
]

// Remove the requiresScheduling state since it will always be true
function HabitForm({ onClose, refreshHabits, habit }) {
  const { auth } = useAuth()
  const [habitName, setHabitName] = useState(habit ? habit.name : '')
  const [estimatedMinutes, setEstimatedMinutes] = useState(
    habit ? habit.estimated_minutes : ''
  )
  const [windowStart, setWindowStart] = useState(habit ? habit.window_start : '09:00')
  const [windowEnd, setWindowEnd] = useState(habit ? habit.window_end : '17:00')
  const [selectedColor, setSelectedColor] = useState(
    habit ? habit.hex_color : COLORS[0]
  )
  const [errorMessage, setErrorMessage] = useState('')
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

  useEffect(() => {
    if (habit) {
      setHabitName(habit.name)
      setEstimatedMinutes(habit.estimated_minutes || '')
      setSelectedColor(habit.hex_color || COLORS[0])
      setWindowStart(habit.window_start || '09:00')
      setWindowEnd(habit.window_end || '17:00')
    }
  }, [habit])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setErrorMessage('')

    if (!habitName.trim()) {
      setErrorMessage('Please provide a habit name.')
      setIsErrorModalOpen(true)
      return
    }

    if ((!estimatedMinutes || parseInt(estimatedMinutes) <= 0)) {
      setErrorMessage('Please set valid estimated minutes for scheduled habits.')
      setIsErrorModalOpen(true)
      return
    }

    if (windowStart >= windowEnd) {
      setErrorMessage('End time must be after start time.')
      setIsErrorModalOpen(true)
      return
    }

    const habitData = {
      name: habitName,
      requires_scheduling: true, // Always set to true
      estimated_minutes: parseInt(estimatedMinutes),
      hex_color: selectedColor,
      window_start: windowStart,
      window_end: windowEnd,
    }

    try {
      if (habit) {
        await axiosInstance.put(`/api/habits/${habit.id}`, habitData)
      } else {
        await axiosInstance.post('/api/habits', habitData)
      }

      if (refreshHabits) {
        refreshHabits()
      }

      if (onClose) {
        onClose()
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error saving the habit. Please try again.')
      setIsErrorModalOpen(true)
    }
  }

  return (
    <div className="max-h-[80vh] overflow-y-auto overflow-x-hidden p-4">
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Habit Name */}
        <div className="space-y-2">
          <Label htmlFor="habitName">Habit Name</Label>
          <Input
            id="habitName"
            value={habitName}
            onChange={(e) => setHabitName(e.target.value)}
            required
          />
        </div>

        {/* Time Window */}
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <Timer className="h-4 w-4" />
            <Label>This habit should happen between:</Label>
          </div>
          <div className="flex items-center gap-4">
            <Input
              type="time"
              value={windowStart}
              onChange={(e) => setWindowStart(e.target.value)}
              step="300"
              className="flex-1"
            />
            <span className="text-sm">and</span>
            <Input
              type="time"
              value={windowEnd}
              onChange={(e) => setWindowEnd(e.target.value)}
              step="300"
              className="flex-1"
            />
          </div>
        </div>

        {/* Estimated Minutes */}
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center gap-2">
                <Timer className="h-4 w-4 text-muted-foreground" />
                <div className="w-full">
                  <Label htmlFor="estimatedMinutes">Estimated Minutes</Label>
                  <Input
                    id="estimatedMinutes"
                    type="number"
                    value={estimatedMinutes}
                    onChange={(e) => setEstimatedMinutes(e.target.value)}
                    required
                    className="mt-2"
                  />
                </div>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              How many minutes this habit typically takes
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        {/* Color Picker */}
        <div className="flex justify-center">
          <div className="flex flex-wrap gap-2 justify-center">
            {COLORS.map((color) => (
              <button
                key={color}
                type="button"
                onClick={() => setSelectedColor(color)}
                className={`w-6 h-6 rounded-full cursor-pointer transition-all ${
                  selectedColor === color ? 'ring-2 ring-offset-2 ring-ring' : ''
                }`}
                style={{ backgroundColor: color }}
              />
            ))}
          </div>
        </div>

        {/* Submit Button */}
        <Button type="submit" className="w-full">
          {habit ? 'Update Habit' : 'Create Habit'}
        </Button>
      </form>

      {/* Error Modal */}
      <Dialog open={isErrorModalOpen} onOpenChange={setIsErrorModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Error</DialogTitle>
          </DialogHeader>
          <DialogDescription>{errorMessage}</DialogDescription>
          <DialogFooter>
            <Button variant="secondary" onClick={() => setIsErrorModalOpen(false)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default HabitForm