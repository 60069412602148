import { useState, useRef, createRef } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import axiosInstance from "../api/axiosInstance"
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Card, CardContent } from "../components/ui/card"
import { Alert, AlertDescription } from "../components/ui/alert"

function VerifyPage() {
  const inputsRef = useRef(Array(6).fill(null).map(() => createRef()))
  const [code, setCode] = useState(Array(6).fill(""))
  const navigate = useNavigate()
  const { auth } = useAuth()
  const { userEmail } = auth
  const [resendMessage, setResendMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const handleInputChange = (value, index) => {
    const newCode = [...code]
    // Only allow numbers
    const sanitizedValue = value.replace(/[^0-9]/g, "")

    // Handle pasting multiple numbers
    if (sanitizedValue.length > 1) {
      const digits = sanitizedValue.split("").slice(0, 6)
      const updatedCode = [...code]
      digits.forEach((digit, i) => {
        if (index + i < 6) {
          updatedCode[index + i] = digit
        }
      })
      setCode(updatedCode)

      // Focus the next empty input or the last input
      const nextEmptyIndex = updatedCode.findIndex((digit, i) => i > index && !digit)
      if (nextEmptyIndex !== -1 && nextEmptyIndex < 6) {
        inputsRef.current[nextEmptyIndex].current.focus()
      } else {
        inputsRef.current[5].current.focus()
      }
    } else {
      // Handle single digit input
      newCode[index] = sanitizedValue
      setCode(newCode)
      if (sanitizedValue && index < 5) {
        inputsRef.current[index + 1].current.focus()
      }
    }
  }

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      inputsRef.current[index - 1].current.focus()
    }
  }

  const handlePaste = (e, index) => {
    e.preventDefault()
    const pastedData = e.clipboardData.getData("text")
    handleInputChange(pastedData, index)
  }

  // Rest of the code remains the same...
  const handleResendVerification = async () => {
    try {
      const response = await axiosInstance.post("/api/user/resend_verification", {
        email: userEmail,
      })
      setResendMessage(response.data.message || "Verification email resent. Please check your inbox.")
    } catch (error) {
      setResendMessage(`Failed to resend verification email. Please try again later.`)
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    try {
      const verifyResponse = await axiosInstance.post("/api/user/verify_code", {
        email: userEmail,
        code: code.join(""),
      })

      if (verifyResponse.status === 200) {
        navigate("/subscribe")
      } else {
        setErrorMessage(verifyResponse.data.message || "Verification failed. Please try again.")
      }
    } catch (error) {
      setErrorMessage("An error occurred during verification. Please try again later.")
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex min-h-screen items-center justify-center px-4 py-5">
      <div className="w-full max-w-md">
        <Card>
          <CardContent className="pt-6">
            <h2 className="mb-4 text-2xl font-bold text-foreground">
              Verify Your Email
            </h2>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="flex justify-center space-x-2">
                {code.map((digit, index) => (
                  <Input
                    key={index}
                    ref={inputsRef.current[index]}
                    value={digit}
                    onChange={(e) => handleInputChange(e.target.value, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={(e) => handlePaste(e, index)}
                    maxLength={1}
                    className="h-12 w-12 text-center text-lg"
                    inputMode="numeric"
                    pattern="[0-9]*"
                  />
                ))}
              </div>

              {/* Rest of the JSX remains the same... */}
              {errorMessage && (
                <Alert variant="destructive">
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Alert>
              )}

              <Button
                type="submit"
                className="h-12 w-full"
                disabled={loading}
              >
                {loading ? (
                  <div className="h-5 w-5 animate-spin rounded-full border-2 border-gray-300 border-t-white" />
                ) : (
                  "Verify"
                )}
              </Button>

              <div className="text-center text-sm">
                <p>
                  Didn't receive the code?{" "}
                  <button
                    type="button"
                    onClick={handleResendVerification}
                    className="text-primary hover:underline"
                  >
                    Resend
                  </button>
                </p>
              </div>

              {resendMessage && (
                <Alert variant={resendMessage.includes('Failed') ? 'destructive' : 'default'}>
                  <AlertDescription>{resendMessage}</AlertDescription>
                </Alert>
              )}

              <p className="text-center text-xs text-muted-foreground">
                Remember to check your junk!
              </p>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default VerifyPage