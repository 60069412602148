import * as React from "react"
import { cn } from "../../lib/utils"

const Progress = React.forwardRef(({ className, value, max = 100, ...props }, ref) => {
  const percentage = value != null ? Math.min(Math.max(value, 0), max) : 0

  return (
    <div
      className={cn(
        "relative h-2 w-full overflow-hidden rounded-full bg-secondary",
        className
      )}
      {...props}
    >
      <div
        ref={ref}
        className="h-full flex-1 bg-primary transition-all duration-200 ease-in-out"
        style={{
          width: `${percentage}%`
        }}
      />
    </div>
  )
})
Progress.displayName = "Progress"

export { Progress }