import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Layout from '../components/Layout'
import { Button } from "../components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card"
import { Alert, AlertDescription } from "../components/ui/alert"
import { Loader2 } from "lucide-react"
import axiosInstance from '../api/axiosInstance'

const SubscribeSuccessPage = () => {
 const location = useLocation()
 const [loading, setLoading] = useState(true)
 const [subscriptionDetails, setSubscriptionDetails] = useState(null)
 const [error, setError] = useState('')

 const getSessionIdFromUrl = () => {
   const searchParams = new URLSearchParams(location.search)
   return searchParams.get('session_id')
 }

 useEffect(() => {
   const sessionId = getSessionIdFromUrl()
   if (sessionId) {
     fetchSubscriptionDetails(sessionId)
   } else {
     setError('No session ID found in the URL.')
     setLoading(false)
   }
 }, [location.search])

 const fetchSubscriptionDetails = async (sessionId) => {
   try {
     setLoading(true)
     const response = await axiosInstance.get(`/api/subscription/success/${sessionId}`)
     setSubscriptionDetails(response.data)
   } catch (err) {
     setError('Failed to retrieve subscription details.')
   } finally {
     setLoading(false)
   }
 }

 return (
   <Layout>
     <div className="container flex items-center justify-center min-h-screen py-8">
       <div className="w-full max-w-md">
         <Card>
           <CardHeader>
             <CardTitle>Subscription Success</CardTitle>
           </CardHeader>
           <CardContent>
             {loading && (
               <div className="flex justify-center">
                 <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
               </div>
             )}

             {error && (
               <Alert variant="destructive">
                 <AlertDescription>{error}</AlertDescription>
               </Alert>
             )}

             {!loading && !error && subscriptionDetails && (
               <div className="space-y-4">
                 <p className="text-sm text-muted-foreground">
                   Thank you for subscribing! Your subscription details are as follows:
                 </p>
                 <div className="space-y-2">
                   <p className="text-sm">
                     <span className="font-medium">Subscription ID:</span>{" "}
                     {subscriptionDetails.id}
                   </p>
                   <p className="text-sm">
                     <span className="font-medium">Status:</span>{" "}
                     {subscriptionDetails.status}
                   </p>
                 </div>
                 <div className="mt-4">
                   <Button asChild className="w-full">
                     <a href="/calendar">Go to Calendar</a>
                   </Button>
                 </div>
               </div>
             )}
           </CardContent>
         </Card>
       </div>
     </div>
   </Layout>
 )
}

export default SubscribeSuccessPage