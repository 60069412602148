import { useState, useEffect } from "react"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card"
import { Button } from "../../components/ui/button"
import { Alert, AlertDescription } from "../../components/ui/alert"
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"
import axiosInstance from "../../api/axiosInstance"

function CapacityChart() {
  const [data, setData] = useState(null)
  const [viewRange, setViewRange] = useState("1m")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const TIME_RANGES = {
    "1w": 7,
    "2w": 14,
    "1m": 30,
  }

  // Theme-aware colors using CSS variables
  const COLORS = {
    used: "hsl(var(--chart-1))",
    stroke: "hsl(var(--chart-1))"
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const { data: response } = await axiosInstance.get("/api/analytics/capacity")
        setData(response)
        setError(null)
      } catch (err) {
        console.error("Error fetching capacity data:", err)
        setError("Failed to load capacity data.")
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const handleRangeChange = (newRange) => {
    setViewRange(newRange)
  }

  if (loading) {
    return (
      <div className="flex h-[400px] items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex h-[400px] items-center justify-center p-6">
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </div>
    )
  }

  if (!data || !data.days) {
    return (
      <div className="flex h-[400px] items-center justify-center">
        <p className="text-muted-foreground">No data available.</p>
      </div>
    )
  }

  const daysToShow = TIME_RANGES[viewRange] || 30
      const displayedData = data.days
    .filter(day => day.total_capacity_minutes > 0) // Only show working days
    .slice(0, daysToShow)
    .map((day) => {
      const total = day.total_capacity_minutes
      const usedPercentage = (day.used_capacity_minutes / total) * 100

      return {
        date: day.date,
        used: Math.round(usedPercentage * 10) / 10 || 0, // Ensure we always have at least 0
      }
    })

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const used = payload[0].value || 0

      return (
        <div className="rounded-lg border bg-card p-3 shadow-sm">
          <p className="font-medium">{label}</p>
          <p className="text-sm text-muted-foreground">Used: {used.toFixed(1)}%</p>
          <p className="text-sm text-muted-foreground">Free: {(100 - used).toFixed(1)}%</p>
        </div>
      )
    }
    return null
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Future capacity overview</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="mb-6">
          <div className="inline-flex rounded-lg border p-1">
            {Object.entries(TIME_RANGES).map(([range, days]) => (
              <Button
                key={range}
                variant={viewRange === range ? "default" : "ghost"}
                className="px-3"
                onClick={() => handleRangeChange(range)}
              >
                {range === "1w"
                  ? "1 Week"
                  : range === "2w"
                  ? "2 Weeks"
                  : range === "1m"
                  ? "1 Month"
                  : "3 Months"}
              </Button>
            ))}
          </div>
        </div>

        <div className="h-[400px] w-full">
          <ResponsiveContainer>
            <AreaChart data={displayedData}>
              <defs>
                <linearGradient id="fillUsed" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={COLORS.used} stopOpacity={0.3} />
                  <stop offset="95%" stopColor={COLORS.used} stopOpacity={0.05} />
                </linearGradient>
              </defs>
              <CartesianGrid
                strokeDasharray="3 3"
                className="stroke-border"
                vertical={false}
              />
              <XAxis
                dataKey="date"
                tick={{ fill: "currentColor" }}
                stroke="currentColor"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                minTickGap={32}
                tickFormatter={(value) => {
                  const date = new Date(value)
                  if (viewRange === "3m") {
                    return date.toLocaleDateString("en-GB", { day: 'numeric', month: 'short' })
                  }
                  return date.toLocaleDateString("en-GB", { day: 'numeric', month: 'short' })
                }}
              />
              <YAxis
                domain={[0, 100]}
                unit="%"
                tick={{ fill: "currentColor" }}
                stroke="currentColor"
                tickLine={false}
                axisLine={false}
                allowDataOverflow={false}
              />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="basis"
                dataKey="used"
                fill="url(#fillUsed)"
                stroke={COLORS.used}
                name="Used Capacity"
                strokeWidth={2}
                connectNulls={true}
                baseValue={0}
                dot={false}
                isAnimationActive={true}
                animationDuration={300}
                animationEasing="ease-in-out"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  )
}

export default CapacityChart