import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [auth, setAuth] = useState(() => {
    const storedAuth = localStorage.getItem('auth');
    return storedAuth
      ? JSON.parse(storedAuth)
      : {
          isLoggedIn: false,
          userEmail: null,
          isSubscribed: false,
          isEmailVerified: false,
          isOnboardingComplete: false,
          calendarType: '',
          isCheckingSession: true,
        };
  });

  useEffect(() => {
    localStorage.setItem('auth', JSON.stringify(auth));
  }, [auth]);

  useEffect(() => {
    if (auth.isLoggedIn && !auth.isCheckingSession && auth.userEmail) {
      window.pendo?.initialize({
        visitor: {
          id: auth.userEmail,
          email: auth.userEmail,
          isEmailVerified: auth.isEmailVerified,
          calendarType: auth.calendarType || 'none',
          isOnboardingComplete: auth.isOnboardingComplete,
          subscriptionStatus: auth.isSubscribed ? 'subscribed' : 'free'
        },
        account: {
          id: auth.userEmail.split('@')[1],
        }
      });
    } else if (!auth.isLoggedIn && window.pendo) {
      window.pendo.initialize({
        visitor: { id: null },
        account: { id: null }
      });
    }
  }, [auth.isLoggedIn, auth.isCheckingSession, auth.userEmail, auth.isEmailVerified,
      auth.calendarType, auth.isOnboardingComplete, auth.isSubscribed]);

  const handleLogoutAndRedirect = useCallback(() => {
    if (window.pendo) {
      window.pendo.initialize({
        visitor: { id: null },
        account: { id: null }
      });
    }

    setAuth({
      isLoggedIn: false,
      userEmail: null,
      isSubscribed: false,
      isEmailVerified: false,
      isOnboardingComplete: false,
      calendarType: '',
      isCheckingSession: false,
    });
    navigate('/login', { replace: true });
  }, [navigate]);

  const checkSession = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/api/user/check_session');

      if (!response.data.isLoggedIn) {
        setAuth(prev => ({
          ...prev,
          isLoggedIn: false,
          isCheckingSession: false
        }));
        return false;
      }

      setAuth(prev => ({
        ...prev,
        isLoggedIn: true,
        userEmail: response.data.userEmail,
        isSubscribed: response.data.is_subscribed,
        isEmailVerified: response.data.is_email_verified,
        isOnboardingComplete: response.data.is_onboarding_complete,
        calendarType: response.data.calendar_type || '',
        isCheckingSession: false,
        redeemedPromo: response.data.redeemed_promo || false,
      }));
      return true;

    } catch (error) {
      if (error.response?.status === 401) {
        try {
          await axiosInstance.post('/api/user/refresh_token');
          return checkSession();
        } catch (refreshError) {
          console.error('Refresh token failed:', refreshError);
          setAuth(prev => ({
            ...prev,
            isLoggedIn: false,
            isCheckingSession: false
          }));
          return false;
        }
      }
      console.error('Session check failed:', error);
      setAuth(prev => ({
        ...prev,
        isLoggedIn: false,
        isCheckingSession: false
      }));
      return false;
    }
  }, []);

  useEffect(() => {
    const isPublicPath =
      location.pathname === '/login' ||
      location.pathname === '/register' ||
      location.pathname === '/forgot-password' ||
      location.pathname.startsWith('/reset-password/') ||
      location.pathname === '/reset';

    if (!isPublicPath) {
      const handleInitialSession = async () => {
        const isAuthenticated = await checkSession();
        if (!isAuthenticated) {
          handleLogoutAndRedirect();
        }
      };

      handleInitialSession();
    } else {
      setAuth(prev => ({ ...prev, isCheckingSession: false }));
    }
  }, [location.pathname, checkSession, handleLogoutAndRedirect]);

  useEffect(() => {
    if (auth.isLoggedIn) {
      const refreshToken = async () => {
        try {
          await axiosInstance.post('/api/user/refresh_token');
        } catch (error) {
          console.error('Token refresh failed:', error);
          if (error.response?.status === 401) {
            handleLogoutAndRedirect();
          }
        }
      };

      const refreshInterval = setInterval(refreshToken, 10 * 60 * 1000);
      refreshToken(); // Initial refresh

      const sessionCheckInterval = setInterval(() => {
        checkSession();
      }, 5 * 60 * 1000);

      return () => {
        clearInterval(refreshInterval);
        clearInterval(sessionCheckInterval);
      };
    }
  }, [auth.isLoggedIn, handleLogoutAndRedirect, checkSession]);

  const login = async (email, password) => {
    try {
      const response = await axiosInstance.post('/api/user/login', { email, password });

      if (response.status === 200) {
        const { emailVerified, isSubscribed, isOnboardingComplete, calendar_type } = response.data;

        setAuth({
          isLoggedIn: true,
          userEmail: email,
          isSubscribed: isSubscribed,
          isEmailVerified: emailVerified,
          isOnboardingComplete: isOnboardingComplete || false,
          calendarType: calendar_type || '',
          isCheckingSession: false,
        });

        navigate(emailVerified ? '/dashboard' : '/verify');
      } else {
        throw new Error('Login unsuccessful');
      }
    } catch (error) {
      console.error('Login error:', error);
      throw new Error(error.response?.data?.message || 'Login failed. Please try again.');
    }
  };

  const register = async (email, password) => {
    try {
      const response = await axiosInstance.post('/api/user/register', { email, password });

      if (response.status === 201) {
        setAuth({
          isLoggedIn: true,
          userEmail: email,
          isSubscribed: false,
          isEmailVerified: false,
          isOnboardingComplete: false,
          calendarType: '',
          isCheckingSession: false,
        });

        navigate('/verify');
      } else {
        throw new Error(response.data.message || 'Registration failed');
      }
    } catch (error) {
      console.error('Registration error:', error);
      throw new Error(error.response?.data?.message || 'Registration failed. Please try again.');
    }
  };

  const logout = async () => {
    try {
      await axiosInstance.post('/api/user/logout', {}, { withCredentials: true });
      handleLogoutAndRedirect();
    } catch (error) {
      console.error('Logout error:', error);
      handleLogoutAndRedirect();
    }
  };

  const microsoftSignIn = async () => {
    try {
      const response = await axiosInstance.get('/api/user/outlook/auth');
      if (response.data.authorization_url) {
        window.location.href = response.data.authorization_url;
      }
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to connect to Microsoft');
    }
  };

  const googleSignIn = async () => {
    try {
      const response = await axiosInstance.get('/api/user/google/auth');
      if (response.data.authorization_url) {
        window.location.href = response.data.authorization_url;
      }
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to connect to Google');
    }
  };

  return (
    <AuthContext.Provider value={{
      auth,
      setAuth,
      login,
      logout,
      checkSession,
      register,
      microsoftSignIn,
      googleSignIn
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;