import { useState, useEffect } from "react"
import { useAuth } from "../contexts/AuthContext"
import Layout from "../components/Layout"
import TaskForm from "../components/TaskForm"
import TaskCheckbox from '../components/tasks/TaskCheckbox';
import axiosInstance from "../api/axiosInstance"
import { formatEstimatedTime } from "../lib/utils"
import { useToast } from "../components/ui/toast"
import { Button } from "../components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../components/ui/dialog"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "../components/ui/dropdown-menu"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table"
import { Input } from "../components/ui/input"
import { Card } from "../components/ui/card"
import { Badge } from "../components/ui/badge"
import {
  Plus,
  MoreVertical,
  Pencil,
  Trash2,
  CalendarClock,
  Search,
  ArrowUpDown,
  Check,
  Circle,
    Clock,
    Calendar,
    CalendarX,
    Wand2,
    Loader2,
} from "lucide-react"

const AVAILABLE_TAGS = [
  "Email",
  "Admin",
  "Planning",
  "Research",
  "Learning",
  "Implementation",
  "Other"
]

const PRIORITY_OPTIONS = [
  { value: "urgent_important", label: "Urgent & Important" },
  { value: "important_not_urgent", label: "Important but Not Urgent" },
  { value: "urgent_not_important", label: "Urgent but Not Important" },
  { value: "not_important_not_urgent", label: "Not Important or Urgent" },
]

function formatDateTimeLocal(dateString) {
  if (!dateString) return ""
  const date = new Date(dateString)
  const offset = date.getTimezoneOffset()
  const localDate = new Date(date.getTime() - offset * 60 * 1000)
  return localDate.toISOString().slice(0, 16)
}

const getPriorityColor = (priority) => {
  switch (priority) {
    case "urgent_important":
      return "text-red-500 bg-red-100 dark:bg-red-900/20"
    case "important_not_urgent":
      return "text-orange-500 bg-orange-100 dark:bg-orange-900/20"
    case "urgent_not_important":
      return "text-blue-500 bg-blue-100 dark:bg-blue-900/20"
    case "not_important_not_urgent":
      return "text-gray-500 bg-gray-100 dark:bg-gray-900/20"
    default:
      return "text-gray-500 bg-gray-100 dark:bg-gray-900/20"
  }
}

const EditableCell = ({ value, onEdit, type = "text", className = "", placeholder = "", displayValue }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }

  const handleBlur = () => {
    setIsEditing(false);
    if (currentValue !== value) {
      onEdit(currentValue);
    }
  }

  if (!isEditing) {
    return (
      <span
        className={`cursor-pointer ${className} ${!value ? "text-muted-foreground italic" : ""}`}
        onClick={() => setIsEditing(true)}
      >
        {displayValue || value || placeholder}
      </span>
    );
  }

  return (
    <Input
      type={type}
      autoFocus
      value={currentValue || ""}
      onChange={(e) => setCurrentValue(e.target.value)}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      className="h-8"
    />
  );
};

const getPriorityDisplay = (priority) => {
  switch (priority) {
    case "urgent_important":
      return "Urgent & Important"
    case "important_not_urgent":
      return "Important but Not Urgent"
    case "urgent_not_important":
      return "Urgent but Not Important"
    case "not_important_not_urgent":
      return "Not Important or Urgent"
    default:
      return priority
  }
}

const TasksHeader = ({ searchQuery, onSearchChange, onSort, sortDirection }) => {
  const [isScheduling, setIsScheduling] = useState(false)
  const { toast } = useToast()

  const getSortButtonText = (direction) => {
    switch (direction) {
      case "schedule": return "Sort by Schedule";
      case "asc": return "Sort by Due Date (Asc)";
      case "desc": return "Sort by Due Date (Desc)";
    }
  };

  const handleScheduleTasks = async () => {
    setIsScheduling(true)
    try {
      const response = await axiosInstance.get("/api/sync-calendar")

      toast({
        description: "Tasks have been scheduled in your calendar"
      })

      // If we want to refresh the tasks list after scheduling
      window.location.reload() // Or use your fetchTasks function if available

    } catch (error) {
      console.error("Error during sync:", error)
      toast({
        title: "Error",
        description: "Failed to schedule tasks. Please try again.",
        variant: "destructive",
      })
    } finally {
      setIsScheduling(false)
    }
  }

  return (
    <div className="mb-4 flex items-center gap-2">
      <div className="relative w-[300px]">
        <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground"/>
        <Input
          placeholder="Filter tasks..."
          value={searchQuery}
          onChange={(e) => onSearchChange(e.target.value)}
          className="pl-8"
        />
      </div>
      <Button variant="outline" onClick={onSort} className="gap-2">
        <ArrowUpDown className="h-4 w-4"/>
        {getSortButtonText(sortDirection)}
      </Button>
      <Button
        variant="outline"
        onClick={handleScheduleTasks}
        disabled={isScheduling}
        className="gap-2"
      >
        {isScheduling ? (
          <>
            <Loader2 className="h-4 w-4 animate-spin" />
            Scheduling...
          </>
        ) : (
          <>
            <Calendar className="h-4 w-4" />
            Schedule Tasks
          </>
        )}
      </Button>
    </div>
  );
};

const TAG_STYLES = {
  Email: "border-blue-400 text-blue-500 bg-blue-50 dark:bg-blue-500/10",
  Admin: "border-purple-400 text-purple-500 bg-purple-50 dark:bg-purple-500/10",
  Planning: "border-green-400 text-green-500 bg-green-50 dark:bg-green-500/10",
  Research: "border-amber-400 text-amber-500 bg-amber-50 dark:bg-amber-500/10",
  Learning: "border-pink-400 text-pink-500 bg-pink-50 dark:bg-pink-500/10",
  Implementation: "border-indigo-400 text-indigo-500 bg-indigo-50 dark:bg-indigo-500/10",
  Other: "border-gray-400 text-gray-500 bg-gray-50 dark:bg-gray-500/10"
}

const QuickTaskRow = ({ onSubmit }) => {
  const [taskName, setTaskName] = useState("")
  const [isGenerating, setIsGenerating] = useState(false)
  const [error, setError] = useState("")
  const { toast } = useToast()

  const handleGenerate = async (e) => {
    e.preventDefault()
    if (!taskName.trim()) return

    setIsGenerating(true)
    setError("")

    try {
      const { data } = await axiosInstance.post('/api/generate-task', {
        task_name: taskName
      })

      if (data.success) {
        setTaskName("")
        // Directly trigger a fetch instead of trying to create another task
        if (onSubmit) onSubmit() // Just refresh the task list
        toast({
          title: "Success",
          description: "Task created successfully",
          variant: "default",
        })
      } else {
        throw new Error(data.error || "Failed to generate task")
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message || "Failed to generate task"
      setError(errorMessage)
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      })
    } finally {
      setIsGenerating(false)
    }
  }

  return (
    <form onSubmit={handleGenerate} className="mb-4">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-2 p-3 bg-background/50 rounded-lg border">
        <div className="md:col-span-4">
          <Input
            placeholder="Enter task (e.g., 'prepare quarterly sales report by end of next week')"
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
            className="h-9"
            disabled={isGenerating}
          />
        </div>

       <Button
          type="submit"
          variant="outline"
          className="md:col-span-1 h-9 gap-2 hover:bg-primary/10 transition-colors whitespace-nowrap"
          disabled={isGenerating || !taskName.trim()}
        >
          {isGenerating ? (
            <>
              <Loader2 className="h-4 w-4 animate-spin" />
              <span>Generating...</span>
            </>
          ) : (
            <>
              <Wand2 className="h-4 w-4" />
              <span>Generate</span>
            </>
          )}
        </Button>
      </div>
      {error && (
        <div className="mt-2 text-sm text-destructive">
          {error}
        </div>
      )}
    </form>
  )
}

function TasksTable({ tasks, onComplete, onEdit, onDelete }) {
  const [selectedTasks, setSelectedTasks] = useState(new Set());
  const [selectedTask, setSelectedTask] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState(null);
  const { toast } = useToast()

  const handleSelectionChange = (taskId, isSelected) => {
    setSelectedTasks(prev => {
      const newSet = new Set(prev);
      if (isSelected) {
        newSet.add(taskId);
      } else {
        newSet.delete(taskId);
      }
      return newSet;
    });
  };

  const handleAction = (task, action) => {
    if (selectedTasks.size > 0 && action !== 'edit') {
      // Handle bulk action
      setSelectedTask(Array.from(selectedTasks).map(id =>
        tasks.find(t => t.id === id)
      ));
    } else {
      // Handle single task action
      setSelectedTask(task);
    }
    setDialogAction(action);
    setDialogOpen(true);
  };

  const canBeScheduled = (task) => {
    return task.estimated_minutes > 0 && task.due_date;
  };

  const handleConfirm = () => {
    if (selectedTask && dialogAction) {
      if (Array.isArray(selectedTask)) {
        // Handle bulk actions
        selectedTask.forEach(task => {
          if (dialogAction === "delete") {
            onDelete(task);
          } else if (dialogAction === "complete") {
            onComplete(task);
          }
        });
        setSelectedTasks(new Set()); // Clear selections
      } else {
        // Handle single task action
        if (dialogAction === "delete") {
          onDelete(selectedTask);
        } else if (dialogAction === "complete") {
          onComplete(selectedTask);
        }
      }
    }
    setDialogOpen(false);
    setSelectedTask(null);
    setDialogAction(null);
  };

  return (
      <>
      <div className="overflow-auto max-h-[calc(100vh-300px)] relative">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[50px]"> </TableHead>
              <TableHead>Tag</TableHead>
              <TableHead>Task</TableHead>
              <TableHead>Description</TableHead>
              <TableHead>Time</TableHead>
              <TableHead>Due Date</TableHead>
              <TableHead>Priority</TableHead>
              <TableHead>Schedule Type</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {tasks.map((task) => (
                <TableRow
                    key={task.id}
                    className={`group ${selectedTasks.has(task.id) ? 'bg-muted/50' : ''}`}
                >
                  <TableCell>
                    <TaskCheckbox
                        task={task}
                        isSelected={selectedTasks.has(task.id)}
                        selectedCount={selectedTasks.size}
                        onOpenChange={handleAction}
                        onSelectionChange={handleSelectionChange}
                    />
                  </TableCell>
                  <TableCell>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-full justify-start p-2">
                          <Badge variant="outline" className={`${TAG_STYLES[task.tag]} border`}>
                            {task.tag || "Other"}
                          </Badge>
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="start">
                        {AVAILABLE_TAGS.map(tag => (
                            <DropdownMenuItem
                                key={tag}
                                onClick={() => {
                                  console.log('Current task:', task);
                                  console.log('New tag:', tag);
                                  const updatedTask = {
                                    ...task,
                                    tag: tag,
                                  };
                                  console.log('Updated task:', updatedTask);
                                  onEdit(updatedTask);
                                }}
                            >
                              <Badge variant="outline" className={`${TAG_STYLES[tag]} border`}>
                                {tag}
                              </Badge>
                            </DropdownMenuItem>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                  <TableCell>
                    <EditableCell
                        value={task.name}
                        onEdit={(value) => onEdit({...task, name: value})}
                        className={task.completed ? "line-through text-muted-foreground" : ""}
                    />
                  </TableCell>
                  <TableCell className="max-w-[500px]">
                    <EditableCell
                        value={task.description}
                        onEdit={(value) => onEdit({...task, description: value})}
                        className={task.completed ? "line-through text-muted-foreground" : ""}
                        placeholder="Add description..."
                    />
                  </TableCell>
                  <TableCell>
                    <EditableCell
                        value={task.estimated_minutes ? task.estimated_minutes.toString() : ""}
                        onEdit={(value) => onEdit({...task, estimated_minutes: parseInt(value) || 0})}
                        type="number"
                        placeholder="Set time..."
                        className="cursor-pointer hover:text-primary transition-colors"
                        displayValue={task.estimated_minutes ? `${task.estimated_minutes} min` : ""}
                    />
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <CalendarClock className="h-4 w-4 text-muted-foreground"/>
                      <EditableCell
                          value={task.due_date ? formatDateTimeLocal(task.due_date) : ""}
                          displayValue={task.due_date ? new Date(task.due_date).toLocaleString([], {
                            weekday: 'short',
                            month: 'short',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          }) : ""}
                          onEdit={(value) => {
                            // Validation logic
                            const selectedDate = new Date(value);
                            const now = new Date();
                            const maxDate = new Date();
                            maxDate.setFullYear(now.getFullYear() + 10); // Max 10 years in the future

                            if (selectedDate < now) {
                              toast({
                                variant: "destructive",
                                title: "Invalid date",
                                description: "Due date cannot be in the past"
                              });
                              return;
                            }

                            if (selectedDate > maxDate) {
                              toast({
                                variant: "destructive",
                                title: "Invalid date",
                                description: "Due date cannot be more than 10 years in the future"
                              });
                              return;
                            }

                            onEdit({...task, due_date: value});
                          }}
                          type="datetime-local"
                          min={formatDateTimeLocal(new Date())} // Prevent selecting past dates
                          max={formatDateTimeLocal(new Date(Date.now() + 10 * 365 * 24 * 60 * 60 * 1000))} // 10 years max
                          placeholder="Set due date..."
                          className="cursor-pointer hover:text-primary transition-colors"
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-full justify-start p-2">
                          <Badge variant="secondary" className={getPriorityColor(task.priority)}>
                            {getPriorityDisplay(task.priority)}
                          </Badge>
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        {PRIORITY_OPTIONS.map(option => (
                            <DropdownMenuItem
                                key={option.value}
                                onClick={() => onEdit({...task, priority: option.value})}
                            >
                              <Badge variant="secondary" className={getPriorityColor(option.value)}>
                                {option.label}
                              </Badge>
                            </DropdownMenuItem>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                  <TableCell>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-full justify-start p-2">
                          <Badge variant="outline">
                            {task.requires_scheduling ? "Scheduled" : "Flexible"}
                          </Badge>
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuItem
                            onClick={() => {
                              if (!canBeScheduled(task)) {
                                toast({
                                  title: "Cannot schedule task",
                                  description: "Please set both time and due date before scheduling",
                                  variant: "destructive",
                                });
                                return;
                              }
                              onEdit({...task, requires_scheduling: true});
                            }}
                            disabled={!canBeScheduled(task)}
                            className={!canBeScheduled(task) ? "cursor-not-allowed opacity-50" : ""}
                        >
                          <Badge variant="outline">Scheduled</Badge>
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => onEdit({...task, requires_scheduling: false})}>
                          <Badge variant="outline">Flexible</Badge>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
        </div>

        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                {dialogAction === "delete" ? "Delete Task(s)?" : "Complete Task(s)?"}
              </DialogTitle>
              <DialogDescription>
                {Array.isArray(selectedTask) ? (
                    `Are you sure you want to ${dialogAction} ${selectedTask.length} tasks? This action cannot be undone.`
                ) : (
                    `Are you sure you want to ${dialogAction} "${selectedTask?.name}"? This action cannot be undone.`
                )}
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button variant="outline" onClick={() => setDialogOpen(false)}>
                Cancel
              </Button>
              <Button
                  variant={dialogAction === "delete" ? "destructive" : "default"}
                  onClick={handleConfirm}
              >
                {dialogAction === "delete" ? "Delete" : "Complete"}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </>
      );
      }


      function TasksPage({onNewClick}) {
        const {auth} = useAuth()
        const [tasks, setTasks] = useState({scheduled: [], unscheduled: []})
        const [searchQuery, setSearchQuery] = useState("")
        const [sortDirection, setSortDirection] = useState("schedule")
        const [createModalOpen, setCreateModalOpen] = useState(false)
        const [selectedTask, setSelectedTask] = useState(null)
        const [loading, setLoading] = useState(true)
        const [error, setError] = useState(null)
        const [calendarEvents, setCalendarEvents] = useState([])
        const {toast} = useToast()

        const fetchTasks = async () => {
        try {
        const response = await axiosInstance.get("/api/tasks")
        setTasks(response.data)
        setLoading(false)
      } catch (error) {
        console.error("Error fetching tasks:", error)
        setError("Failed to load tasks")
        setLoading(false)
      }
      }

        const fetchCalendarEvents = async () => {
        try {
        const response = await axiosInstance.get("/api/outlook/events")
        setCalendarEvents(response.data.events)
      } catch (error) {
        console.error("Error fetching calendar events:", error)
      }
      }

        useEffect(() => {
        const fetchInitialData = async () => {
        await Promise.all([
        fetchTasks(),
        fetchCalendarEvents()
        ])
      }
        fetchInitialData()
      }, [])

        const handleTaskComplete = async (task) => {
        try {
        await axiosInstance.post(`/api/tasks/${task.id}/complete`)
        fetchTasks()
      } catch (error) {
        console.error("Error completing task:", error)
      }
      }

        const handleTaskDelete = async (task) => {
        try {
        await axiosInstance.delete(`/api/tasks/${task.id}`)
        fetchTasks()
      } catch (error) {
        console.error("Error deleting task:", error)
      }
      }

        const handleTaskEdit = async (updatedTask) => {
        try {
        console.log('Sending task update:', updatedTask);
        // Create a specific update payload
        const updatePayload = {
        name: updatedTask.name,
        description: updatedTask.description,
        estimated_minutes: updatedTask.estimated_minutes,
        due_date: updatedTask.due_date,
        priority: updatedTask.priority,
        requires_scheduling: updatedTask.requires_scheduling,
        tag: updatedTask.tag // Make sure this is explicitly included
      };
        console.log('Update payload:', updatePayload);

        const response = await axiosInstance.put(`/api/tasks/${updatedTask.id}`, updatePayload);
        if (response.status === 200) {
        console.log('Update response:', response.data);
        await fetchTasks();
      }
      } catch (error) {
        console.error("Error updating task:", error);
        console.error("Error details:", error.response?.data);
      }
      };

        const handleQuickTaskCreate = async () => {
        try {
        await fetchTasks()
      } catch (error) {
        console.error("Error refreshing tasks:", error)
      }
      }

        const handleModalOpen = (task) => {
        setSelectedTask(task)
        setCreateModalOpen(true)
      }

        const handleModalClose = () => {
        setCreateModalOpen(false)
        setSelectedTask(null)
      }

        if (loading) {
        return (
        <div className="flex h-[50vh] items-center justify-center">
        <div className="flex items-center gap-2">
        <div className="h-4 w-4 animate-spin rounded-full border-2 border-primary border-t-transparent" />
        <span>Loading tasks...</span>
        </div>
        </div>
        )
      }

        const sortTasks = (tasksToSort) => {
        return [...tasksToSort].sort((a, b) => {
        if (sortDirection === "schedule") {
        const eventA = calendarEvents.find(event =>
        event.calendar_type === 'task' &&
        event.event_id === a.id
        )
        const eventB = calendarEvents.find(event =>
        event.calendar_type === 'task' &&
        event.event_id === b.id
        )

        if (eventA && !eventB) return -1
        if (!eventA && eventB) return 1
        if (eventA && eventB) {
        return new Date(eventA.start) - new Date(eventB.start)
      }

        const dateA = new Date(a.due_date || "9999-12-31")
        const dateB = new Date(b.due_date || "9999-12-31")
        return dateA - dateB
      } else {
        const dateA = new Date(a.due_date || "9999-12-31")
        const dateB = new Date(b.due_date || "9999-12-31")
        return sortDirection === "asc" ? dateA - dateB : dateB - dateA
      }
      })
      }

        const filterTasks = (tasks) => {
        return tasks.filter(task =>
        task.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        task.description?.toLowerCase().includes(searchQuery.toLowerCase())
        )
      }

        const handleSort = async () => {
        const newDirection = sortDirection === "schedule" ? "asc"
        : sortDirection === "asc" ? "desc"
        : "schedule"

        if (newDirection === "schedule") {
        await fetchCalendarEvents()
      }

        setSortDirection(newDirection)
      }

        const allTasks = [...tasks.scheduled, ...tasks.unscheduled]
        const filteredAndSortedTasks = sortTasks(filterTasks(allTasks))

        return (
        <>
        <div className="flex flex-col h-full"> {/* Add h-full */}
      <div className="mt-4 px-6 flex flex-col"> {/* Add flex flex-col and height calculation */}
        <TasksHeader
            searchQuery={searchQuery}
            onSearchChange={setSearchQuery}
            onSort={handleSort}
            sortDirection={sortDirection}
        />

        <QuickTaskRow onSubmit={handleQuickTaskCreate}/>

        <div className="rounded-md border flex-1 min-h-0"> {/* Add flex-1 and min-h-0 */}
          <TasksTable
              tasks={filteredAndSortedTasks}
              onComplete={handleTaskComplete}
              onEdit={handleTaskEdit}
              onDelete={handleTaskDelete}
          />
        </div>
      </div>

      <Dialog open={createModalOpen} onOpenChange={setCreateModalOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>
              {selectedTask ? "Edit Task" : "Add a New Task"}
            </DialogTitle>
          </DialogHeader>
          <TaskForm
              onClose={handleModalClose}
              refreshTasks={fetchTasks}
              task={selectedTask}
          />
        </DialogContent>
      </Dialog>
      </div>
</>
)
}

export default TasksPage