import React, { useState, useEffect } from 'react';
import { Button } from '../ui/button';
import { Check, Loader2, ChevronDown } from 'lucide-react';
import axiosInstance from '../../api/axiosInstance';
import {
  Dialog,
  DialogContent,
} from '../ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import ProjectForm from '../ProjectForm';

const TaskCheckbox = ({ task, isSelected, selectedCount, onOpenChange, onSelectionChange }) => {
  const [loading, setLoading] = useState(false);
  const [showProjectForm, setShowProjectForm] = useState(false);
  const [showProjectSelect, setShowProjectSelect] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [projects, setProjects] = useState([]);
  const [actionType, setActionType] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get('/api/projects');
        setProjects(response.data.projects.filter(p => !p.completed));
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    if (showProjectSelect) {
      fetchProjects();
    }
  }, [showProjectSelect]);

  const handleCheckboxClick = (e) => {
    e.stopPropagation();
    onSelectionChange(task.id, !isSelected);
  };

  const handleCompleteSelectedTasks = async () => {
    try {
      setLoading(true);
      const selectedTaskElements = document.querySelectorAll('[data-selected="true"]');
      const taskIds = Array.from(selectedTaskElements).map(el => el.dataset.taskId);

      const completePromises = taskIds.map(taskId =>
        axiosInstance.post(`/api/tasks/${taskId}/complete`)
      );

      await Promise.all(completePromises);
      window.location.reload();
    } catch (error) {
      console.error('Error completing tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteSelectedTasks = async (taskIds) => {
    try {
      const deletePromises = taskIds.map(taskId =>
        axiosInstance.delete(`/api/tasks/${taskId}`)
      );
      await Promise.all(deletePromises);
    } catch (error) {
      console.error('Error deleting tasks:', error);
      throw error;
    }
  };

  const fetchTaskDetails = async (taskIds) => {
    const taskPromises = taskIds.map(taskId =>
      axiosInstance.get(`/api/tasks/${taskId}`).then(res => res.data)
    );
    return Promise.all(taskPromises);
  };

  const transformTasksToProjectFormat = (tasks) => {
    return tasks.map(t => {
      const hours = t.estimated_minutes ? Math.floor(t.estimated_minutes / 60).toString() : "0";
      const minutes = t.estimated_minutes ? (t.estimated_minutes % 60).toString() : "0";

      return {
        name: t.name,
        hours: hours,
        minutes: minutes,
        estimated_hours: hours,
        estimated_minutes: minutes,
        tag: t.tag || "Other",
        completed: false
      };
    });
  };

  const handleSelectProject = async (projectId) => {
    try {
      setLoading(true);

      // Fetch project and tasks
      const projectResponse = await axiosInstance.get(`/api/projects/${projectId}`);
      const selectedProject = projectResponse.data;

      const selectedTaskElements = document.querySelectorAll('[data-selected="true"]');
      const selectedTaskIds = Array.from(selectedTaskElements).map(el => el.dataset.taskId);
      const selectedTasks = await fetchTaskDetails(selectedTaskIds);

      const newTasks = transformTasksToProjectFormat(selectedTasks);

      const combinedData = {
        ...selectedProject,
        tasks: [...selectedProject.tasks, ...newTasks]
      };

      setActionType('update');
      setProjectData(combinedData);
      setShowProjectSelect(false);
      setShowProjectForm(true);
    } catch (error) {
      console.error('Error preparing project data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateProject = async () => {
    try {
      setLoading(true);
      const selectedTaskElements = document.querySelectorAll('[data-selected="true"]');
      const selectedTaskIds = Array.from(selectedTaskElements).map(el => el.dataset.taskId);

      const tasksForProject = await fetchTaskDetails(selectedTaskIds);

      const initialData = {
        name: "",
        start_time: "",
        deadline: "",
        color: "#B5A1FF",
        tasks: transformTasksToProjectFormat(tasksForProject)
      };

      setActionType('create');
      setProjectData(initialData);
      setShowProjectForm(true);
    } catch (error) {
      console.error('Error fetching task details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleProjectSubmit = async (formData) => {
    try {
      setLoading(true);
      const selectedTaskElements = document.querySelectorAll('[data-selected="true"]');
      const selectedTaskIds = Array.from(selectedTaskElements).map(el => el.dataset.taskId);

      let response;
      let success = false;

      if (actionType === 'create') {
        response = await axiosInstance.post('/api/project-create', formData);
        success = response.status === 200;
      } else if (actionType === 'update' && projectData.id) {
        response = await axiosInstance.put(`/api/projects/${projectData.id}`, formData);
        success = response.status === 200;
      }

      if (success) {
        await deleteSelectedTasks(selectedTaskIds);
        setShowProjectForm(false);
        window.location.reload();
      }
    } catch (error) {
      console.error('Error saving project:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="ghost"
        className="h-8 w-8 p-0 hover:bg-transparent focus:ring-2 focus:ring-offset-2"
        onClick={handleCheckboxClick}
        disabled={task.completed}
        data-selected={isSelected}
        data-task-id={task.id}
      >
        {task.completed ? (
          <Check className="h-5 w-5 text-primary" />
        ) : isSelected ? (
          <div className="h-5 w-5 rounded border-2 border-primary bg-primary/10 flex items-center justify-center">
            <Check className="h-4 w-4 text-primary" />
          </div>
        ) : (
          <div className="h-5 w-5 rounded border-2 border-muted-foreground" />
        )}
      </Button>

      {selectedCount > 0 && (
        <div className="fixed bottom-6 right-6 z-50">
          <div className="bg-background rounded-lg shadow-lg border p-2 min-w-[280px]">
            <div className="px-2 py-1.5 text-sm text-muted-foreground mb-2">
              {selectedCount} items selected
            </div>

            <Button
              variant="ghost"
              className="w-full justify-start text-left py-3 px-4 mb-1"
              onClick={handleCompleteSelectedTasks}
              disabled={loading}
            >
              {loading ? (
                <Loader2 className="h-4 w-4 mr-2 animate-spin" />
              ) : (
                <>Mark {selectedCount} tasks as complete</>
              )}
            </Button>

            <div className="h-px bg-border my-1" />

            <Button
              variant="ghost"
              className="w-full justify-start text-left py-3 px-4 mb-1"
              onClick={handleCreateProject}
              disabled={loading}
            >
              {loading ? (
                <div className="flex items-center">
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  Creating project...
                </div>
              ) : (
                <>Create new project with {selectedCount} tasks</>
              )}
            </Button>

            <Button
              variant="ghost"
              className="w-full justify-start text-left py-3 px-4 mb-1"
              onClick={() => setShowProjectSelect(true)}
            >
              Add {selectedCount} tasks to existing project
            </Button>

            <Button
              variant="ghost"
              className="w-full justify-start text-left py-3 px-4 text-destructive hover:text-destructive"
              onClick={async () => {
                try {
                  setLoading(true);
                  const selectedTaskElements = document.querySelectorAll('[data-selected="true"]');
                  const selectedTaskIds = Array.from(selectedTaskElements).map(el => el.dataset.taskId);
                  await deleteSelectedTasks(selectedTaskIds);
                  window.location.reload();
                } catch (error) {
                  console.error('Error deleting tasks:', error);
                } finally {
                  setLoading(false);
                }
              }}
              disabled={loading}
            >
              {loading ? (
                <div className="flex items-center">
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  Deleting tasks...
                </div>
              ) : (
                <>Delete {selectedCount} tasks</>
              )}
            </Button>
          </div>
        </div>
      )}

      <Dialog open={showProjectSelect} onOpenChange={setShowProjectSelect}>
        <DialogContent className="sm:max-w-md">
          <div className="space-y-4">
            <h2 className="text-lg font-semibold">Select Project</h2>
            <div className="space-y-2">
              {projects.map(project => (
                <Button
                  key={project.id}
                  variant="outline"
                  className="w-full justify-start text-left"
                  onClick={() => handleSelectProject(project.id)}
                >
                  <div className="h-3 w-3 rounded-full mr-2" style={{ backgroundColor: project.color }} />
                  {project.name}
                </Button>
              ))}
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={showProjectForm} onOpenChange={setShowProjectForm}>
        <DialogContent className="max-w-3xl">
          <ProjectForm
            project={actionType === 'update' ? projectData : null}
            initialData={actionType === 'create' ? projectData : null}
            onClose={() => setShowProjectForm(false)}
            refreshProjects={() => window.location.reload()}
            onSubmit={handleProjectSubmit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TaskCheckbox;