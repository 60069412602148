import { useState } from "react"
import { Link } from "react-router-dom"
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Card, CardContent } from "../components/ui/card"
import { Alert, AlertDescription } from "../components/ui/alert"
import axiosInstance from '../api/axiosInstance'

function ForgotPasswordPage() {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const handleResetPassword = async (event) => {
    event.preventDefault()
    try {
      await axiosInstance.post('/api/user/forgot-password', { email })
      setMessage('If your account exists, a reset link will be sent to your email address.')
    } catch (error) {
      setMessage('Failed to send reset link. Please try again later.')
    }
  }

  return (
    <div className="flex min-h-screen items-center justify-center px-4 py-5">
      <div className="w-full max-w-md">
        <Card>
          <CardContent className="pt-6">
            <h2 className="mb-4 text-2xl font-bold text-foreground">
              Forgot Password
            </h2>

            <form onSubmit={handleResetPassword} className="space-y-4">
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <Button
                type="submit"
                className="h-12 w-full"
              >
                Send reset link
              </Button>

              {message && (
                <Alert variant={message.includes('Failed') ? 'destructive' : 'default'}>
                  <AlertDescription>
                    {message}
                  </AlertDescription>
                </Alert>
              )}

              <div className="text-center text-sm">
                <p>
                  Remembered your password?{" "}
                  <Link to="/login" className="text-primary hover:underline">
                    Log in
                  </Link>
                </p>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default ForgotPasswordPage