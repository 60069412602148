import React, { useState, useRef, useEffect } from 'react'
import { MessageCircle, X as CloseIcon, Minus as MinimizeIcon } from 'lucide-react'
import DorisChat from './chat/DorisChat'
import { cn } from "../lib/utils"
import { Button } from "../components/ui/button"

const DorisChatWindow = ({ open, onClose, onMinimize }) => {
  // Initialize position to be on the right side
  const [position, setPosition] = useState(() => ({
    x: window.innerWidth - 420 - 40, // 40px from right edge
    y: 100 // 100px from top
  }))

  // More compact initial size
  const [size, setSize] = useState(() => ({
    width: 600,
    height: 600
  }))

  const windowRef = useRef(null)
  const isDraggingRef = useRef(false)
  const isResizingRef = useRef(false)
  const dragStartPosRef = useRef({ x: 0, y: 0 })
  const resizeStartPosRef = useRef({ x: 0, y: 0 })
  const resizeStartSizeRef = useRef({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = () => {
      setSize(prev => ({
        width: Math.min(prev.width, window.innerWidth * 0.95),
        height: Math.min(prev.height, window.innerHeight * 0.95)
      }))
      setPosition(prev => ({
        x: Math.min(prev.x, window.innerWidth - size.width),
        y: Math.min(prev.y, window.innerHeight - size.height)
      }))
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [size.width, size.height])

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDraggingRef.current) {
        const deltaX = e.clientX - dragStartPosRef.current.x
        const deltaY = e.clientY - dragStartPosRef.current.y

        setPosition(prev => ({
          x: Math.min(Math.max(0, prev.x + deltaX), window.innerWidth - size.width),
          y: Math.min(Math.max(0, prev.y + deltaY), window.innerHeight - size.height)
        }))

        dragStartPosRef.current = { x: e.clientX, y: e.clientY }
      }

      if (isResizingRef.current) {
        const deltaX = e.clientX - resizeStartPosRef.current.x
        const deltaY = e.clientY - resizeStartPosRef.current.y

        setSize({
          width: Math.max(300, Math.min(window.innerWidth * 0.95, resizeStartSizeRef.current.width + deltaX)),
          height: Math.max(400, Math.min(window.innerHeight * 0.95, resizeStartSizeRef.current.height + deltaY))
        })
      }
    }

    const handleMouseUp = () => {
      isDraggingRef.current = false
      isResizingRef.current = false
    }

    if (open) {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [open, size.width, size.height])

  if (!open) return null

  const handleTitleMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      isDraggingRef.current = true
      dragStartPosRef.current = { x: e.clientX, y: e.clientY }
    }
  }

  const handleResizeMouseDown = (e) => {
    isResizingRef.current = true
    resizeStartPosRef.current = { x: e.clientX, y: e.clientY }
    resizeStartSizeRef.current = size
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <div
      ref={windowRef}
      style={{
        position: 'fixed',
        left: position.x,
        top: position.y,
        width: size.width,
        height: size.height,
        maxWidth: '95vw',
        maxHeight: '95vh',
        zIndex: 50,
      }}
      className={cn(
        "flex flex-col overflow-hidden rounded-lg border shadow-lg",
        "bg-background text-foreground"
      )}
    >
      {/* Window Title Bar */}
      <div
        onMouseDown={handleTitleMouseDown}
        className="flex h-10 items-center justify-between border-b bg-secondary/10 px-3 cursor-move select-none"
      >
        <div className="flex items-center gap-2">
          <MessageCircle className="h-4 w-4" />
          <span className="text-sm font-semibold">Doris AI Assistant</span>
        </div>
        <div className="flex gap-1">
          <Button
            variant="ghost"
            size="icon"
            className="h-6 w-6 hover:bg-secondary"
            onClick={onMinimize}
          >
            <MinimizeIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            className="h-6 w-6 hover:bg-destructive hover:text-destructive-foreground"
            onClick={onClose}
          >
            <CloseIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>

      {/* Chat Container */}
      <div className="flex-1 overflow-hidden">
        <DorisChat />
      </div>

      {/* Resize Handle */}
      <div
        onMouseDown={handleResizeMouseDown}
        className="absolute bottom-0 right-0 h-5 w-5 cursor-se-resize"
      >
        <div className="absolute bottom-[6px] right-[6px] h-0 w-0 border-[5px] border-transparent border-b-muted-foreground/20 border-r-muted-foreground/20" />
      </div>
    </div>
  )
}

export default DorisChatWindow