import { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import TasksPage from "./TasksPage"
import ProjectsPage from "./ProjectsPage"
import Layout from "../components/Layout"
import WorkspaceHeader from "../components/workspace/WorkspaceHeader"

function TasksProjectsContainer() {
  const navigate = useNavigate()
  const location = useLocation()
  const [activeTab, setActiveTab] = useState("tasks")

  // Sync tab with URL
  useEffect(() => {
    const tab = location.hash.replace("#", "") || "tasks"
    setActiveTab(tab)
  }, [location])

  const handleTabChange = (tab) => {
    setActiveTab(tab)
    navigate(`/taskboard#${tab}`)
  }

  const renderActiveTab = () => {
    switch (activeTab) {
      case "tasks":
        return <TasksPage />
      case "projects":
        return <ProjectsPage />
      default:
        return <TasksPage />
    }
  }

  return (
    <Layout>
      <div className="flex h-screen flex-col">
        <div className="flex-none">
          <WorkspaceHeader
            activeTab={activeTab}
            onTabChange={handleTabChange}
          />
        </div>
        <div className="flex-1 overflow-auto">
          {renderActiveTab()}
        </div>
      </div>
    </Layout>
  )
}

export default TasksProjectsContainer