import React, { useState, useEffect } from "react"
import { useTheme } from "../ThemeProvider"

// Axios
import axiosInstance from "../api/axiosInstance"

// Layout
import Layout from "../components/Layout"

// shadcn/ui imports
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../components/ui/dialog"
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "../components/ui/tabs"
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../components/ui/select"
import { Checkbox } from "../components/ui/checkbox"
import { useToast } from "../components/ui/toast"

// Icons from lucide-react
import {
  Calendar,
  Clock,
  User,
  Upload,
  Trash2,
  Check,
  Sun,
  Moon,
    ChevronDown,
} from "lucide-react"

const MicrosoftIcon = () => (
  <svg width="20" height="20" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0h10v10H10V0z" fill="#f25022" />
    <path d="M0 0h10v10H0V0z" fill="#00a4ef" />
    <path d="M10 10h10v10H10V10z" fill="#7fba00" />
    <path d="M0 10h10v10H0V10z" fill="#ffb900" />
  </svg>
)

export default function Settings() {
  const [activeTab, setActiveTab] = useState("calendars")
  const { theme, toggleTheme } = useTheme()
  const [loading, setLoading] = useState(true)
  const [settings, setSettings] = useState({
    workStartTime: "",
    workEndTime: "",
    timezone: "",
    workdays: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    },
  })

  const [calendarConnections, setCalendarConnections] = useState({
    google: false,
    outlook: false,
  })

  const [moodleUrl, setMoodleUrl] = useState("")
  const [dialogs, setDialogs] = useState({
    purge: false,
    moodle: false,
  })

  const [isSaving, setIsSaving] = useState(false)
  const { toast } = useToast()

  const timezones = [
    'Pacific/Honolulu',
    'America/Anchorage',
    'America/Los_Angeles',
    'America/Denver',
    'America/Chicago',
    'America/New_York',
    'America/Sao_Paulo',
    'Europe/London',
    'Europe/Paris',
    'Europe/Berlin',
    'Europe/Madrid',
    'Europe/Istanbul',
    'Asia/Dubai',
    'Asia/Kolkata',
    'Asia/Shanghai',
    'Asia/Tokyo',
    'Australia/Sydney',
  ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [settingsRes, calendarRes] = await Promise.all([
          axiosInstance.get("/api/user/settings"),
          axiosInstance.get("/api/calendar-status"),
        ])

        const sData = settingsRes.data
        setSettings({
          ...sData,
          workdays: {
            Monday: sData.workdays.includes("Monday"),
            Tuesday: sData.workdays.includes("Tuesday"),
            Wednesday: sData.workdays.includes("Wednesday"),
            Thursday: sData.workdays.includes("Thursday"),
            Friday: sData.workdays.includes("Friday"),
            Saturday: sData.workdays.includes("Saturday"),
            Sunday: sData.workdays.includes("Sunday"),
          },
        })

        setCalendarConnections({
          google: calendarRes.data.isGoogleLinked,
          outlook: calendarRes.data.isOutlookLinked,
        })
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to load settings.",
          variant: "destructive",
        })
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [toast])

  const handleCalendarAction = async (type, action) => {
    try {
      if (action === "connect") {
        const response = await axiosInstance.get(`/api/${type}/connect-calendar`)
        if (response.data.authorization_url) {
          window.location.href = response.data.authorization_url
        }
      } else {
        await axiosInstance.post(`/api/${type}/unlink-calendar`)
        setCalendarConnections((prev) => ({
          ...prev,
          [type]: false,
        }))
        toast({
          title: "Success",
          description: `Successfully unlinked ${type} calendar.`,
        })
      }
    } catch (error) {
      toast({
        title: "Error",
        description: `Failed to ${action} ${type} calendar.`,
        variant: "destructive",
      })
    }
  }

  const handleSaveSettings = async (e) => {
    e.preventDefault()
    setIsSaving(true)

    try {
      const workdaysArray = Object.entries(settings.workdays)
        .filter(([_, isSelected]) => isSelected)
        .map(([day]) => day)

      await axiosInstance.post("/api/user/settings", {
        ...settings,
        workdays: workdaysArray,
      })

      toast({
        title: "Success",
        description: "Settings saved successfully.",
      })
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to save settings.",
        variant: "destructive",
      })
    } finally {
      setIsSaving(false)
    }
  }

  const handleFileUpload = async (event) => {
    const file = event.target.files[0]
    if (!file) return

    const formData = new FormData()
    formData.append("file", file)

    try {
      await axiosInstance.post("/api/upload-calendar", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      toast({
        title: "Success",
        description: "Calendar file uploaded successfully.",
      })
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to upload calendar file.",
        variant: "destructive",
      })
    }
  }

  const handleMoodleSubmit = async () => {
    try {
      await axiosInstance.post("/api/upload-calendar-url", { url: moodleUrl })
      setDialogs((prev) => ({ ...prev, moodle: false }))
      setMoodleUrl("")
      toast({
        title: "Success",
        description: "Moodle calendar connected successfully.",
      })
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to connect Moodle calendar.",
        variant: "destructive",
      })
    }
  }

  const handlePurgeData = async () => {
    try {
      await axiosInstance.post("/api/user/settings/purge")
      setDialogs((prev) => ({ ...prev, purge: false }))
      toast({
        title: "Success",
        description: "All data purged successfully.",
      })
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to purge data.",
        variant: "destructive",
      })
    }
  }

  if (loading) {
    return (
      <Layout>
        <div className="flex h-[80vh] items-center justify-center">
          <div className="flex items-center">
            <span className="mr-2 h-8 w-8 animate-spin rounded-full border-4 border-gray-300 border-t-transparent" />
            <p className="font-semibold">Loading...</p>
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <div className="container my-8 flex max-w-4xl gap-6">
        <Tabs value={activeTab} onValueChange={setActiveTab} className="flex w-full gap-6">
          <TabsList className="flex w-48 flex-col justify-start space-y-2 rounded-lg bg-transparent p-2">
            <TabsTrigger value="calendars" className="flex w-full items-center gap-2 justify-start px-3 py-2">
              <Calendar className="h-4 w-4" />
              <span>Calendars</span>
            </TabsTrigger>
            <TabsTrigger value="workhours" className="flex w-full items-center gap-2 justify-start px-3 py-2">
              <Clock className="h-4 w-4" />
              <span>Work Hours</span>
            </TabsTrigger>
            <TabsTrigger value="account" className="flex w-full items-center gap-2 justify-start px-3 py-2">
              <User className="h-4 w-4" />
              <span>Account</span>
            </TabsTrigger>
          </TabsList>

          <div className="flex-1 space-y-6 rounded-lg bg-card p-6 shadow-md transition-colors">
            <h1 className="mb-4 text-2xl font-bold tracking-tight sm:text-3xl">
              Settings
            </h1>

            <TabsContent value="calendars" className="rounded-md bg-background p-4 shadow-sm">
              <div className="flex flex-col gap-4">
                <h2 className="text-lg font-semibold tracking-tight">
                  Calendar Connections
                </h2>
                <p className="text-sm text-muted-foreground">
                  Quickly link or unlink your favorite calendar apps.
                </p>

                <Button
                  variant="outline"
                  className="h-12 border-red-600 text-red-600 hover:bg-red-500"
                  onClick={() =>
                    handleCalendarAction(
                      "google",
                      calendarConnections.google ? "unlink" : "connect"
                    )
                  }
                >
                  <span className="mr-2 font-bold">G</span>
                  {calendarConnections.google
                    ? "Unlink Google Calendar"
                    : "Connect Google Calendar"}
                </Button>

                <Button
                  variant="outline"
                  className="h-12 border-blue-600 text-blue-600 hover:bg-blue-500"
                  onClick={() =>
                    handleCalendarAction(
                      "outlook",
                      calendarConnections.outlook ? "unlink" : "connect"
                    )
                  }
                >
                  <span className="mr-2">
                    <MicrosoftIcon />
                  </span>
                  {calendarConnections.outlook
                    ? "Unlink Outlook Calendar"
                    : "Connect Outlook Calendar"}
                </Button>
              </div>
            </TabsContent>

            <TabsContent value="workhours" className="rounded-md bg-background p-4 shadow-sm">
              <form onSubmit={handleSaveSettings} className="flex flex-col gap-6">
                <div>
                  <label
                    htmlFor="workStartTime"
                    className="mb-2 block text-sm font-medium"
                  >
                    Work Start Time
                  </label>
                  <Input
                    id="workStartTime"
                    type="time"
                    value={settings.workStartTime}
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        workStartTime: e.target.value,
                      }))
                    }
                    className="white-calendar"
                  />
                </div>

                <div>
                  <label
                    htmlFor="workEndTime"
                    className="mb-2 block text-sm font-medium"
                  >
                    Work End Time
                  </label>
                  <Input
                    id="workEndTime"
                    type="time"
                    value={settings.workEndTime}
                    onChange={(e) =>
                      setSettings((prev) => ({
                        ...prev,
                        workEndTime: e.target.value,
                      }))
                    }
                    className="white-calendar"
                  />
                </div>

                <div>
                  <label
                    htmlFor="timezone"
                    className="mb-2 block text-sm font-medium"
                  >
                    Timezone
                  </label>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="outline"
                        role="combobox"
                        className="w-full justify-between font-normal"
                      >
                        {settings.timezone || "Select a timezone"}
                        <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50"/>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="max-h-[300px] overflow-y-auto">
                      {timezones.map((timezone) => (
                        <DropdownMenuItem
                          key={timezone}
                          onSelect={() => setSettings((prev) => ({...prev, timezone}))}
                          className="flex items-center justify-between"
                        >
                          {timezone}
                          {settings.timezone === timezone && (
                            <Check className="h-4 w-4 text-primary"/>
                          )}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>

                <div>
                  <p className="mb-2 text-sm font-semibold">Work Days</p>
                  <p className="mb-2 text-xs text-muted-foreground">
                    Which days of the week do you usually work?
                  </p>
                  <div className="grid grid-cols-2 gap-2">
                    {Object.entries(settings.workdays).map(([day, checked]) => (
                      <label key={day} className="flex items-center gap-2 text-sm">
                        <Checkbox
                          checked={checked}
                          onCheckedChange={(val) =>
                            setSettings((prev) => ({
                              ...prev,
                              workdays: {
                                ...prev.workdays,
                                [day]: val,
                              },
                            }))
                          }
                        />
                        <span>{day}</span>
                      </label>
                    ))}
                  </div>
                </div>

                <Button
                  type="submit"
                  variant="default"
                  disabled={isSaving}
                  className="h-12"
                >
                  {isSaving ? "Saving..." : "Save Settings"}
                </Button>
              </form>
            </TabsContent>

            <TabsContent value="account" className="rounded-md bg-background p-4 shadow-sm">
              <div className="flex flex-col gap-4">
                <h3 className="text-lg font-semibold tracking-tight">Account</h3>
                <p className="text-sm text-muted-foreground">
                  Manage your subscription or purge your data below.
                </p>
                <div className="flex flex-col gap-2 pt-2">
                  <Button
                    variant="default"
                    className="h-12"
                    onClick={() => {
                      window.open(
                        "https://billing.stripe.com/p/login/00g5mhakicN72L6bII",
                        "_blank"
                      )
                    }}
                  >
                    Manage Subscription
                  </Button>

                  <Button
                    variant="outline"
                    className="h-12 border-red-600 text-red-600 hover:bg-red-50"
                    onClick={() => setDialogs((prev) => ({ ...prev, purge: true }))}
                  >
                    <Trash2 className="mr-2 h-4 w-4" />
                    Purge All Data
                  </Button>
                </div>
              </div>
            </TabsContent>
          </div>
        </Tabs>
      </div>

      {/* Purge Dialog */}
      <Dialog
        open={dialogs.purge}
        onOpenChange={(open) => setDialogs((prev) => ({ ...prev, purge: open }))}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Data Purge</DialogTitle>
            <DialogDescription>
              This action <span className="font-semibold">cannot</span> be undone.
              Do you really want to delete <em>all</em> your data?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="ghost"
              onClick={() => setDialogs((prev) => ({ ...prev, purge: false }))}
            >
              Cancel
            </Button>
            <Button variant="destructive" onClick={handlePurgeData}>
              Confirm Purge
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Layout>
  )
}
