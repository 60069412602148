import {Button} from "../../components/ui/button";
import {CheckCircle, CheckSquare, FolderGit2, HelpCircle, RefreshCw} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";

const WelcomeScreen = ({ onActionClick, chatInput }) => (
  <div className="flex-1 overflow-y-auto">
    <div className="min-h-full flex flex-col items-center justify-center gap-8 p-8">
      <div className="flex items-center gap-2 max-w-2xl">
        <h1 className="text-center text-3xl font-bold">What should we achieve today?</h1>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <HelpCircle className="h-6 w-6 text-muted-foreground hover:text-primary" />
            </TooltipTrigger>
            <TooltipContent side="left" className="max-w-[300px]">
              <p className="font-medium mb-2">I can help you with:</p>
              <ul className="list-disc pl-4 space-y-1 text-sm">
                <li>Creating and managing tasks</li>
                <li>Building and organizing projects</li>
                <li>Tracking daily habits</li>
                <li>Scheduling and calendar management</li>
                <li>Task completion and updates</li>
                <li>Prioritizing your workload</li>
                <li>Calendar synchronization</li>
              </ul>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>

      <div className="w-full max-w-2xl">{chatInput}</div>

      <div className="grid w-full max-w-2xl gap-4 grid-cols-1 sm:grid-cols-2">
        <Button
          variant="default"
          size="lg"
          onClick={() => onActionClick("create a task")}
          className="h-[100px] text-lg"
        >
          <CheckSquare className="mr-3 h-5 w-5" />
          Create a Task
        </Button>
        <Button
          variant="default"
          size="lg"
          onClick={() => onActionClick("create a project")}
          className="h-[100px] text-lg"
        >
          <FolderGit2 className="mr-3 h-5 w-5" />
          Create a Project
        </Button>
        <Button
          variant="default"
          size="lg"
          onClick={() => onActionClick("update my schedule")}
          className="h-[100px] text-lg"
        >
          <RefreshCw className="mr-3 h-5 w-5" />
          Update my Schedule
        </Button>
        <Button
          variant="default"
          size="lg"
          onClick={() => onActionClick("complete some tasks")}
          className="h-[100px] text-lg"
        >
          <CheckCircle className="mr-3 h-5 w-5" />
          Complete Tasks
        </Button>
      </div>
    </div>
  </div>
)

export default WelcomeScreen