import { useState, useEffect } from "react"
import { useAuth } from "../contexts/AuthContext"
import axiosInstance from "../api/axiosInstance"
import HabitForm from "../components/HabitForm"
import HabitDetailForm from "../components/HabitDetailForm"
import habitsIllustration from "../assets/habits_sc.png"
import { useToast } from "../components/ui/toast"

import { Button } from "../components/ui/button"
import { Card, CardContent } from "../components/ui/card"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/ui/dialog"
import {
  Pencil,
  CheckCircle,
  SkipForward,
  Timer,
  Flame,
  Trophy,
  Plus,
} from "lucide-react"
import confetti from "canvas-confetti";

const HabitCard = ({ habit, onComplete, onEdit, onSkip }) => {
  const getTimeWindowDisplay = () => {
    if (!habit.requires_scheduling) return "Flexible Timing"
    return `${habit.window_start || "09:00"} - ${habit.window_end || "17:00"}`
  }

  return (
    <Card className="relative overflow-visible">
      <div
        className="absolute right-[-1px] top-[14px] bottom-[14px] w-1 rounded-md"
        style={{ backgroundColor: habit.hex_color || "#78C2FF" }}
      />
      <CardContent className="p-6">
        <div className="mb-4 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div
              className="h-3 w-3 rounded-full"
              style={{ backgroundColor: habit.hex_color || "#4CAF50" }}
            />
            <h3 className="text-lg font-semibold">{habit.name}</h3>
          </div>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onEdit(habit)}
            className="h-8 w-8"
          >
            <Pencil className="h-4 w-4" />
          </Button>
        </div>

        <div className="mb-4 grid grid-cols-3 gap-4 rounded-lg bg-muted p-3">
          <div className="flex items-center gap-2">
            <Flame className="h-4 w-4 text-destructive" />
            <span className="text-sm text-muted-foreground">
              {habit.current_streak || 0} days
            </span>
          </div>
          <div className="flex items-center gap-2">
            <Trophy className="h-4 w-4 text-yellow-500" />
            <span className="text-sm text-muted-foreground">
              Best: {habit.longest_streak || 0}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <CheckCircle className="h-4 w-4 text-primary" />
            <span className="text-sm text-muted-foreground">
              {habit.total_completions || 0}
            </span>
          </div>
        </div>

        <div className="mb-4 flex justify-between text-sm text-muted-foreground">
          <span>{getTimeWindowDisplay()}</span>
          {habit.requires_scheduling && (
            <div className="flex items-center gap-2">
              <Timer className="h-4 w-4" />
              <span>{habit.estimated_minutes}min</span>
            </div>
          )}
        </div>

        <div className="flex gap-2">
          <Button
            onClick={() => onComplete(habit)}
            className="flex-1"
            variant="default"
          >
            <CheckCircle className="mr-2 h-4 w-4" />
            Completed Today
          </Button>
          <Button
            onClick={() => onSkip(habit)}
            className="flex-1"
            variant="secondary"
          >
            <SkipForward className="mr-2 h-4 w-4" />
            Skipped Today
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}

const EmptyState = ({ onCreateClick }) => (
  <Card className="mx-auto mt-4 max-w-2xl p-6 text-center">
    <div className="flex flex-col items-center gap-6">
      <img
        src={habitsIllustration}
        alt="Habits illustration"
        className="mb-4 h-auto w-full max-w-lg"
      />

      <p className="mb-4 max-w-lg text-muted-foreground">
        Habits are automatically scheduled into your calendar within the time
        window you choose. This way, you can rely on our smart scheduling
        algorithm to ensure you always have time for the things that matter most
        to you.
      </p>

      <Button
        variant="default"
        size="lg"
        onClick={onCreateClick}
        className="gap-2"
      >
        <Plus className="h-5 w-5" />
        Create Your First Habit
      </Button>
    </div>
  </Card>
)

function HabitsPage(refreshHabits, habit) {
  const { auth } = useAuth()
  const { toast } = useToast()
  const [habits, setHabits] = useState([])
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [detailModalOpen, setDetailModalOpen] = useState(false)
  const [selectedHabit, setSelectedHabit] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const MAX_FREE_HABITS = 1
  const MAX_PREMIUM_HABITS = 3
  const maxHabits = auth?.isSubscribed ? MAX_PREMIUM_HABITS : MAX_FREE_HABITS

  const fetchHabits = async () => {
    try {
      const response = await axiosInstance.get("/api/habits")
      setHabits(response.data.habits)
      setLoading(false)
    } catch (err) {
      setError("Failed to fetch habits")
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchHabits()
  }, [])

  const handleCreateOpen = () => setCreateModalOpen(true)
  const handleCreateClose = () => setCreateModalOpen(false)
  const handleDetailOpen = (habit) => {
    setSelectedHabit(habit)
    setDetailModalOpen(true)
  }
  const handleDetailClose = () => {
    setSelectedHabit(null)
    setDetailModalOpen(false)
  }

  const fireConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    })
    confetti({
      particleCount: 50,
      angle: 60,
      spread: 55,
      origin: { x: 0 }
    })
    confetti({
      particleCount: 50,
      angle: 120,
      spread: 55,
      origin: { x: 1 }
    })
  }

  const handleComplete = async (habit) => {
    try {
      await axiosInstance.post(`/api/habits/${habit.id}/complete`)
      fireConfetti()
      await fetchHabits()
      toast({
        title: "Success!",
        description: `${habit.name} completed for today.`,
      })
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: error.response?.data?.message || 'Error completing the habit.'
      })
    }
  }

  const handleSkip = async (habit) => {
    try {
      await axiosInstance.post(`/api/habits/${habit.id}/skip`)
      await fetchHabits()
      toast({
        title: "Skipped",
        description: `${habit.name} skipped for today.`,
      })
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: error.response?.data?.message || 'Error skipping the habit.'
      })
    }
  }

  if (loading) {
    return (
      <div className="flex h-[50vh] items-center justify-center">
        <div className="flex items-center gap-2">
          <div className="h-4 w-4 animate-spin rounded-full border-2 border-primary border-t-transparent" />
          <span>Loading habits...</span>
        </div>
      </div>
    )
  }

  return (
    <div className="p-6">
      {habits.length > 0 ? (
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {habits.map((habit) => (
            <HabitCard
              key={habit.id}
              habit={habit}
              onComplete={handleComplete}
              onEdit={handleDetailOpen}
              onSkip={handleSkip}
            />
          ))}
        </div>
      ) : (
        <EmptyState onCreateClick={handleCreateOpen} />
      )}

      {/* Create Habit Dialog */}
      <Dialog open={createModalOpen} onOpenChange={setCreateModalOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Add a New Habit</DialogTitle>
          </DialogHeader>
          <HabitForm onClose={handleCreateClose} refreshHabits={fetchHabits} />
        </DialogContent>
      </Dialog>

      {/* Detail/Edit Dialog */}
      <Dialog open={detailModalOpen} onOpenChange={setDetailModalOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <HabitDetailForm
            habit={selectedHabit}
            onClose={handleDetailClose}
            refreshHabits={fetchHabits}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default HabitsPage