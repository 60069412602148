// components/chat/ThinkingIndicator.jsx
import DorisLogo from "../../assets/DorisAILogo.webp"

const ThinkingIndicator = () => {
  return (
    <div className="mt-4 flex w-full justify-center">
      <div className="flex w-full max-w-[1200px] justify-start">
        <div className="mr-2 flex items-start">
          <img
            src={DorisLogo}
            alt="Doris AI"
            className="h-8 w-8 rounded-full object-cover"
          />
        </div>
        <div className="flex items-center">
          <span className="inline-block text-lg font-medium">
            <span className="inline-block bg-gradient-to-r from-transparent via-muted-foreground to-transparent
              bg-[length:200%_100%] animate-shine bg-clip-text text-transparent">
              Thinking...
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default ThinkingIndicator