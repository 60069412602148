import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import Layout from "../components/Layout"
import TimeDistributionChart from "../components/analytics/TimeDistributionChart"
import CapacityChart from "../components/analytics/CapacityChart"
import CompletionTrendsChart from "../components/analytics/CompletionTrendsChart"
import TimeByTagChart from "../components/analytics/TimeByTagChart"
import ProductivityScoreChart from "../components/analytics/ProductivityScoreChart"
import StatSummary from "../components/analytics/StatSummary"
import { cn } from "../lib/utils"
import FeedbackForm from "../components/FeedbackForm"

import { Button } from "../components/ui/button"
import { Card, CardContent } from "../components/ui/card"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip"
import { RefreshCw, Lock } from "lucide-react"
import {Dialog, DialogTrigger, DialogContent, DialogTitle, DialogHeader} from "../components/ui/dialog";


const Content = ({ loading }) => {
  if (loading) {
    return (
      <div className="flex h-[50vh] items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent" />
      </div>
    )
  }

  return (
    <>
      <div>
        <StatSummary />
      </div>
      <div>
        <TimeDistributionChart />
      </div>
      <div>
        <CapacityChart />
      </div>
    </>
  )
}

export default function AnalyticsPage() {
  const { auth } = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  // Add useEffect to handle loading state based on auth status
  useEffect(() => {
    setLoading(false) // Stop loading once we have auth status
  }, [auth.isSubscribed])

  return (
    <Layout>
      <div className="flex h-screen flex-col">
        {/* Header */}
        <div className="sticky top-0 z-50 border-b bg-background/95 p-6 backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <div className="flex justify-between">
            <div>
              <h1 className="mb-1 text-3xl font-bold">Insights</h1>
              <p className="text-muted-foreground">
                Track your productivity and time allocation across all activities.
              </p>
            </div>

            <div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                  </TooltipTrigger>
                  <TooltipContent>
                    {auth.isSubscribed ? "Refresh data" : "Upgrade to refresh data"}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>

              <Dialog>
                <DialogTrigger asChild>
                  <Button variant="outline" size="sm">
                    Suggest New Charts!
                  </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                  <DialogHeader>
                    <DialogTitle>Submit Feedback</DialogTitle>
                  </DialogHeader>
                  <FeedbackForm />
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 space-y-8 overflow-auto p-6">
          <Content loading={loading} />

          {/* Premium Overlay */}
          {!auth.isSubscribed && (
            <div className="fixed inset-0 left-[240px] z-50 flex items-center justify-center bg-background/80 backdrop-blur-sm">
              <Card className="max-w-md">
                <CardContent className="flex flex-col items-center space-y-4 p-6">
                  <Lock className="h-12 w-12 text-primary" />
                  <h2 className="text-2xl font-semibold">
                    Unlock your productivity insights
                  </h2>
                  <p className="text-center text-muted-foreground">
                    Get detailed insights into your productivity patterns.
                  </p>
                  <Button
                    size="lg"
                    className="mt-4"
                    onClick={() => navigate("/subscribe")}
                  >
                    Upgrade Now
                  </Button>
                </CardContent>
              </Card>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}