import React, { useState, useEffect } from "react"
import { Clock, Wand2, Loader2 } from "lucide-react"
import axiosInstance from "../api/axiosInstance"

// shadcn/ui components
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Badge } from "../components/ui/badge"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../components/ui/dialog"
import { useToast } from "../components/ui/toast"
import {Separator} from "../components/ui/separator";

const AVAILABLE_TAGS = [
  "Email",
  "Admin",
  "Planning",
  "Research",
  "Learning",
  "Implementation",
  "Other"
]

const TAG_STYLES_DEFAULT = {
  Email: "border-blue-400 text-blue-500",
  Admin: "border-purple-400 text-purple-500",
  Planning: "border-green-400 text-green-500",
  Research: "border-amber-400 text-amber-500",
  Learning: "border-pink-400 text-pink-500",
  Implementation: "border-indigo-400 text-indigo-500",
  Other: "border-gray-400 text-gray-500"
}

const TAG_STYLES_SELECTED = {
  Email: "border-blue-400 text-blue-500 bg-blue-50 dark:bg-blue-500/10",
  Admin: "border-purple-400 text-purple-500 bg-purple-50 dark:bg-purple-500/10",
  Planning: "border-green-400 text-green-500 bg-green-50 dark:bg-green-500/10",
  Research: "border-amber-400 text-amber-500 bg-amber-50 dark:bg-amber-500/10",
  Learning: "border-pink-400 text-pink-500 bg-pink-50 dark:bg-pink-500/10",
  Implementation: "border-indigo-400 text-indigo-500 bg-indigo-50 dark:bg-indigo-500/10",
  Other: "border-gray-400 text-gray-500 bg-gray-50 dark:bg-gray-500/10"
}

const PRIORITY_OPTIONS = [
  { value: "urgent_important", label: "Urgent & Important" },
  { value: "important_not_urgent", label: "Important but Not Urgent" },
  { value: "urgent_not_important", label: "Urgent but Not Important" },
  { value: "not_important_not_urgent", label: "Not Important or Urgent" },
]

const PRIORITY_STYLES = {
  urgent_important: "border-red-400 text-red-500 hover:bg-red-50 dark:hover:bg-red-500/10",
  important_not_urgent: "border-orange-400 text-orange-500 hover:bg-orange-50 dark:hover:bg-orange-500/10",
  urgent_not_important: "border-blue-400 text-blue-500 hover:bg-blue-50 dark:hover:bg-blue-500/10",
  not_important_not_urgent: "border-gray-400 text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-500/10"
}

const PRIORITY_STYLES_SELECTED = {
  urgent_important: "bg-red-50 text-red-500 dark:bg-red-500/10",
  important_not_urgent: "bg-orange-50 text-orange-500 dark:bg-orange-500/10",
  urgent_not_important: "bg-blue-50 text-blue-500 dark:bg-blue-500/10",
  not_important_not_urgent: "bg-gray-50 text-gray-500 dark:bg-gray-500/10"
}

function formatDateTimeLocal(dateString) {
  if (!dateString) return ""
  const date = new Date(dateString)
  const offset = date.getTimezoneOffset()
  const localDate = new Date(date.getTime() - offset * 60 * 1000)
  return localDate.toISOString().slice(0, 16)
}

export default function TaskForm({ onClose, refreshTasks, task }) {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    requires_scheduling: false,
    estimated_minutes: "",
    due_date: formatDateTimeLocal(new Date().setHours(17, 0, 0, 0)),
    priority: "important_not_urgent",
    tag: "Other",
  })

  const [errorMessage, setErrorMessage] = useState("")
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const { toast } = useToast()

  useEffect(() => {
    if (task) {
      setFormData({
        name: task.name || "",
        description: task.description || "",
        estimated_minutes: task.estimated_minutes || "",
        due_date: task.due_date ? formatDateTimeLocal(task.due_date) : formatDateTimeLocal(new Date().setHours(17, 0, 0, 0)),
        priority: task.priority || "important_not_urgent",
        requires_scheduling: task.requires_scheduling ?? false,
        tag: task.tag || "Other",
      })
    }
  }, [task])

  const handleGenerate = async () => {
      if (!formData.name.trim()) return

      setIsGenerating(true)
      try {
          const { data } = await axiosInstance.post('/api/preview-task', {
              task_name: formData.name
          })

          if (data.success && data.suggestions) {
              const suggestions = data.suggestions

              // Description
              setTimeout(() => {
                  setFormData(prev => ({
                      ...prev,
                      description: suggestions.description || ""
                  }))
              }, 100)

              // Estimated minutes
              setTimeout(() => {
                  setFormData(prev => ({
                      ...prev,
                      estimated_minutes: suggestions.estimated_minutes || ""
                  }))
              }, 300)

              // Due date
              setTimeout(() => {
                  setFormData(prev => ({
                      ...prev,
                      due_date: suggestions.due_date ? formatDateTimeLocal(suggestions.due_date) : prev.due_date
                  }))
              }, 500)

              // Priority
              setTimeout(() => {
                  setFormData(prev => ({
                      ...prev,
                      priority: suggestions.priority || "important_not_urgent"
                  }))
              }, 700)

              // Requires scheduling
              setTimeout(() => {
                  setFormData(prev => ({
                      ...prev,
                      requires_scheduling: suggestions.requires_scheduling ?? true
                  }))
              }, 900)

              // Tag
              setTimeout(() => {
                  setFormData(prev => ({
                      ...prev,
                      tag: suggestions.tag || "Other"
                  }))
              }, 1100)

              // Success toast at the end
              setTimeout(() => {
                  toast({
                      title: "Success",
                      description: "Task generated. Make sure to review and adjust if needed!",
                      variant: "default",
                  })
              }, 1300)
          }
      } catch (error) {
          const errorMsg = error.response?.data?.error || "Failed to create task suggestion"
          setErrorMessage(errorMsg)
          setIsErrorModalOpen(true)
          toast({
              title: "Error",
              description: errorMsg,
              variant: "destructive",
          })
      } finally {
          // Remove generating state after all animations complete
          setTimeout(() => {
              setIsGenerating(false)
          }, 1500)
      }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setErrorMessage("")

    if (!formData.name.trim()) {
      setErrorMessage("Please provide a task name.")
      setIsErrorModalOpen(true)
      return
    }

    if (!formData.tag) {
      setErrorMessage("Please select a tag for the task.")
      setIsErrorModalOpen(true)
      return
    }

    if (
      formData.requires_scheduling &&
      (!formData.estimated_minutes || parseInt(formData.estimated_minutes) <= 0)
    ) {
      setErrorMessage("Please set valid estimated minutes for scheduled tasks.")
      setIsErrorModalOpen(true)
      return
    }

    const taskData = {
      ...formData,
      estimated_minutes: parseInt(formData.estimated_minutes) || 0,
    }

    try {
      if (task) {
        await axiosInstance.put(`/api/tasks/${task.id}`, taskData)
      } else {
        await axiosInstance.post("/api/tasks", taskData)
      }

      if (refreshTasks) {
        refreshTasks()
      }
      if (onClose) {
        onClose()
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ||
        "Error saving the task. Please try again."
      )
      setIsErrorModalOpen(true)
    }
  }

  const handleChange = (field) => (event) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }))
  }

  return (
    <div className="max-h-[80vh] overflow-y-auto p-4">
        <form onSubmit={handleSubmit} className="space-y-4">
            {/* Task Name with Generate Button */}
            <div className="flex flex-col">
                <label className="mb-1 text-sm font-medium" htmlFor="taskName">
                    Task Name
                </label>
                <div className="flex gap-2">
                    <Input
                        id="taskName"
                        placeholder="Write a clear task title..."
                        value={formData.name}
                        onChange={handleChange("name")}
                        required
                        className="flex-1"
                    />
                    <Button
                        type="button"
                        variant="outline"
                        onClick={handleGenerate}
                        disabled={isGenerating || !formData.name.trim()}
                        className="gap-2 whitespace-nowrap"
                    >
                        {isGenerating ? (
                            <>
                                <Loader2 className="h-4 w-4 animate-spin"/>
                                <span>Generating...</span>
                            </>
                        ) : (
                            <>
                                <Wand2 className="h-4 w-4"/>
                                <span>Generate</span>
                            </>
                        )}
                    </Button>
                </div>
            </div>

            <div className="relative my-6">
                <div className="absolute inset-0 flex items-center">
                    <Separator className="w-full"/>
                </div>
                <div className="relative flex justify-center text-xs">
                    <span className="bg-background px-2 text-muted-foreground">or create manually</span>
                </div>
            </div>

            {/* Scheduling Toggle */}
            <div className="flex flex-col">
                <label className="mb-1 text-sm font-medium">
                    Scheduling
                </label>
                <Button
                    type="button"
                    variant="outline"
                    onClick={() =>
                        setFormData(prev => ({
                            ...prev,
                            requires_scheduling: !prev.requires_scheduling
                        }))
                    }
                    className={`w-full justify-between ${
                        formData.requires_scheduling
                            ? "border-primary text-primary hover:bg-primary/10"
                            : "text-muted-foreground"
                    }`}
                >
            <span>
              {formData.requires_scheduling ? "Must Schedule" : "No Scheduling Required"}
            </span>
                    <div className={`h-4 w-8 rounded-full transition-colors ${
                        formData.requires_scheduling ? "bg-primary" : "bg-muted"
                    }`}>
                        <div className={`h-3 w-3 translate-y-0.5 rounded-full bg-background transition-transform ${
                            formData.requires_scheduling ? "translate-x-4" : "translate-x-1"
                        }`}/>
                    </div>
                </Button>
            </div>

            {/* Description */}
            <div className="flex flex-col">
                <label className="mb-1 text-sm font-medium" htmlFor="taskDescription">
                    Description
                </label>
                <textarea
                    id="taskDescription"
                    className="min-h-[100px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm focus:outline-none focus:ring-1 focus:ring-ring"
                    placeholder="Add additional details or notes..."
                    rows={3}
                    value={formData.description}
                    onChange={handleChange("description")}
                />
            </div>

            {/* Tag Selection */}
            <div className="flex flex-col">
                <label className="mb-1 text-sm font-medium">
                    Tag
                </label>
                <div className="flex mt-1 flex-wrap gap-2">
                    {AVAILABLE_TAGS.map((tag) => (
                        <Badge
                            key={tag}
                            variant="outline"
                            className={`cursor-pointer border ${
                                formData.tag === tag ? TAG_STYLES_SELECTED[tag] : TAG_STYLES_DEFAULT[tag]
                            }`}
                            onClick={() => setFormData(prev => ({...prev, tag}))}
                        >
                            {tag}
                        </Badge>
                    ))}
                </div>
            </div>

            {formData.requires_scheduling && (
                <>
                    {/* Priority */}
                    <div className="flex flex-col">
                        <label className="mb-1 text-sm font-medium">
                            Priority
                        </label>
                        <div className="flex flex-wrap gap-2">
                            {PRIORITY_OPTIONS.map((option) => (
                                <Button
                                    key={option.value}
                                    type="button"
                                    variant={formData.priority === option.value ? "default" : "outline"}
                                    onClick={() => setFormData(prev => ({...prev, priority: option.value}))}
                                    className="h-8 flex-1"
                                >
                                    {option.label}
                                </Button>
                            ))}
                        </div>
                    </div>

                    <div className="flex gap-4">
                        {/* Estimated Minutes */}
                        <div className="flex flex-col flex-1">
                            <label className="mb-1 text-sm font-medium" htmlFor="minutesInput">
                                Estimated Minutes
                            </label>
                            <div className="relative flex items-center">
                                <Clock className="pointer-events-none absolute left-3 h-4 w-4 text-muted-foreground"/>
                                <Input
                                    id="minutesInput"
                                    type="number"
                                    className="pl-9"
                                    placeholder="e.g. 30"
                                    value={formData.estimated_minutes}
                                    onChange={handleChange("estimated_minutes")}
                                    required
                                />
                            </div>
                        </div>

                        {/* Due Date */}
                        <div className="flex flex-col flex-1">
                            <label className="mb-1 text-sm font-medium" htmlFor="dueDate">
                                Due Date
                            </label>
                            <Input
                                id="dueDate"
                                type="datetime-local"
                                value={formData.due_date}
                                onChange={handleChange("due_date")}
                                className="white-calendar"
                                step="300"
                            />
                        </div>
                    </div>
                </>
            )}

            {/* Submit Button */}
            <Button type="submit" variant="default" className="w-full">
                {task ? "Update Task" : "Create Task"}
            </Button>
        </form>

        {/* Error Dialog */}
        <Dialog open={isErrorModalOpen} onOpenChange={setIsErrorModalOpen}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Error</DialogTitle>
                    <DialogDescription>{errorMessage}</DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <Button variant="default" onClick={() => setIsErrorModalOpen(false)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}