import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
import { Alert, AlertDescription } from '../../components/ui/alert';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts';
import axiosInstance from '../../api/axiosInstance';
import TimeByTagChart from './TimeByTagChart';

const TimeDistributionChart = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Theme-aware colors using theme variables
  const COLORS = {
    'Projects': 'hsl(var(--chart-1))',
    'Tasks': 'hsl(var(--chart-2))',
    'Habits': 'hsl(var(--chart-3))',
    'Calendar Events': 'hsl(var(--chart-4))'
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: response } = await axiosInstance.get('/api/analytics/time_distribution');
        setData(response);
        setError(null);
      } catch (error) {
        console.error('Error fetching time distribution:', error);
        setError('Failed to load time distribution data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours === 0) return `${remainingMinutes}m`;
    if (remainingMinutes === 0) return `${hours}h`;
    return `${hours}h ${remainingMinutes}m`;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="rounded-lg border bg-card p-3 shadow-sm backdrop-blur">
          <p className="font-medium">{data.name}</p>
          {data.minutes ? (
            <>
              <p className="text-sm text-muted-foreground">
                {formatMinutes(data.minutes)}
              </p>
              <p className="text-sm text-muted-foreground">
                {data.percentage}% of total
              </p>
            </>
          ) : (
            <p className="text-sm text-muted-foreground">
              {data.value}%
            </p>
          )}
        </div>
      );
    }
    return null;
  };

  const CustomLegend = ({ payload }) => {
    return (
      <div className="mt-4 flex flex-wrap justify-center gap-4">
        {payload.map((entry) => (
          <div key={entry.value} className="flex items-center gap-2">
            <div
              className="h-2 w-2 rounded-full"
              style={{ backgroundColor: entry.color }}
            />
            <span className="text-sm text-muted-foreground">
              {entry.value}
            </span>
          </div>
        ))}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex h-[400px] items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex h-[400px] items-center justify-center p-6">
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </div>
    );
  }

  return (
    <div className="grid gap-4 md:grid-cols-12">
      <div className="md:col-span-6">
        <Card>
          <CardHeader>
            <CardTitle>Future time breakdown</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-[400px] w-full">
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={data.categories}
                    dataKey="minutes"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius="60%"
                    outerRadius="80%"
                    paddingAngle={2}
                  >
                    {data.categories.map((entry) => (
                      <Cell
                        key={entry.name}
                        fill={COLORS[entry.name]}
                        className="stroke-background"
                        strokeWidth={2}
                      />
                    ))}
                  </Pie>
                  <Tooltip content={<CustomTooltip />} />
                  <Legend content={<CustomLegend />} />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="md:col-span-6">
        <TimeByTagChart />
      </div>
    </div>
  );
};

export default TimeDistributionChart;