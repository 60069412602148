import React, { useState, useEffect } from 'react'
import confetti from 'canvas-confetti'
import { useAuth } from '../contexts/AuthContext'
import axiosInstance from '../api/axiosInstance'
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../components/ui/dialog"
import { Card, CardContent } from "../components/ui/card"
import { Separator } from "../components/ui/separator"
import {
  Flame,
  Trophy,
  CheckCircle2,
  SkipForward,
  Timer,
  Trash2
} from "lucide-react"

const COLORS = [
  '#F3A160', '#2E3060', '#C6D8AF', '#6CCFF6', '#9999C3',
  '#FFCC99', '#A3D9FF', '#FF9999', '#B5A1FF', '#FFE599'
]

function HabitDetailForm({ habit, onClose, refreshHabits }) {
  const { auth } = useAuth()
  const [habitName, setHabitName] = useState(habit?.name || '')
  const [estimatedMinutes, setEstimatedMinutes] = useState(habit?.estimated_minutes || '')
  const [windowStart, setWindowStart] = useState(habit?.window_start || '09:00')
  const [windowEnd, setWindowEnd] = useState(habit?.window_end || '17:00')
  const [selectedColor, setSelectedColor] = useState(habit?.hex_color || COLORS[0])
  const [errorMessage, setErrorMessage] = useState('')
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  useEffect(() => {
    if (habit) {
      setHabitName(habit.name)
      setEstimatedMinutes(habit.estimated_minutes || '')
      setSelectedColor(habit.hex_color || COLORS[0])
      setWindowStart(habit.window_start || '09:00')
      setWindowEnd(habit.window_end || '17:00')
    }
  }, [habit])

  const fireConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    })
    confetti({
      particleCount: 50,
      angle: 60,
      spread: 55,
      origin: { x: 0 }
    })
    confetti({
      particleCount: 50,
      angle: 120,
      spread: 55,
      origin: { x: 1 }
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setErrorMessage('')

    try {
      if (!habitName.trim()) {
        throw new Error('Please provide a habit name.')
      }

      if (!estimatedMinutes || parseInt(estimatedMinutes) <= 0) {
        throw new Error('Please set valid estimated minutes.')
      }

      if (windowStart >= windowEnd) {
        throw new Error('End time must be after start time.')
      }

      const habitData = {
        name: habitName,
        requires_scheduling: true,
        estimated_minutes: parseInt(estimatedMinutes),
        hex_color: selectedColor,
        window_start: windowStart,
        window_end: windowEnd,
      }

      await axiosInstance.put(`/api/habits/${habit.id}`, habitData)
      await refreshHabits()
      onClose()
    } catch (error) {
      const message = error.response?.data?.message || error.message || 'Error saving the habit.'
      setErrorMessage(message)
      setIsErrorModalOpen(true)
    }
  }

  const handleComplete = async () => {
    try {
      await axiosInstance.post(`/api/habits/${habit.id}/complete`)
      fireConfetti()
      if (refreshHabits) refreshHabits()
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error completing the habit.')
      setIsErrorModalOpen(true)
    }
  }

  const handleSkip = async () => {
    try {
      await axiosInstance.post(`/api/habits/${habit.id}/skip`)
      if (refreshHabits) refreshHabits()
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error skipping the habit.')
      setIsErrorModalOpen(true)
    }
  }

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/api/habits/${habit.id}`)
      setIsDeleteModalOpen(false)
      if (refreshHabits) refreshHabits()
      if (onClose) onClose()
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error deleting the habit.')
      setIsErrorModalOpen(true)
    }
  }

  return (
    <div className="max-h-[80vh] overflow-y-auto p-4">
      {/* Stats Section */}
      <Card className="mb-6 bg-muted/50">
        <CardContent className="grid grid-cols-2 md:grid-cols-4 gap-4 p-4">
          <div className="flex items-center gap-2">
            <Flame className="h-4 w-4 text-destructive" />
            <span className="text-sm">Current Streak: {habit?.current_streak || 0}</span>
          </div>
          <div className="flex items-center gap-2">
            <Trophy className="h-4 w-4 text-primary" />
            <span className="text-sm">Best Streak: {habit?.longest_streak || 0}</span>
          </div>
          <div className="text-sm">
            Total Completions: {habit?.total_completions || 0}
          </div>
          <div className="text-sm">
            Times Skipped: {habit?.skipped_count || 0}
          </div>
        </CardContent>
      </Card>

      {/* Action Buttons */}
      <div className="grid grid-cols-2 gap-4 mb-6">
        <Button
          variant="default"
          className="w-full bg-emerald-600 hover:bg-emerald-700"
          onClick={handleComplete}
        >
          <CheckCircle2 className="mr-2 h-4 w-4" />
          Complete Today
        </Button>
        <Button
          variant="default"
          className="w-full bg-orange-500 hover:bg-orange-600"
          onClick={handleSkip}
        >
          <SkipForward className="mr-2 h-4 w-4" />
          Skip Today
        </Button>
      </div>

      <Separator className="my-6" />

      {/* Edit Form Section Header */}
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Edit Habit</h3>
        <Button
          type="button"
          variant="ghost"
          size="icon"
          className="hover:bg-destructive hover:text-destructive-foreground"
          onClick={() => setIsDeleteModalOpen(true)}
        >
          <Trash2 className="h-4 w-4" />
        </Button>
      </div>

      {/* Edit Form */}
      <form onSubmit={handleSubmit} className="space-y-4">
        <Input
          placeholder="Habit Name"
          value={habitName}
          onChange={(e) => setHabitName(e.target.value)}
          className="w-full"
        />

        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <Timer className="h-4 w-4" />
            <span className="text-sm font-medium">Time Window:</span>
          </div>
          <div className="flex items-center gap-4">
            <Input
              type="time"
              value={windowStart}
              onChange={(e) => setWindowStart(e.target.value)}
              className="flex-1"
            />
            <span>to</span>
            <Input
              type="time"
              value={windowEnd}
              onChange={(e) => setWindowEnd(e.target.value)}
              className="flex-1"
            />
          </div>
        </div>

        <Input
          type="number"
          placeholder="Estimated Minutes"
          value={estimatedMinutes}
          onChange={(e) => setEstimatedMinutes(e.target.value)}
          className="w-full"
        />

        <div className="flex justify-center">
          <div className="flex flex-wrap gap-2 justify-center">
            {COLORS.map((color) => (
              <button
                key={color}
                type="button"
                onClick={() => setSelectedColor(color)}
                className={`w-6 h-6 rounded-full cursor-pointer transition-all ${
                  selectedColor === color ? 'ring-2 ring-offset-2 ring-ring' : ''
                }`}
                style={{ backgroundColor: color }}
              />
            ))}
          </div>
        </div>

        <Button type="submit" className="w-full">
          Save Changes
        </Button>
      </form>

      {/* Error Modal */}
      <Dialog open={isErrorModalOpen} onOpenChange={setIsErrorModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Error</DialogTitle>
          </DialogHeader>
          <DialogDescription>{errorMessage}</DialogDescription>
          <DialogFooter>
            <Button variant="secondary" onClick={() => setIsErrorModalOpen(false)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Modal */}
      <Dialog open={isDeleteModalOpen} onOpenChange={setIsDeleteModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Habit</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            Are you sure you want to delete this habit? This action cannot be undone.
          </DialogDescription>
          <DialogFooter className="flex justify-between sm:justify-between">
            <Button variant="outline" onClick={() => setIsDeleteModalOpen(false)}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleDelete}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default HabitDetailForm