import { useState } from "react"
import { useNavigate } from "react-router-dom"
import axiosInstance from "../api/axiosInstance"
import Cookies from "js-cookie"
import { Check, Loader2 } from "lucide-react"
import Layout from "../components/Layout"

import { Button } from "../components/ui/button"
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../components/ui/card"
import { Badge } from "../components/ui/badge"

const PlanFeature = ({ children }) => {
  return (
    <div className="flex items-center space-x-2 py-2">
      <Check className="h-4 w-4 text-primary" />
      <span className="text-sm">{children}</span>
    </div>
  )
}

const SubscribePage = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubscribe = async () => {
    setLoading(true)
    try {
      const fbpCookie = Cookies.get("_fbp") || null
      const fbcCookie = Cookies.get("_fbc") || null
      const response = await axiosInstance.post("/api/user/subscribe", {
        fbp: fbpCookie,
        fbc: fbcCookie,
      })
      if (response.status === 200) {
        window.location.href = response.data.checkout_url
      }
    } catch (error) {
      console.error("Error:", error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Layout>
      <div className="container max-w-5xl px-6 py-20">
        <div className="text-center">
          <h1 className="text-4xl font-bold">Upgrade to Plus</h1>
          <p className="mt-2 text-lg text-muted-foreground">
            Unlock all features with our Plus plan
          </p>
        </div>

        <div className="mt-16 grid gap-8 md:grid-cols-3">
          {/* Free Plan */}
          <Card className="relative flex h-full flex-col border bg-card">
            <CardHeader>
              <CardTitle className="text-2xl">Free</CardTitle>
              <div className="flex items-baseline gap-1">
                <span className="text-4xl font-bold">$0</span>
                <span className="text-sm text-muted-foreground">/ month</span>
              </div>
              <CardDescription className="mt-4">
                A great starting point to simplify your calendar and tackle a few key projects with ease.
              </CardDescription>
            </CardHeader>
            <CardContent className="flex flex-1 flex-col justify-between">
              <div className="mt-6 flex-1 space-y-2">
                <PlanFeature>30 DorisAI responses per month</PlanFeature>
                <PlanFeature>Up to 2 projects</PlanFeature>
                <PlanFeature>Up to 3 tasks per project</PlanFeature>
                <PlanFeature>Connect to Outlook & Google Calendar</PlanFeature>
                <PlanFeature>Schedular Lite</PlanFeature>
              </div>
              <div className="h-[40px]" /> {/* Spacer to match button height */}
            </CardContent>
          </Card>

          {/* Plus Plan */}
          <div className="relative pt-4">
            <Badge
              className="absolute -right-5 -top-3 z-20 px-3 py-1 text-sm font-semibold"
              variant="default"
            >
              Recommended
            </Badge>
            <Card className="relative flex h-full flex-col scale-105 border-2 border-primary bg-card">
              <CardHeader>
                <CardTitle className="text-2xl">Plus</CardTitle>
                <div className="flex items-baseline gap-1">
                  <span className="text-4xl font-bold">$10</span>
                  <span className="text-sm text-muted-foreground">/ month</span>
                </div>
                <CardDescription className="mt-4">
                  Ideal for professionals ready to streamline unlimited projects and tasks seamlessly.
                </CardDescription>
              </CardHeader>
              <CardContent className="flex flex-1 flex-col justify-between">
                <div className="mt-6 flex-1 space-y-2">
                  <PlanFeature>Everything in Free, and...</PlanFeature>
                  <PlanFeature>Scheduler Pro</PlanFeature>
                  <PlanFeature>Insights</PlanFeature>
                  <PlanFeature>Unlimited projects</PlanFeature>
                  <PlanFeature>Unlimited tasks per project</PlanFeature>
                  <PlanFeature>Unlimited standalone Tasks</PlanFeature>
                </div>
                <Button
                  className="mt-6"
                  onClick={handleSubscribe}
                  disabled={loading}
                >
                  {loading ? (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  ) : (
                    "Get Started"
                  )}
                </Button>
              </CardContent>
            </Card>
          </div>

          {/* Teams Plan */}
          <Card className="relative flex h-full flex-col border bg-card">
            <CardHeader>
              <CardTitle className="text-2xl">Team</CardTitle>
              <div className="flex items-baseline gap-1">
                <span className="text-2xl font-bold">Contact Us</span>
              </div>
              <CardDescription className="mt-4">
                Empower your team with insights, advanced integrations, and unlimited scheduling capacity.
              </CardDescription>
            </CardHeader>
            <CardContent className="flex flex-1 flex-col justify-between">
              <div className="mt-6 flex-1 space-y-2">
                <PlanFeature>Everything in Plus, and...</PlanFeature>
                <PlanFeature>Team Insights</PlanFeature>
                <PlanFeature>Integrations including ClickUp, Todoist</PlanFeature>
                <PlanFeature>Dedicated Account Manager</PlanFeature>
              </div>
              <Button
                className="mt-6"
                variant="outline"
                onClick={() => window.open("https://www.meetdoris.com", "_blank")}
              >
                Contact Us
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
    </Layout>
  )
}

export default SubscribePage