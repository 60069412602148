import { useState, useEffect } from "react"
import { useAuth } from "../../contexts/AuthContext"
import axiosInstance from "../../api/axiosInstance"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"

export function ProductivityScoreChart() {
  const { auth } = useAuth()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const COLORS = {
    completion: "hsl(var(--chart-1))",
    timeliness: "hsl(var(--chart-2))",
    streak: "hsl(var(--chart-3))"
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!auth.isSubscribed) {
        // Set dummy data for non-subscribed users
        setData({
          productivity_score: 75,
          task_metrics: {
            completion_rate: 80,
            timeliness_rate: 70,
            total_tasks: 50,
            completed_tasks: 40,
            on_time_tasks: 35
          },
          project_metrics: {
            completion_rate: 75,
            timeliness_rate: 65,
            total_projects: 10,
            completed_projects: 7,
            on_time_projects: 6
          },
          habit_metrics: {
            completion_rate: 85,
            average_streak: 5
          }
        });
        setLoading(false);
        return;
      }

      try {
        const response = await axiosInstance.get("/api/analytics/historic/productivity_score");
        setData(response.data);
        setError(null);
      } catch (err) {
        console.error("Error fetching productivity score:", err);
        setError("Failed to load productivity data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [auth.isSubscribed]);

  if (loading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Productivity Metrics</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex h-[400px] items-center justify-center">
            <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Productivity Metrics</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex h-[400px] items-center justify-center text-muted-foreground">
            {error}
          </div>
        </CardContent>
      </Card>
    );
  }

  // If no data available, show empty state
  if (!data) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Productivity Metrics</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid gap-4">
            <div className="flex items-center justify-center text-2xl font-bold">
              Productivity Score: 0%
            </div>
            <div className="grid grid-cols-3 gap-4 mb-6">
              <div className="text-center">
                <h3 className="text-lg font-medium">Tasks</h3>
                <p className="text-sm text-muted-foreground">0 / 0</p>
              </div>
              <div className="text-center">
                <h3 className="text-lg font-medium">Projects</h3>
                <p className="text-sm text-muted-foreground">0 / 0</p>
              </div>
              <div className="text-center">
                <h3 className="text-lg font-medium">Habits</h3>
                <p className="text-sm text-muted-foreground">Avg Streak: 0</p>
              </div>
            </div>
            <div className="h-[300px] w-full flex items-center justify-center text-muted-foreground">
              No productivity data available
            </div>
          </div>
        </CardContent>
      </Card>
    )
  }

  // Safely extract metrics with defaults
  const taskMetrics = data.task_metrics || {
    completion_rate: 0,
    timeliness_rate: 0,
    completed_tasks: 0,
    total_tasks: 0,
    on_time_tasks: 0
  }

  const projectMetrics = data.project_metrics || {
    completion_rate: 0,
    timeliness_rate: 0,
    completed_projects: 0,
    total_projects: 0,
    on_time_projects: 0
  }

  const habitMetrics = data.habit_metrics || {
    completion_rate: 0,
    average_streak: 0
  }

  const chartData = [
    {
      name: "Tasks",
      completion: taskMetrics.completion_rate || 0,
      timeliness: taskMetrics.timeliness_rate || 0,
    },
    {
      name: "Projects",
      completion: projectMetrics.completion_rate || 0,
      timeliness: projectMetrics.timeliness_rate || 0,
    },
    {
      name: "Habits",
      completion: habitMetrics.completion_rate || 0,
      streak: habitMetrics.average_streak || 0,
    },
  ]

  return (
    <Card>
      <CardHeader>
        <CardTitle>Productivity Metrics</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid gap-4">
          <div className="flex items-center justify-center text-2xl font-bold">
            Productivity Score: {data.productivity_score?.toFixed(1) || 0}%
          </div>

          {/* Metrics Summary */}
          <div className="grid grid-cols-3 gap-4 mb-6">
            <div className="text-center">
              <h3 className="text-lg font-medium">Tasks</h3>
              <p className="text-sm text-muted-foreground">
                {taskMetrics.completed_tasks} / {taskMetrics.total_tasks}
              </p>
              <p className="text-xs text-muted-foreground">
                On Time: {taskMetrics.on_time_tasks}
              </p>
            </div>
            <div className="text-center">
              <h3 className="text-lg font-medium">Projects</h3>
              <p className="text-sm text-muted-foreground">
                {projectMetrics.completed_projects} / {projectMetrics.total_projects}
              </p>
              <p className="text-xs text-muted-foreground">
                On Time: {projectMetrics.on_time_projects}
              </p>
            </div>
            <div className="text-center">
              <h3 className="text-lg font-medium">Habits</h3>
              <p className="text-sm text-muted-foreground">
                Avg Streak: {habitMetrics.average_streak?.toFixed(1) || 0}
              </p>
              <p className="text-xs text-muted-foreground">
                Completion: {habitMetrics.completion_rate?.toFixed(1) || 0}%
              </p>
            </div>
          </div>

          <div className="h-[300px] w-full">
            <ResponsiveContainer>
              <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" className="stroke-border" vertical={false} />
                <XAxis
                  dataKey="name"
                  tick={{ fill: "currentColor" }}
                  stroke="currentColor"
                />
                <YAxis
                  domain={[0, 100]}
                  unit="%"
                  tick={{ fill: "currentColor" }}
                  stroke="currentColor"
                />
                <Tooltip
                  content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div className="rounded-lg border bg-card p-3 shadow-sm">
                          <p className="font-medium">{label}</p>
                          {payload.map((entry, index) => (
                            <p key={index} className="text-sm text-muted-foreground">
                              {entry.name}: {entry.value?.toFixed(1) || 0}%
                            </p>
                          ))}
                        </div>
                      )
                    }
                    return null
                  }}
                />
                <Legend />
                <Bar
                  dataKey="completion"
                  name="Completion Rate"
                  fill={COLORS.completion}
                  radius={[4, 4, 0, 0]}
                />
                <Bar
                  dataKey="timeliness"
                  name="Timeliness Rate"
                  fill={COLORS.timeliness}
                  radius={[4, 4, 0, 0]}
                />
                <Bar
                  dataKey="streak"
                  name="Streak Rate"
                  fill={COLORS.streak}
                  radius={[4, 4, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>

          {/* Additional Summary */}
          <div className="mt-4 grid grid-cols-2 gap-4 text-sm text-muted-foreground">
            <div>
              <p>Task Completion: {taskMetrics.completion_rate?.toFixed(1) || 0}%</p>
              <p>Task Timeliness: {taskMetrics.timeliness_rate?.toFixed(1) || 0}%</p>
            </div>
            <div>
              <p>Project Completion: {projectMetrics.completion_rate?.toFixed(1) || 0}%</p>
              <p>Project Timeliness: {projectMetrics.timeliness_rate?.toFixed(1) || 0}%</p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default ProductivityScoreChart