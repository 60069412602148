import React, { createContext, useContext, useEffect, useState } from "react"

const ThemeProviderContext = createContext({
  theme: "dark",
  setTheme: () => null,
  themeChangeListeners: new Set(),
})

export function ThemeProvider({
  children,
  defaultTheme = "dark",
  storageKey = "ui-theme",
}) {
  const [theme, setTheme] = useState(() => {
    if (typeof window !== "undefined") {
      const stored = localStorage.getItem(storageKey)
      if (stored) return stored
    }
    return defaultTheme
  })

  // Create a single instance of the listeners Set
  const [themeChangeListeners] = useState(new Set())

  useEffect(() => {
    const root = window.document.documentElement

    // Only add the "light" class when in light mode
    // Dark mode uses root CSS variables by default
    if (theme === "light") {
      root.classList.add("light")
    } else {
      root.classList.remove("light")
    }

    localStorage.setItem(storageKey, theme)

    // Notify listeners of theme change
    themeChangeListeners.forEach(listener => listener(theme))
  }, [theme, storageKey, themeChangeListeners])

  const value = {
    theme,
    setTheme: (newTheme) => {
      setTheme(newTheme)
    },
    toggleTheme: () => {
      setTheme(theme === "light" ? "dark" : "light")
    },
    themeChangeListeners, // Add listeners to context
  }

  return (
    <ThemeProviderContext.Provider value={value}>
      {children}
    </ThemeProviderContext.Provider>
  )
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext)

  if (context === undefined)
    throw new Error("useTheme must be used within a ThemeProvider")

  return context
}