import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Layout from '../components/Layout'
import { Button } from "../components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card"
import { Alert, AlertDescription } from "../components/ui/alert"
import { Loader2 } from "lucide-react"
import axiosInstance from '../api/axiosInstance'

const SubscribeCancelPage = () => {
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [cancellationDetails, setCancellationDetails] = useState(null)
  const [error, setError] = useState('')

  const getSessionIdFromUrl = () => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('session_id')
  }

  useEffect(() => {
    const sessionId = getSessionIdFromUrl()
    if (sessionId) {
      fetchCancellationDetails(sessionId)
    } else {
      setError('No session ID found in the URL.')
      setLoading(false)
    }
  }, [location.search])

  const fetchCancellationDetails = async (sessionId) => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(`/api/subscription/cancel/${sessionId}`)
      setCancellationDetails(response.data)
    } catch (err) {
      setError('Failed to retrieve cancellation details.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Layout>
      <div className="container flex items-center justify-center min-h-screen py-8">
        <div className="w-full max-w-md">
          <Card>
            <CardHeader>
              <CardTitle>Subscription Cancellation</CardTitle>
            </CardHeader>
            <CardContent>
              {loading && (
                <div className="flex justify-center">
                  <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
                </div>
              )}

              {error && (
                <Alert variant="destructive">
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}

              {!loading && !error && cancellationDetails && (
                <div className="space-y-4">
                  <p className="text-sm text-muted-foreground">
                    Your subscription has been canceled. Here are the details:
                  </p>
                  <div className="space-y-2">
                    <p className="text-sm">
                      <span className="font-medium">Subscription ID:</span>{" "}
                      {cancellationDetails.id}
                    </p>
                    <p className="text-sm">
                      <span className="font-medium">Status:</span>{" "}
                      {cancellationDetails.status}
                    </p>
                  </div>
                  <div className="mt-4">
                    <Button asChild className="w-full">
                      <a href="/pricing">View Pricing Plans</a>
                    </Button>
                  </div>
                </div>
              )}

              {!loading && !error && !cancellationDetails && (
                <p className="text-sm text-muted-foreground">
                  It seems the subscription was canceled, but no further details are available.
                </p>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </Layout>
  )
}

export default SubscribeCancelPage