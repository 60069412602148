export const MAX_SESSION_AGE = 24 * 60 * 60 * 1000; // 24 hours

export const isSessionExpired = (startTime) => {
  if (!startTime) return true;
  const sessionAge = Date.now() - parseInt(startTime);
  return sessionAge > MAX_SESSION_AGE;
};

export const clearChatSession = () => {
  sessionStorage.removeItem('doris_thread_id');
  sessionStorage.removeItem('doris_messages');
  sessionStorage.removeItem('doris_started');
  sessionStorage.removeItem('doris_session_start');
};

export const initializeChatSession = () => {
  sessionStorage.setItem('doris_session_start', Date.now().toString());
};