import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Loader2 } from "lucide-react";

const ProtectedRoute = ({ children }) => {
  const { auth } = useAuth();
  const location = useLocation();

  console.log('ProtectedRoute checks:', {
    isLoggedIn: auth.isLoggedIn,
    isEmailVerified: auth.isEmailVerified,
    isCheckingSession: auth.isCheckingSession,
    path: location.pathname,
  });

  // Show loading state while checking session
  if (auth.isCheckingSession) {
    return (
      <div className="flex h-screen items-center justify-center bg-background">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  // After session check is complete, handle routing
  if (!auth.isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!auth.isEmailVerified && location.pathname !== '/verify') {
    return <Navigate to="/verify" state={{ from: location }} replace />;
  }

  if (!auth.isEmailVerified && location.pathname === '/verify') {
    return children;
  }

  if (auth.isEmailVerified && location.pathname === '/verify') {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

export default ProtectedRoute;