import React, { useState } from 'react'
import Sidebar from './Sidebar'
import { cn } from "../lib/utils"

function Layout({ children }) {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <div className="flex h-screen w-screen overflow-hidden">
      {/* Sidebar */}
      <div
        className={cn(
          "flex-shrink-0 transition-[width] duration-300 ease-in-out",
          isCollapsed ? "w-20" : "w-60"
        )}
      >
        <Sidebar isCollapsed={isCollapsed} toggleCollapse={toggleCollapse} />
      </div>

      {/* Main Content Area */}
      <div className="flex-1 overflow-hidden p-6 transition-[margin] duration-300 ease-in-out">
        {children}
      </div>
    </div>
  )
}

export default Layout