import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { Delete, Edit, Save, Plus, Wand2, Loader2 } from "lucide-react"
import axiosInstance from "../api/axiosInstance"
import { useAuth } from "../contexts/AuthContext"

// shadcn/ui components
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../components/ui/dialog"
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../components/ui/tooltip"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import { Check, ChevronDown, Clock, Tag } from "lucide-react"
import {useToast} from "../components/ui/toast";
import {Separator} from "../components/ui/separator";

// Constants
const COLORS = [
  "#B5A1FF", // Light violet
  "#FFE599", // Soft yellow
  "#FF9999", // Light red
  "#6CCFF6", // Custom cyan blue
  "#F3A160", // Custom orange
  "#2E3060", // Custom dark blue
  "#C6D8AF", // Custom light green
  "#9999C3", // Custom soft purple
  "#FFCC99", // Light peach
  "#A3D9FF", // Soft sky blue
]

const AVAILABLE_TAGS = [
  "Email",
  "Admin",
  "Planning",
  "Research",
  "Learning",
  "Implementation",
  "Other"
]

const MAX_FREE_TASKS = 3

function formatDateTimeLocal(dateString) {
  if (!dateString) return ""
  const date = new Date(dateString)
  const offset = date.getTimezoneOffset()
  const localDate = new Date(date.getTime() - offset * 60 * 1000)
  return localDate.toISOString().slice(0, 16)
}

export default function ProjectForm({ onClose, refreshProjects, project }) {
  const { auth } = useAuth()

  const [projectName, setProjectName] = useState("")
  const [deadline, setDeadline] = useState("")
  const [startTime, setStartTime] = useState("")
  const [taskName, setTaskName] = useState("")
  const [taskHours, setTaskHours] = useState("")
  const [taskMinutes, setTaskMinutes] = useState("")
  const [tasks, setTasks] = useState([])
  const [isEditing, setIsEditing] = useState(null)
  const [editHours, setEditHours] = useState("")
  const [editMinutes, setEditMinutes] = useState("")
  const [selectedColor, setSelectedColor] = useState(COLORS[0])
  const [taskTag, setTaskTag] = useState("")
  const [editTag, setEditTag] = useState("")
  const { toast } = useToast()
  const [showTaskSection, setShowTaskSection] = useState(false)

  const [errorMessage, setErrorMessage] = useState("")
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

  const [isGenerating, setIsGenerating] = useState(false)

  useEffect(() => {
    if (project) {
      setProjectName(project.name || "")
      setStartTime(formatDateTimeLocal(project.start_time))
      setDeadline(formatDateTimeLocal(project.deadline))
      setSelectedColor(project.color || COLORS[0])
      setShowTaskSection(true) // Add this line

      if (project.tasks) {
        setTasks(
          project.tasks.map((t) => ({
            name: t.name,
            hours: t.estimated_hours || t.hours || 0, // Handle both property names
            minutes: t.estimated_minutes || t.minutes || 0, // Handle both property names
            completed: t.completed || false,
            tag: t.tag || "Other",
          }))
        )
      }
    }
  }, [project])

  const calculateCompletionPercentage = () => {
    const totalMinutes = tasks.reduce(
      (total, t) => total + (parseInt(t.hours, 10) * 60 + parseInt(t.minutes, 10)),
      0
    )
    const completedMinutes = tasks.reduce((acc, t) => {
      if (t.completed) {
        return acc + (parseInt(t.hours, 10) * 60 + parseInt(t.minutes, 10))
      }
      return acc
    }, 0)

    return totalMinutes > 0 ? (completedMinutes / totalMinutes) * 100 : 0
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setErrorMessage("")

    const now = new Date()
    const start = new Date(startTime)
    const end = new Date(deadline)

    if (!project && start <= now) {
      setErrorMessage("Start time must be in the future for new projects.")
      setIsErrorModalOpen(true)
      return
    }

    if (end <= now) {
      setErrorMessage("Deadline must be in the future.")
      setIsErrorModalOpen(true)
      return
    }

    if (end <= start) {
      setErrorMessage("Deadline must be after the start time.")
      setIsErrorModalOpen(true)
      return
    }

    const projectData = {
      name: projectName,
      start_time: startTime,
      deadline,
      max_sitting_length: 180,
      color: selectedColor,
      tasks: tasks.map((t, idx) => ({
        name: t.name,
        estimated_hours: t.hours,
        estimated_minutes: t.minutes,
        completed: t.completed,
        tag: t.tag,
        order: idx + 1,
      })),
    }

    try {
      if (project) {
        await axiosInstance.put(`/api/projects/${project.id}`, projectData)
      } else {
        await axiosInstance.post("/api/project-create", projectData)
      }
      if (refreshProjects) refreshProjects()
      if (onClose) onClose()
    } catch (error) {
      setErrorMessage("Error saving the project. Please try again.")
      setIsErrorModalOpen(true)
    }
  }

  const handleAddTask = () => {
    if (!auth.isSubscribed && tasks.length >= MAX_FREE_TASKS) {
      setErrorMessage(
        `Free users can add up to ${MAX_FREE_TASKS} tasks. Please upgrade to add more.`
      )
      setIsErrorModalOpen(true)
      return
    }

    if (!taskTag) {
      setErrorMessage("Please select a tag for the task.")
      setIsErrorModalOpen(true)
      return
    }

    if (taskName.trim() !== "" && (taskHours !== "" || taskMinutes !== "")) {
      const newTask = {
        name: taskName,
        hours: taskHours || 0,
        minutes: taskMinutes || 0,
        completed: false,
        tag: taskTag,
      }
      setTasks((prev) => [...prev, newTask])
      setTaskName("")
      setTaskHours("")
      setTaskMinutes("")
      setTaskTag("")
    } else {
      setErrorMessage("Please provide a task name and duration.")
      setIsErrorModalOpen(true)
    }
  }

  const handleDeleteTask = (index) => {
    setTasks((prev) => {
      const newTasks = [...prev]
      newTasks.splice(index, 1)
      return newTasks
    })
  }

  const handleEditTask = (index) => {
    const taskToEdit = tasks[index];
    setIsEditing(index)
    setEditHours(taskToEdit.hours)
    setEditMinutes(taskToEdit.minutes)
    setEditTag(taskToEdit.tag || "") // Use editTag instead of taskTag
  }

  const handleSaveTask = (index) => {
    setTasks((prev) => {
      const updated = [...prev]
      updated[index] = {
        ...updated[index],
        hours: editHours || 0,
        minutes: editMinutes || 0,
        tag: editTag, // Use editTag instead of taskTag
      }
      return updated
    })
    setIsEditing(null)
    setEditTag("") // Reset editTag after saving
  }

  const handleTaskCompletionToggle = (index) => {
    setTasks((prev) => {
      const updated = [...prev]
      updated[index].completed = !updated[index].completed
      return updated
    })
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return
    const reordered = Array.from(tasks)
    const [moved] = reordered.splice(result.source.index, 1)
    reordered.splice(result.destination.index, 0, moved)
    setTasks(reordered)
  }

  const handleCloseErrorModal = () => setIsErrorModalOpen(false)

  const handleColorChange = (color) => setSelectedColor(color)

  const handleGenerate = async () => {
    if (!projectName.trim()) return

    setIsGenerating(true)
    try {
      const { data } = await axiosInstance.post('/api/preview-project', {
        project_name: projectName
      })

      if (data.success && data.suggestions) {
        // Store suggestions temporarily
        const suggestions = data.suggestions

        // Sequence the updates with small delays
        // Project name
        setTimeout(() => {
          setProjectName(suggestions.name || "")
        }, 100)

        // Start time
        setTimeout(() => {
          setStartTime(formatDateTimeLocal(suggestions.start_time))
        }, 300)

        // Deadline
        setTimeout(() => {
          setDeadline(formatDateTimeLocal(suggestions.deadline))
        }, 500)

        // Color
        setTimeout(() => {
          setSelectedColor(suggestions.color || COLORS[0])
        }, 700)

        // Tasks - load them one by one
        if (suggestions.tasks) {
          const newTasks = suggestions.tasks.map(task => ({
            name: task.name,
            hours: task.estimated_hours || 0,
            minutes: task.estimated_minutes || 0,
            completed: false,
            tag: task.tag || "Other"
          }))

          // Clear existing tasks first
          setTimeout(() => {
            setTasks([])
            setShowTaskSection(true)
          }, 900)

          // Add tasks one by one
          newTasks.forEach((task, index) => {
            setTimeout(() => {
              setTasks(prev => [...prev, task])
            }, 1100 + (index * 200))
          })
        }

        if (data.suggestions.truncated) {
          setTimeout(() => {
            toast({
              title: "Note",
              description: "As a free user, you are limited to 3 tasks per project. Upgrade to add more tasks.",
              variant: "warning",
            })
          }, 1500)
        }
      }
    } catch (error) {
      const errorMsg = error.response?.data?.error || "Failed to generate project details"
      setErrorMessage(errorMsg)
      setIsErrorModalOpen(true)
    } finally {
      // Set generating to false after all animations complete
      setTimeout(() => {
        setIsGenerating(false)
      }, 2000)
    }
  }


  return (
    <div className="max-h-[80vh] overflow-y-auto p-4">
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Project Name */}
        <div className="flex flex-col">
          <label htmlFor="projectName" className="mb-1 text-sm font-medium">
            Project Name
          </label>
          <div className="flex gap-2">
            <Input
                id="projectName"
                placeholder="Enter project name..."
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                className="flex-1"
            />
            <Button
                type="button"
                variant="outline"
                onClick={handleGenerate}
                disabled={isGenerating || !projectName.trim()}
                className="gap-2 whitespace-nowrap"
            >
              {isGenerating ? (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin"/>
                    <span>Generating...</span>
                  </>
              ) : (
                  <>
                    <Wand2 className="h-4 w-4"/>
                    <span>Generate</span>
                  </>
              )}
            </Button>
          </div>
        </div>

        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center">
            <Separator className="w-full"/>
          </div>
          <div className="relative flex justify-center text-xs">
            <span className="bg-background px-2 text-muted-foreground">or create manually</span>
          </div>
        </div>

        <div className="flex gap-4">
          {/* Start Time */}
          <div className="flex flex-col flex-1">
            <label htmlFor="startTime" className="mb-1 text-sm font-medium">
              Start Date
            </label>
            <Input
                id="startTime"
                type="datetime-local"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className="white-calendar"
            />
          </div>

          {/* Deadline */}
          <div className="flex flex-col flex-1">
            <label htmlFor="deadline" className="mb-1 text-sm font-medium">
              Deadline
            </label>
            <Input
                id="deadline"
                type="datetime-local"
                value={deadline}
                onChange={(e) => setDeadline(e.target.value)}
                className="white-calendar"
            />
          </div>
        </div>

        {/* Color Palette */}
        <div className="flex flex-col items-center">
          <p className="mb-1 text-sm font-medium">Project Color</p>
          <div className="flex flex-wrap justify-center gap-1 max-w-[300px]">
            {COLORS.map((color) => (
                <button
                    key={color}
                    type="button"
                    onClick={() => handleColorChange(color)}
                    className="h-6 w-6 rounded-full border-2"
                    style={{
                      backgroundColor: color,
                      borderColor: selectedColor === color ? "#000" : "transparent",
                    }}
                />
            ))}
          </div>
        </div>

        {/* Completion Progress - only show for existing projects */}
        {project && (
            <div className="flex flex-col items-center">
            <span className="mb-1 text-sm font-medium text-center">{`Project ${Math.round(
                calculateCompletionPercentage()
            )}% Completed`}</span>
              <div className="relative h-2 w-full rounded-full bg-accent/20">
                <div
                    className="absolute left-0 top-0 h-full bg-primary transition-all"
                    style={{width: `${calculateCompletionPercentage()}%`}}
                />
              </div>
            </div>
        )}

        {/* Task Section Toggle */}
        <Button
            type="button"
            variant="outline"
            className="w-full"
            onClick={() => setShowTaskSection(!showTaskSection)}
        >
          <Plus className={`mr-2 h-4 w-4 transition-transform ${showTaskSection ? 'rotate-45' : ''}`}/>
          {showTaskSection ? "Hide Tasks" : "Add Tasks"}
        </Button>

        {showTaskSection && (
            <div className="space-y-4">
              {/* Task Limit + Upgrade Link */}
              {!auth.isSubscribed && (
                  <div className="flex items-center justify-end gap-2 text-sm">
                <span>
                  {tasks.length}/{MAX_FREE_TASKS} tasks
                </span>
                    <Link to="/subscribe" className="text-primary underline">
                      Upgrade for more
                    </Link>
                  </div>
              )}

              {/* Task Fields */}
              <div className="flex flex-wrap items-end gap-2">
                <div className="flex w-full flex-col md:w-[30%]">
                  <label htmlFor="taskName" className="mb-1 text-sm font-medium">
                    Task Name
                  </label>
                  <Input
                      id="taskName"
                      placeholder="E.g. Outline chapter"
                      value={taskName}
                      onChange={(e) => setTaskName(e.target.value)}
                  />
                </div>
                <div className="flex flex-col w-[45%] max-w-[110px]">
                  <label htmlFor="taskHours" className="mb-1 text-sm font-medium">
                    Hours
                  </label>
                  <Input
                      id="taskHours"
                      type="number"
                      placeholder="1"
                      value={taskHours}
                      onChange={(e) => setTaskHours(e.target.value)}
                  />
                </div>
                <div className="flex flex-col w-[45%] max-w-[110px]">
                  <label htmlFor="taskMinutes" className="mb-1 text-sm font-medium">
                    Minutes
                  </label>
                  <Input
                      id="taskMinutes"
                      type="number"
                      placeholder="30"
                      value={taskMinutes}
                      onChange={(e) => setTaskMinutes(e.target.value)}
                  />
                </div>
                <div className="flex w-full flex-col md:w-1/6">
                  <label className="mb-1 text-sm font-medium">
                    Tag
                  </label>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                          variant="outline"
                          role="combobox"
                          className="w-28 justify-between font-normal"
                      >
                        {taskTag || "Select tag"}
                        <ChevronDown className="ml-0 h-4 w-5 shrink-0 opacity-50"/>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-[200px]">
                      {AVAILABLE_TAGS.map((tag) => (
                          <DropdownMenuItem
                              key={tag}
                              onSelect={() => setTaskTag(tag)}
                              className="flex items-center justify-between"
                          >
                            {tag}
                            {taskTag === tag && (
                                <Check className="h-4 w-4 text-primary"/>
                            )}
                          </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
                <Button
                    type="button"
                    variant="outline"
                    className="flex-grow"
                    onClick={handleAddTask}
                    disabled={!auth.isSubscribed && tasks.length >= MAX_FREE_TASKS}
                >
                  <Plus className="mr-2 h-4 w-4"/>
                  {(!auth.isSubscribed && tasks.length >= MAX_FREE_TASKS)
                      ? "Upgrade to add more"
                      : "Add Task"}
                </Button>
              </div>

              {/* Task List (Draggable) */}
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="tasks">
                  {(provided) => (
                      <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="mt-4 space-y-2"
                      >
                        {tasks.map((t, index) => (
                            <Draggable
                                key={index}
                                draggableId={`task-${index}`}
                                index={index}
                            >
                              {(provided) => (
                                  <div
                                      className={`group flex items-center justify-between rounded-md border p-3 transition-all hover:border-primary hover:shadow-sm ${
                                          t.completed ? 'bg-muted/50' : 'bg-background'
                                      }`}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                  >
                                    {/* Completion Toggle */}
                                    <div className="flex items-center gap-3">
                                      <input
                                          type="checkbox"
                                          className="h-4 w-4 rounded-sm border-primary text-primary transition-colors"
                                          checked={t.completed}
                                          onChange={() => handleTaskCompletionToggle(index)}
                                      />

                                      {/* Task Details */}
                                      {isEditing === index ? (
                                          <div className="grid grid-cols-1 sm:grid-cols-12 gap-3 w-full items-center">
                                            {/* Hours and Minutes in a single row */}
                                            <div className="sm:col-span-5 grid grid-cols-2 gap-3">
                                              <div className="relative">
                                                <Input
                                                    type="number"
                                                    value={editHours}
                                                    onChange={(e) => setEditHours(e.target.value)}
                                                    className="pl-14 transition-all focus:ring-2 focus:ring-primary/20"
                                                    placeholder="0"
                                                />
                                                <span
                                                    className="absolute left-3 top-1/2 -translate-y-1/2 text-sm text-muted-foreground">
                                        Hours
                                      </span>
                                              </div>
                                              <div className="relative">
                                                <Input
                                                    type="number"
                                                    value={editMinutes}
                                                    onChange={(e) => setEditMinutes(e.target.value)}
                                                    className="pl-16 transition-all focus:ring-2 focus:ring-primary/20"
                                                    placeholder="0"
                                                />
                                                <span
                                                    className="absolute left-3 top-1/2 -translate-y-1/2 text-sm text-muted-foreground">
                                        Mins
                                      </span>
                                              </div>
                                            </div>

                                            {/* Tag Dropdown */}
                                            <div className="sm:col-span-4">
                                              <DropdownMenu>
                                                <DropdownMenuTrigger asChild>
                                                  <Button
                                                      variant="outline"
                                                      role="combobox"
                                                      className="w-full justify-between font-normal hover:border-primary"
                                                  >
                                                    {taskTag || "Select tag"}
                                                    <ChevronDown className="ml-1 h-4 w-4 shrink-0 opacity-50"/>
                                                  </Button>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent align="end" className="w-[200px]">
                                                  {AVAILABLE_TAGS.map((tag) => (
                                                      <DropdownMenuItem
                                                          key={tag}
                                                          onSelect={() => setTaskTag(tag)}
                                                          className="flex items-center justify-between"
                                                      >
                                                        {tag}
                                                        {taskTag === tag && (
                                                            <Check className="h-4 w-4 text-primary"/>
                                                        )}
                                                      </DropdownMenuItem>
                                                  ))}
                                                </DropdownMenuContent>
                                              </DropdownMenu>
                                            </div>

                                            {/* Save Button */}
                                            <div className="sm:col-span-3">
                                              <Button
                                                  variant="default"
                                                  onClick={() => handleSaveTask(index)}
                                                  className="w-full"
                                              >
                                                <Save className="mr-2 h-4 w-4"/>
                                                Save
                                              </Button>
                                            </div>
                                          </div>
                                      ) : (
                                          <div
                                              className={`flex-grow text-sm space-y-0.5 ${
                                                  t.completed ? "line-through opacity-60" : ""
                                              }`}
                                          >
                                            <p className="font-medium text-base">{t.name}</p>
                                            <div className="flex items-center gap-2 text-muted-foreground">
                                    <span className="flex items-center">
                                      <Clock className="mr-1 h-3 w-3"/>
                                      {`${t.hours}h ${t.minutes}m`}
                                    </span>
                                              <span className="flex items-center">
                                      <Tag className="mr-1 h-3 w-3"/>
                                                {t.tag}
                                    </span>
                                            </div>
                                          </div>
                                      )}
                                    </div>

                                    {/* Edit/Delete Buttons */}
                                    <div className="flex gap-1 opacity-0 transition-opacity group-hover:opacity-100">
                                      <Button
                                          variant="ghost"
                                          size="sm"
                                          type="button"
                                          onClick={(e) => {
                                            e.preventDefault()
                                            handleEditTask(index)
                                          }}
                                          className="hover:bg-primary/10 hover:text-primary"
                                      >
                                        <Edit className="h-4 w-4"/>
                                      </Button>
                                      <Button
                                          variant="ghost"
                                          size="sm"
                                          onClick={() => handleDeleteTask(index)}
                                          className="hover:bg-destructive/10 hover:text-destructive"
                                      >
                                        <Delete className="h-4 w-4"/>
                                      </Button>
                                    </div>
                                  </div>
                              )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
        )}

        {/* Submit (Create/Update) Button */}
        <Button
            type="submit"
            variant="default"
            className="w-full mt-4"
            disabled={isEditing !== null}
        >
          {project ? "Update Project" : "Add Project"}
        </Button>
      </form>

      {/* Error Dialog */}
      <Dialog open={isErrorModalOpen} onOpenChange={handleCloseErrorModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Error</DialogTitle>
            <DialogDescription>{errorMessage}</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="default" onClick={handleCloseErrorModal}>
            Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}
