import * as React from "react"
import { cn } from "../../lib/utils"

const Link = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <a
      ref={ref}
      className={cn(
        "text-primary underline-offset-4 hover:underline",
        className
      )}
      {...props}
    />
  )
})
Link.displayName = "Link"

export { Link }