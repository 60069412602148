import React, { useState } from 'react';
import { Button } from "../../components/ui/button";
import { MessageCircle, MessageSquare, UserPlus } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../components/ui/dialog";
import { Textarea } from "../../components/ui/textarea";
import axiosInstance from '../../api/axiosInstance';

const DashboardHeader = ({ onOpenInvite, redeemedPromo }) => {
  const [feedbackText, setFeedbackText] = useState('');
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [statusDialog, setStatusDialog] = useState({ open: false, message: '' });

  const handleFeedbackChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const handleSubmitFeedback = async () => {
    try {
      await axiosInstance.post('/api/feedback', { feedbackText });
      setStatusDialog({
        open: true,
        message: 'Your feedback has been successfully submitted. We appreciate your input!'
      });
      setFeedbackText('');
      setOpenFeedbackDialog(false);
    } catch (error) {
      setStatusDialog({
        open: true,
        message: error.response?.status === 429
          ? 'You can only submit feedback once per minute. Please try again later.'
          : 'Failed to submit feedback. Please try again later.'
      });
    }
  };

  return (
    <div className="mb-4 flex items-center justify-between">
      <h1 className="text-3xl font-bold tracking-tight">Welcome 👋</h1>

      <div className="flex gap-2">
        <div className="relative group">
          <div className="absolute -inset-1 rounded-lg bg-gradient-to-r from-orange-400 to-amber-500 opacity-75 blur-sm group-hover:opacity-100 transition-opacity"></div>
          <Button
            variant="outline"
            className="flex items-center gap-2 relative hover:bg-gray"
            onClick={() => setOpenFeedbackDialog(true)}
          >
            <MessageSquare className="h-4 w-4"/>
            Feedback
            <span className="ml-1 text-xs font-medium text-orange-500">Get free Plus!</span>
          </Button>
        </div>
      </div>

      {/* Feedback Form Dialog */}
      <Dialog open={openFeedbackDialog} onOpenChange={setOpenFeedbackDialog}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Submit Feedback</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-4">
            <p className="text-sm text-muted-foreground">
              Submit feedback to become a Founding User and get free access to Plus forever. You'll receive your code within 24hrs.
            </p>
            <Textarea
              placeholder="Got suggestions? Enter them here."
              value={feedbackText}
              onChange={handleFeedbackChange}
              className="min-h-[200px] resize-none"
            />
          </div>
          <DialogFooter>
            <Button
              variant="secondary"
              onClick={() => setOpenFeedbackDialog(false)}
            >
              Cancel
            </Button>
            <Button
              variant="default"
              onClick={handleSubmitFeedback}
              disabled={!feedbackText.trim()}
            >
              Submit
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Status Dialog */}
      <Dialog open={statusDialog.open} onOpenChange={(open) => setStatusDialog({ ...statusDialog, open })}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Feedback Status</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p className="text-sm text-muted-foreground">{statusDialog.message}</p>
          </div>
          <DialogFooter>
            <Button
              variant="secondary"
              onClick={() => setStatusDialog({ ...statusDialog, open: false })}
            >
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DashboardHeader;