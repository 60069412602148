import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu"
import { Button } from "../ui/button"

function CalendarSettings({ minTime, maxTime, onMinTimeChange, onMaxTimeChange, onClose }) {
  return (
    <div className="space-y-4 p-4">
      <div className="space-y-2">
        <label className="text-sm font-medium">Earliest</label>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="w-full justify-between">
              {minTime}:00
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-full max-h-[200px] overflow-y-auto">
            {Array.from({ length: 24 }, (_, i) => (
              <DropdownMenuItem
                key={i}
                onClick={() => onMinTimeChange(i)}
              >
                {i}:00
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className="space-y-2">
        <label className="text-sm font-medium">Latest</label>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="w-full justify-between">
              {maxTime}:00
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-full max-h-[200px] overflow-y-auto">
            {Array.from({ length: 24 }, (_, i) => (
              <DropdownMenuItem
                key={i}
                onClick={() => onMaxTimeChange(i)}
              >
                {i}:00
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <Button className="w-full" onClick={onClose}>
        Save
      </Button>
    </div>
  )
}

export default CalendarSettings