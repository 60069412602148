import { useState, useEffect } from "react"
import { useAuth } from "../../contexts/AuthContext"
import { Card, CardContent } from "../../components/ui/card"
import { Alert, AlertDescription } from "../../components/ui/alert"
import axiosInstance from "../../api/axiosInstance"

const StatCard = ({ title, value, colorClass }) => {
  return (
    <Card className="transition-all duration-200 hover:-translate-y-0.5 hover:shadow-lg dark:hover:shadow-black/30">
      <CardContent className="p-6 text-center">
        <p className={`mb-2 text-2xl font-semibold ${colorClass}`}>
          {value}
        </p>
        <p className="text-sm text-muted-foreground">
          {title}
        </p>
      </CardContent>
    </Card>
  )
}

function StatSummary() {
  const { auth } = useAuth()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      if (!auth.isSubscribed) {
        // Set dummy data for non-subscribed users
        setData({
          total_project_completions: 12,
          total_task_completions: 45,
          total_habit_completions: 120,
          best_habit_streak: 14
        });
        setLoading(false);
        return;
      }

      try {
        const { data: response } = await axiosInstance.get("/api/analytics/summary")
        setData(response)
        setError(null)
      } catch (error) {
        console.error("Error fetching summary stats:", error)
        setError("Failed to load summary data.")
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [auth.isSubscribed])

  if (loading) {
    return (
      <div className="flex h-[200px] items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex h-[200px] items-center justify-center p-6">
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </div>
    )
  }

  const stats = [
    {
      title: "Project Completions",
      value: data?.total_project_completions || 0,
      colorClass: "text-primary"
    },
    {
      title: "Task Completions",
      value: data?.total_task_completions || 0,
      colorClass: "text-muted-foreground"
    },
  ]

  return (
    <div className="mb-4">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        {stats.map((stat) => (
          <StatCard
            key={stat.title}
            title={stat.title}
            value={stat.value}
            colorClass={stat.colorClass}
          />
        ))}
      </div>
    </div>
  )
}

export default StatSummary