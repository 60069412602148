import React, { useEffect, useState } from 'react'
import axiosInstance from '../api/axiosInstance'
import { ScrollArea } from "../components/ui/scroll-area"
import { Link } from "../components/ui/link"

function Notifications() {
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    // Fetch notifications from the API
    const fetchNotifications = async () => {
      try {
        const response = await axiosInstance.get('/api/projects_with_excess_time')
        const projects = response.data.projects

        const projectNotifications = projects
          .filter((project) => project.total_excess_time > 0)
          .map((project) => ({
            message: `Project "${project.name}" has ${project.total_excess_time} minutes of excess time.`,
            projectId: project.id,
          }))

        setNotifications(projectNotifications)
      } catch (error) {
        console.error('Error fetching project notifications:', error)
      }
    }

    fetchNotifications()
  }, [])

  return (
    <ScrollArea className="h-[300px] w-full rounded-md">
      {notifications.length > 0 ? (
        <div className="space-y-1 p-1">
          {notifications.map((notification, index) => (
            <div
              key={index}
              className="rounded-lg px-3 py-2 text-sm transition-colors hover:bg-accent"
            >
              {notification.message}
            </div>
          ))}
        </div>
      ) : (
        <p className="px-3 py-2 text-sm text-muted-foreground">
          Welcome to MeetDoris! View the getting started guide{' '}
          <Link
            href="https://www.meetdoris.com/help/article/getting-started"
            target="_blank"
            rel="noopener noreferrer"
            className="font-medium underline underline-offset-4 hover:text-primary"
          >
            here
          </Link>
          !
        </p>
      )}
    </ScrollArea>
  )
}

export default Notifications