import React, { useState } from 'react'
import axiosInstance from '../api/axiosInstance'
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Mail } from "lucide-react"
import { useToast } from "../components/ui/toast"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from "../components/ui/dialog"

function InviteForm({ onClose }) {
  const [emails, setEmails] = useState([])
  const [currentEmail, setCurrentEmail] = useState('')
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { toast } = useToast()

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  }

  const handleEmailInput = (event) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault()
      const email = currentEmail.trim()

      if (!validateEmail(email)) {
        setErrorMessage(`${email} is not a valid email address`)
        setIsErrorModalOpen(true)
        return
      }

      if (emails.includes(email)) {
        setErrorMessage(`${email} has already been added`)
        setIsErrorModalOpen(true)
        return
      }

      setEmails([...emails, email])
      setCurrentEmail('')
    }
  }

  const removeEmail = (emailToRemove) => {
    setEmails(emails.filter(email => email !== emailToRemove))
  }

  const handleSubmitInvites = async () => {
    if (emails.length < 3) {
      setErrorMessage('Please add at least 3 email addresses')
      setIsErrorModalOpen(true)
      return
    }

    try {
      const response = await axiosInstance.post('/api/user/submit-invites', { emails })

      const message = response.data.already_redeemed
        ? response.data.message
        : `Invites sent successfully! We've emailed your promotional code (${response.data.submitter_code}) to you.`

      toast({
        title: "Success!",
        description: message,
        duration: 5000,
      })

      setEmails([])
      if (onClose) {
        onClose()
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || 'Failed to send invites. Please try again later.',
        variant: "destructive",
        duration: 5000,
      })
    }
  }

  return (
    <div className="max-h-[80vh] overflow-y-auto p-4">
      <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
        <div className="mb-4 text text-muted-foreground text-center">
          Invite 3 contacts for one month of free <a href="/subscribe" className="text-primary hover:underline">Plus</a>!
        </div>

        {/* Email Input */}
        <div className="flex flex-col">
          <div className="rounded-md border border-input bg-background p-2">
            {/* Email Chips */}
            {emails.length > 0 && (
              <div className="flex flex-wrap gap-2 mb-2">
                {emails.map((email, index) => (
                  <div
                    key={index}
                    className="bg-secondary text-secondary-foreground rounded-full px-3 py-1.5 flex items-center gap-2 text-sm"
                  >
                    <span className="max-w-[200px] truncate">{email}</span>
                    <button
                      type="button"
                      onClick={() => removeEmail(email)}
                      className="hover:text-destructive transition-colors"
                      aria-label={`Remove ${email}`}
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            )}

            {/* Input Field */}
            <div className="relative flex items-center">
              <Mail className="pointer-events-none absolute left-3 h-4 w-4 text-muted-foreground" />
              <Input
                type="email"
                value={currentEmail}
                onChange={(e) => setCurrentEmail(e.target.value)}
                onKeyDown={handleEmailInput}
                placeholder={emails.length === 0 ? "Enter email addresses (minimum 3)" : "Add another email"}
                className="border-none shadow-none pl-9 focus-visible:ring-0"
              />
            </div>
          </div>
        </div>

        {/* Subject */}
        <div className="flex flex-col">
          <label className="mb-1 text-sm font-medium">
            Subject:
          </label>
          <Input
            value="Someone you know thinks you should try MeetDoris!"
            disabled
            className="bg-muted"
          />
        </div>

        {/* Message Preview */}
        <div className="flex flex-col">
          <label className="mb-1 text-sm font-medium">
            Message Preview:
          </label>
          <div className="rounded-md border border-input bg-muted p-4 space-y-4">
            <p className="text-sm text-muted-foreground">
              Hey! {'{your email}'} thinks you should try MeetDoris!
            </p>
            <p className="text-sm text-muted-foreground">
              (Short description of MeetDoris)
            </p>
            <p className="text-sm text-muted-foreground">
              Here's your unique code giving you one month free of Plus membership:
            </p>
            <p className="text-sm font-medium">
              (your recipient's unique coupon code)
            </p>
          </div>
        </div>

        {/* Submit Button */}
        <Button
          type="button"
          variant="default"
          onClick={handleSubmitInvites}
          disabled={emails.length < 3}
          className="w-full"
        >
          Send Invites
        </Button>
        <div className="mb-4 text-xs text-muted-foreground text-center">
          They don't need to sign up for you to receive your coupon! Your recipient's coupon code will only work with
          their email address.
        </div>
      </form>

      {/* Error Dialog */}
      <Dialog open={isErrorModalOpen} onOpenChange={setIsErrorModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Error</DialogTitle>
            <DialogDescription>{errorMessage}</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="default" onClick={() => setIsErrorModalOpen(false)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default InviteForm