import { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Card, CardContent } from "../components/ui/card"
import { Separator } from "../components/ui/separator"
import { Alert, AlertDescription } from "../components/ui/alert"
import { useAuth } from "../contexts/AuthContext"

// Microsoft icon component
const MicrosoftIcon = () => (
  <svg width="20" height="20" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0h10v10H10V0z" fill="#f25022"/>
    <path d="M0 0h10v10H0V0z" fill="#00a4ef"/>
    <path d="M10 10h10v10H10V10z" fill="#7fba00"/>
    <path d="M0 10h10v10H0V10z" fill="#ffb900"/>
  </svg>
)

// Google icon component
const GoogleIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
    <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
    <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
    <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
  </svg>
)

function Register() {
  const { register, microsoftSignIn, googleSignIn } = useAuth()
  const [email, setEmail] = useState("")
  const [showPasswordFields, setShowPasswordFields] = useState(false)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [microsoftLoading, setMicrosoftLoading] = useState(false)
  const [googleLoading, setGoogleLoading] = useState(false)
  const [passwordErrors, setPasswordErrors] = useState({
    minLength: false,
    number: false,
    upperCase: false,
    lowerCase: false,
    specialChar: false,
  })

  const navigate = useNavigate()

  useEffect(() => {
    setPasswordErrors(checkPasswordStrength(password))
  }, [password])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPasswordFields(email.length > 0)
    }, 300)

    return () => clearTimeout(timer)
  }, [email])

  const checkPasswordStrength = (password) => ({
    minLength: password.length >= 8,
    number: /[0-9]/.test(password),
    upperCase: /[A-Z]/.test(password),
    lowerCase: /[a-z]/.test(password),
    specialChar: /[!@#$%^&*]/.test(password),
  })

  const handleGoogleSignUp = async () => {
    try {
      setGoogleLoading(true)
      setErrorMessage("")
      await googleSignIn()
    } catch (error) {
      setErrorMessage(error.message)
      console.error("Google auth error:", error)
    } finally {
      setGoogleLoading(false)
    }
  }

  const handleMicrosoftSignUp = async () => {
    try {
      setMicrosoftLoading(true)
      setErrorMessage("")
      await microsoftSignIn()
    } catch (error) {
      setErrorMessage(error.message)
      console.error("Microsoft auth error:", error)
    } finally {
      setMicrosoftLoading(false)
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setErrorMessage("")
    setLoading(true)

    const errors = checkPasswordStrength(password)
    if (!errors.minLength || !errors.number || !errors.upperCase || !errors.lowerCase || !errors.specialChar) {
      setErrorMessage("Your password does not meet all the requirements.")
      setPasswordErrors(errors)
      setLoading(false)
      return
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.")
      setLoading(false)
      return
    }

    try {
      await register(email, password)
      navigate("/verify")
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
      <div className="flex min-h-screen items-center justify-center bg-login px-4 py-5">
        <div className="w-full max-w-md">
          <Card>
            <CardContent className="pt-6">
              <h2 className="mb-4 text-2xl font-bold text-foreground">Register</h2>

              {errorMessage && (
                  <Alert variant="destructive" className="mb-4">
                    <AlertDescription>{errorMessage}</AlertDescription>
                  </Alert>
              )}

              {/* Google Sign Up Button */}
              <Button
                  variant="outline"
                  className="mb-3 h-12 w-full"
                  onClick={handleGoogleSignUp}
                  disabled={googleLoading}
              >
                {googleLoading ? (
                    <div className="h-5 w-5 animate-spin rounded-full border-2 border-gray-300 border-t-black"/>
                ) : (
                    <>
                      <GoogleIcon/>
                      <span className="ml-2">Sign up with Google</span>
                    </>
                )}
              </Button>

              {/* Microsoft Sign Up Button */}
              <Button
                  variant="outline"
                  className="h-12 w-full"
                  onClick={handleMicrosoftSignUp}
                  disabled={microsoftLoading}
              >
                {microsoftLoading ? (
                    <div className="h-5 w-5 animate-spin rounded-full border-2 border-gray-300 border-t-black"/>
                ) : (
                    <>
                      <MicrosoftIcon/>
                      <span className="ml-2">Sign up with Microsoft</span>
                    </>
                )}
              </Button>

              {/* SSO Note */}
              <p className="mt-2 pt-3 text-center text-xs text-muted-foreground">
                Your sign-in method doesn't need to match your calendar source
              </p>

              {/* Divider */}
              <div className="relative my-6">
                <div className="absolute inset-0 flex items-center">
                  <Separator className="w-full"/>
                </div>
                <div className="relative flex justify-center text-xs uppercase">
                  <span className="bg-background px-2 text-muted-foreground">or</span>
                </div>
              </div>

              <form onSubmit={handleSubmit} className="space-y-4">
                <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />

                <div
                    className={`space-y-4 transition-all duration-500 ${showPasswordFields ? 'opacity-100' : 'opacity-0 h-0 overflow-hidden'}`}>
                  <Input
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required={showPasswordFields}
                  />
                  <Input
                      type="password"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required={showPasswordFields}
                  />

                  <div className="text-sm">
                    <p className="font-medium">Password Requirements:</p>
                    <ul className="mt-2 space-y-1 pl-5">
                      {Object.entries({
                        'At least 8 characters long': passwordErrors.minLength,
                        'At least one number': passwordErrors.number,
                        'At least one uppercase letter': passwordErrors.upperCase,
                        'At least one lowercase letter': passwordErrors.lowerCase,
                        'At least one special character (!@#$%^&*)': passwordErrors.specialChar,
                      }).map(([text, isValid]) => (
                          <li key={text}
                              className={`${isValid ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
                            {text}
                          </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <Button
                    type="submit"
                    className="h-12 w-full"
                    disabled={loading}
                >
                  {loading ? (
                      <div className="h-5 w-5 animate-spin rounded-full border-2 border-gray-300 border-t-white"/>
                  ) : (
                      "Register"
                  )}
                </Button>

                <div className="text-center text-sm">
                  <p>
                    Already have an account?{" "}
                    <Link to="/login" className="text-primary hover:underline">
                      Log in
                    </Link>
                  </p>
                </div>

                <div className="text-center text-xs text-muted-foreground">
                  By creating an account you agree to our{" "}
                  <a
                      href="https://www.meetdoris.com/terms-of-service"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary hover:underline"
                  >
                    Terms
                  </a>{" "}
                  and{" "}
                  <a
                      href="https://www.meetdoris.com/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary hover:underline"
                  >
                    Privacy Policy
                  </a>.
                </div>

              </form>
            </CardContent>
          </Card>
        </div>
      </div>
  )
}

export default Register