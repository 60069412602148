import { useState } from "react"
import { MessageCircle, Bell, Calendar, MessageSquare } from "lucide-react"
import Notifications from "../Notifications"
import FeedbackForm from "../FeedbackForm"
import CalendarSettings from "./CalendarSettings"
import DorisChatWindow from "../DorisChatWindow"
import { cn } from "../../lib/utils"

import { Button } from "../../components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverPortal,
} from "../../components/ui/popover"

function FloatingSettingsMenu({ minTime, maxTime, onMinTimeChange, onMaxTimeChange }) {
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogContent, setDialogContent] = useState(null)
  const [dialogTitle, setDialogTitle] = useState("")
  const [hasOpenedMenu, setHasOpenedMenu] = useState(false)
  const [isDorisOpen, setIsDorisOpen] = useState(false)
  const [isDorisMinimized, setIsDorisMinimized] = useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const handleOpenDialog = (content, title) => {
    setDialogContent(content)
    setDialogTitle(title)
    setOpenDialog(true)
    setIsPopoverOpen(false)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handlePopoverOpenChange = (open) => {
    setIsPopoverOpen(open)
    if (open && !hasOpenedMenu) {
      setHasOpenedMenu(true)
    }
  }

  const MenuItem = ({ icon: Icon, children, onClick }) => (
    <button
      className="flex w-full items-center gap-2 rounded-sm px-2 py-1.5 text-sm outline-none hover:bg-accent focus:bg-accent"
      onClick={onClick}
    >
      {Icon && <Icon className="h-4 w-4" />}
      {children}
    </button>
  )

  return (
    <>
      <Popover open={isPopoverOpen} onOpenChange={handlePopoverOpenChange}>
        <PopoverTrigger asChild>
          <Button
            size="lg"
            className="fixed bottom-4 right-4 h-12 w-12 rounded-full p-0"
          >
            <div className="relative">
              {!hasOpenedMenu && (
                <span className="absolute -right-1 -top-1 h-3 w-3 rounded-full bg-destructive" />
              )}
              <img
                src={`${process.env.PUBLIC_URL}/favicon.ico`}
                alt="icon"
                className="h-8 w-8"
              />
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent
            className="w-48"
            align="end"
            alignOffset={-8}
            sideOffset={16}
          >
            <div className="flex flex-col space-y-1">
              <MenuItem
                icon={Bell}
                onClick={() => handleOpenDialog(<Notifications />, "Notifications")}
              >
                Notifications
              </MenuItem>
              <MenuItem
                icon={Calendar}
                onClick={() =>
                  handleOpenDialog(
                    <CalendarSettings
                      minTime={minTime}
                      maxTime={maxTime}
                      onMinTimeChange={onMinTimeChange}
                      onMaxTimeChange={onMaxTimeChange}
                      onClose={handleCloseDialog}
                    />,
                    "Calendar Settings"
                  )
                }
              >
                Calendar Settings
              </MenuItem>
              <MenuItem
                icon={MessageSquare}
                onClick={() => handleOpenDialog(<FeedbackForm />, "Submit Feedback")}
              >
                Submit Feedback
              </MenuItem>
            </div>
          </PopoverContent>
        </PopoverPortal>
      </Popover>

      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{dialogTitle}</DialogTitle>
          </DialogHeader>
          {dialogContent}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default FloatingSettingsMenu