import React, { useState } from 'react'
import axiosInstance from '../api/axiosInstance'
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select"
import { Checkbox } from "../components/ui/checkbox"
import { Label } from "../components/ui/label"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip"
import { Loader2 } from "lucide-react"
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
} from "../components/ui/dropdown-menu"

const referralSources = [
    'Google Search',
    'Social Media',
      'Online Ad',
    'Friend/Colleague',
    'Blog or Article',
    'Other'
  ]

const timezones = [
  'Pacific/Honolulu',
  'America/Anchorage',
  'America/Los_Angeles',
  'America/Denver',
  'America/Chicago',
  'America/New_York',
  'America/Sao_Paulo',
  'Europe/London',
  'Europe/Paris',
  'Europe/Berlin',
  'Europe/Madrid',
  'Europe/Istanbul',
  'Asia/Dubai',
  'Asia/Kolkata',
  'Asia/Shanghai',
  'Asia/Tokyo',
  'Australia/Sydney',
]

function Step1WorkSetup({
  completeOnboarding,
  setWorkStartTime,
  setWorkEndTime,
  setTimezone,
  setWorkdays,
  workStartTime,
  workEndTime,
  timezone,
    setReferralSource,
    referralSource,
  workdays,
}) {
  const [isSaving, setIsSaving] = useState(false)

  const handleWorkdayChange = (day) => {
    setWorkdays((prevWorkdays) => ({
      ...prevWorkdays,
      [day]: !prevWorkdays[day],
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsSaving(true)

    const workdaysFormatted = Object.keys(workdays).filter(day => workdays[day])

    const settingsData = {
      workStartTime,
      workEndTime,
      timezone,
      workdays: workdaysFormatted,
      referralSource: referralSource,
    }

    try {
      await axiosInstance.post('/api/user/settings', settingsData)
      await completeOnboarding()
    } catch (error) {
      console.error('Error saving settings:', error)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-4">
        {/* Work Start Time */}
        <div className="space-y-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <Label htmlFor="workStartTime">Work Start Time</Label>
                  <Input
                      id="workStartTime"
                      type="time"
                      value={workStartTime}
                      onChange={(e) => setWorkStartTime(e.target.value)}
                      required
                      className="mt-1 white-calendar"
                  />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>The time you typically start working each day.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        {/* Work End Time */}
        <div className="space-y-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <Label htmlFor="workEndTime">Work End Time</Label>
                  <Input
                      id="workEndTime"
                      type="time"
                      value={workEndTime}
                      onChange={(e) => setWorkEndTime(e.target.value)}
                      required
                      className="mt-1 white-calendar"
                  />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>The time you usually finish working each day.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        {/* Timezone Selection */}
        <div className="space-y-2">
          <Label htmlFor="timezone">Time Zone</Label>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="w-full justify-between">
                {timezone || "Select Time Zone"}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="max-h-[300px] overflow-y-auto">
              <DropdownMenuLabel>Time Zones</DropdownMenuLabel>
              {timezones.map((zone) => (
                  <DropdownMenuItem
                      key={zone}
                      onSelect={() => setTimezone(zone)}
                  >
                    {zone}
                  </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        {/* Workdays Checkboxes */}
        <div className="space-y-2">
          <Label>Working Days</Label>
          <div className="grid grid-cols-2 gap-4">
            {Object.keys(workdays).map((day) => (
                <div key={day} className="flex items-center space-x-2">
                  <Checkbox
                      id={day}
                      checked={workdays[day]}
                      onCheckedChange={() => handleWorkdayChange(day)}
                  />
                  <Label
                      htmlFor={day}
                      className="font-normal"
                  >
                    {day}
                  </Label>
                </div>
            ))}
          </div>
        </div>

        {/* Referral Source */}
        <div className="space-y-2">
          <Label htmlFor="referralSource">How did you hear about us? (optional)</Label>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="w-full justify-between">
                {referralSource || "Select an option"}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="max-h-[300px] overflow-y-auto">
              {referralSources.map((source) => (
                  <DropdownMenuItem
                      key={source}
                      onSelect={() => setReferralSource(source)}
                  >
                    {source}
                  </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        {/* Submit Button */}
        <Button
            type="submit"
            className="w-full"
            disabled={isSaving}
        >
          {isSaving ? (
              <Loader2 className="mr-2 h-4 w-4 animate-spin"/>
          ) : (
              'Save & Continue'
          )}
        </Button>
      </div>
    </form>
  )
}

export default Step1WorkSetup