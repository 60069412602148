import React, { useEffect, useState } from 'react';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { Heart, CheckSquare, Target, Loader2, Calendar } from "lucide-react";
import { useTheme } from "../../ThemeProvider";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";
import { useToast } from "../../components/ui/toast";
import { Button } from "../../components/ui/button";
import "react-big-calendar/lib/css/react-big-calendar.css";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 0 }),
  getDay,
  locales,
});

const rgbToHsl = (r, g, b) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;
  if (max === min) {
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }
  return [h, s, l];
};

const hslToRgb = (h, s, l) => {
  let r, g, b;
  if (s === 0) {
    r = g = b = l;
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1/6) return p + (q - p) * 6 * t;
      if (t < 1/2) return q;
      if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1/3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1/3);
  }
  return [r * 255, g * 255, b * 255];
};

const darkenColor = (hex, percent) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  const hsl = rgbToHsl(r, g, b);
  hsl[2] = Math.max(0, hsl[2] * (1 - percent));
  const rgb = hslToRgb(hsl[0], hsl[1], hsl[2]);
  return '#' +
    Math.round(rgb[0]).toString(16).padStart(2, '0') +
    Math.round(rgb[1]).toString(16).padStart(2, '0') +
    Math.round(rgb[2]).toString(16).padStart(2, '0');
};

const CustomEvent = ({ event }) => {
  let Icon;
  switch (event.calendar_type) {
    case "habit":
      Icon = Heart;
      break;
    case "task":
      Icon = CheckSquare;
      break;
    case "project_task":
      Icon = Target;
      break;
    default:
      Icon = null;
  }

  return (
    <div className="flex items-center gap-1">
      <span className="flex-1 truncate text-xs">
        {event.title}
      </span>
      {Icon && (
        <Icon className="h-3 w-3 shrink-0 opacity-50" />
      )}
    </div>
  );
};

const CustomHeader = ({ label }) => {
  const [day, date] = label.split(" ");
  return (
    <div className="flex flex-col items-center p-1">
      <span className="text-xs text-muted-foreground lowercase opacity-70">
        {day}
      </span>
      <span className="mt-0.5 text-sm font-medium text-foreground">
        {date}
      </span>
    </div>
  );
};

const EmptyState = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 p-6">
      <Calendar className="h-12 w-12 text-muted-foreground" />
      <div className="text-center">
        <h3 className="font-medium">No Calendar Connected</h3>
        <p className="mt-1 text-sm text-muted-foreground">
          Connect your calendar to start seeing your events here
        </p>
      </div>
      <Button
        onClick={() => navigate('/settings')}
        className="mt-2"
      >
        Connect Calendar
      </Button>
    </div>
  );
};

const MiniCalendar = () => {
  const { theme } = useTheme();
  const { toast } = useToast();
  const { auth } = useAuth();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const timeToDate = (hours) => {
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  };

  const fetchEvents = async () => {
    try {
      const response = await axiosInstance.get("/api/outlook/events");
      const eventsData = response.data.events
        .filter(event => {
          const start = new Date(event.start);
          const end = new Date(event.end);

          // Only keep events that start before 5pm and end after 9am
          return start.getHours() < 17 && end.getHours() >= 9;
        })
        .map((event) => {
          const start = new Date(event.start);
          const end = new Date(event.end);

          // Clamp start time to 9am
          if (start.getHours() < 9) {
            start.setHours(9, 0, 0, 0);
          }

          // Clamp end time to 5pm
          if (end.getHours() >= 17) {
            end.setHours(17, 0, 0, 0);
          }

          return {
            title: event.title,
            start,
            end,
            calendar_type: event.calendar_type,
            color: event.color,
          };
        });

      setEvents(eventsData);
    } catch (err) {
      console.error("Error fetching events:", err);
      toast({
        title: "Error",
        description: "Failed to load events",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.calendarType) {
      fetchEvents();
      const interval = setInterval(fetchEvents, 5 * 60 * 1000);
      return () => clearInterval(interval);
    } else {
      setLoading(false);
    }
  }, [auth.calendarType]);

  const eventStyleGetter = (event) => {
    const backgroundColor = event.color || "hsl(var(--primary))";
    const textColor = darkenColor(backgroundColor, 0.47);
    const isDarkMode = theme === "dark";

    return {
      style: {
        backgroundColor: isDarkMode ? `${backgroundColor}4D` : backgroundColor,
        borderRadius: "4px",
        color: isDarkMode ? backgroundColor : textColor,
        border: isDarkMode ? `1px solid ${backgroundColor}` : "none",
        fontSize: "0.75rem",
        height: "22px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    };
  };

  const formats = {
    weekdayFormat: (date, culture, localizer) =>
      localizer.format(date, "EEE d", culture).toUpperCase(),
    timeGutterFormat: (date, culture, localizer) =>
      localizer.format(date, "h a", culture),
    eventTimeRangeFormat: () => "",
  };

  if (loading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader2 className="h-6 w-6 animate-spin text-muted-foreground" />
      </div>
    );
  }

  if (!auth.calendarType) {
    return <EmptyState />;
  }

  return (
    <div style={{ height: '100%' }}>
      <BigCalendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={['week']}
        defaultView='week'
        toolbar={false}
        min={timeToDate(9)}
        max={timeToDate(17)}
        eventPropGetter={eventStyleGetter}
        components={{
          event: CustomEvent,
          header: CustomHeader,
        }}
        formats={formats}
        dayLayoutAlgorithm="no-overlap"
        className="text-xs"
      />
    </div>
  );
};

export default MiniCalendar;