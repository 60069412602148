import {cn} from "../../lib/utils";
import {Card, CardContent} from "../../components/ui/card";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Suggestions from "./Suggestions"
import DorisLogo from "../../assets/DorisAILogo.webp"

const Message = ({ content, isUser, error, suggestions, onSuggestionClick, interim }) => {
  console.log('Message rendering:', { content, isUser, error, interim });
  const parseSuggestions = (content) => {
    const match = content.match(/\[SUGGESTIONS\]\s*(.*?)(?=\n|$)/);
    if (!match) return null;

    const options = match[1].split('|');
    return options.map(option => ({
      text: option.trim(),
      value: option.trim()
    }));
  };

  // Clean the content by removing the [SUGGESTIONS] line
  const cleanContent = content.replace(/\[SUGGESTIONS\].*(?=\n|$)/, '').trim();

  // Parse suggestions from content if they exist
  const parsedSuggestions = parseSuggestions(content);

  return (
    <div className="mb-4 flex w-full justify-center">
      <div
        className={cn(
          "flex w-full max-w-[1200px]",
          isUser ? "justify-end" : "justify-start",
          interim && "opacity-70" // Visual indication for streaming messages
        )}
      >
          {!isUser && (
          <div className="mr-2 flex items-start">
            <img
              src={DorisLogo}
              alt="Doris AI"
              className="h-8 w-8 rounded-full object-cover"
            />
          </div>
        )}
        <Card
          className={cn(
            "max-w-[90%] overflow-hidden",
            isUser ? "bg-primary text-primary-foreground" : "",
            error && "bg-destructive text-destructive-foreground"
          )}
        >
          <CardContent className="p-4">
            {isUser ? (
              <p>{content}</p>
            ) : (
              <>
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    p: ({ children }) => <p className="mb-4 last:mb-0">{children}</p>,
                    h1: ({ children }) => <h1 className="mb-4 text-2xl font-bold">{children}</h1>,
                    h2: ({ children }) => <h2 className="mb-3 text-xl font-semibold">{children}</h2>,
                    h3: ({ children }) => <h3 className="mb-2 text-lg font-medium">{children}</h3>,
                    ul: ({ children }) => <ul className="mb-4 list-inside list-disc">{children}</ul>,
                    ol: ({ children }) => <ol className="mb-4 list-inside list-decimal">{children}</ol>,
                    li: ({ children }) => <li className="mb-1">{children}</li>,
                    code: ({ node, inline, className, children, ...props }) => (
                      <code
                        className={cn(
                          "rounded bg-muted px-1.5 py-0.5 font-mono text-sm",
                          inline ? "inline" : "block p-4",
                          className
                        )}
                        {...props}
                      >
                        {children}
                      </code>
                    ),
                    blockquote: ({ children }) => (
                      <blockquote className="my-4 border-l-4 border-muted-foreground/20 pl-4 text-muted-foreground">
                        {children}
                      </blockquote>
                    ),
                    a: ({ href, children }) => (
                      <a
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={cn(
                          "underline underline-offset-4 hover:no-underline",
                          isUser ? "text-primary-foreground" : "text-primary"
                        )}
                      >
                        {children}
                      </a>
                    ),
                    table: ({ children }) => (
                      <table className="my-4 w-full border-collapse">{children}</table>
                    ),
                    th: ({ children }) => (
                      <th className="border border-border p-2 text-left">{children}</th>
                    ),
                    td: ({ children }) => (
                      <td className="border border-border p-2">{children}</td>
                    ),
                  }}
                >
                  {content
                    .split('[SUGGESTIONS]')[0]
                    .trim()}
                </ReactMarkdown>
                {content.includes('[SUGGESTIONS]') && (
                  <Suggestions
                    suggestions={content
                      .match(/\[SUGGESTIONS\]\s*(.*?)(?=\n|$)/)[1]
                      .split('|')
                      .map(option => ({
                        text: option.trim(),
                        value: option.trim()
                      }))}
                    onSuggestionClick={onSuggestionClick}
                  />
                )}
              </>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default Message