// pages/DorisAIPage.jsx
import React from 'react'
import { useAuth } from '../contexts/AuthContext'
import Layout from '../components/Layout'
import DorisChat from '../components/chat/DorisChat'

const DorisAIPage = () => {
  const { auth } = useAuth()

  return (
    <Layout>
      <div className="flex flex-col h-[calc(100vh-theme(spacing.16))]"> {/* Adjust the value based on your header height */}
        <div className="flex-1 overflow-hidden"> {/* Add overflow-hidden here */}
          <DorisChat />
        </div>
      </div>
    </Layout>
  )
}

export default DorisAIPage