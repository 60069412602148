import { useState, useEffect } from "react"
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card"
import { Alert, AlertDescription } from "../../components/ui/alert"
import { useAuth } from "../../contexts/AuthContext"
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts"
import axiosInstance from "../../api/axiosInstance"

function EmptyState({ reason = 'no-data' }) {
  const messages = {
    'no-data': {
      title: 'No capacity data yet',
      description: 'Connect your calendar and add tasks to start tracking your capacity.'
    },
    'incomplete-settings': {
      title: 'Settings needed',
      description: 'Please set your working hours in settings to view your capacity.'
    }
  }

  const content = messages[reason]

  return (
    <div className="flex h-full flex-col items-center justify-center p-6 text-center">
      <div className="mb-4 rounded-full bg-muted p-3">
        <svg
          className="h-6 w-6 text-muted-foreground"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          />
        </svg>
      </div>
      <h3 className="mb-1 text-lg font-medium">{content.title}</h3>
      <p className="text-sm text-muted-foreground">
        {content.description}
      </p>
    </div>
  )
}

function MiniCapacityChart() {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { auth } = useAuth()

  const COLORS = {
    used: "hsl(var(--chart-1))",
    stroke: "hsl(var(--chart-1))"
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        if (!auth.isOnboardingComplete) {
          setData(null)
          return
        }

        const { data: response } = await axiosInstance.get("/api/analytics/capacity")
        setData(response)
        setError(null)
      } catch (err) {
        console.error("Error fetching capacity data:", err)
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [auth.isOnboardingComplete])

  if (loading) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent" />
      </div>
    )
  }

  if (!auth.isOnboardingComplete) {
    return (
      <div className="flex h-full flex-col">
        <h3 className="mb-2 text-xl font-medium">Capacity Overview</h3>
        <div className="flex-1">
          <EmptyState reason="incomplete-settings" />
        </div>
      </div>
    )
  }

  if (error) {
    if (error.response?.status === 404 || error.message?.includes('no events')) {
      return (
        <div className="flex h-full flex-col">
          <h3 className="mb-2 text-xl font-medium">Capacity Overview</h3>
          <div className="flex-1">
            <EmptyState reason="no-data" />
          </div>
        </div>
      )
    }

    return (
      <div className="flex h-full items-center justify-center p-4">
        <Alert variant="destructive">
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      </div>
    )
  }

  if (!data || !data.days || data.days.length === 0) {
    return (
      <div className="flex h-full flex-col">
        <h3 className="mb-2 text-xl font-medium">Capacity Overview</h3>
        <div className="flex-1">
          <EmptyState reason="no-data" />
        </div>
      </div>
    )
  }

  const displayedData = data.days
    .filter(day => day.total_capacity_minutes > 0)
    .slice(0, 14)
    .map((day) => {
      const total = day.total_capacity_minutes
      const usedPercentage = (day.used_capacity_minutes / total) * 100

      return {
        date: day.date,
        used: Math.round(usedPercentage * 10) / 10 || 0,
      }
    })

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const used = payload[0].value || 0

      return (
        <div className="rounded-lg border bg-card p-2 shadow-sm">
          <p className="text-sm font-medium">{label}</p>
          <p className="text-xs text-muted-foreground">Used: {used.toFixed(1)}%</p>
          <p className="text-xs text-muted-foreground">Free: {(100 - used).toFixed(1)}%</p>
        </div>
      )
    }
    return null
  }

  return (
    <div className="flex h-full flex-col">
      <h3 className="mb-2 text-xl font-medium">Capacity Overview</h3>
      <div className="flex-1">
        <ResponsiveContainer>
          <AreaChart data={displayedData} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
            <defs>
              <linearGradient id="fillUsed" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={COLORS.used} stopOpacity={0.3} />
                <stop offset="95%" stopColor={COLORS.used} stopOpacity={0.05} />
              </linearGradient>
            </defs>
            <CartesianGrid
              strokeDasharray="3 3"
              className="stroke-border"
              vertical={false}
            />
            <XAxis
              dataKey="date"
              tick={{ fill: "currentColor", fontSize: 12 }}
              stroke="currentColor"
              tickLine={false}
              axisLine={false}
              tickMargin={4}
              minTickGap={32}
              tickFormatter={(value) => {
                const date = new Date(value)
                return date.toLocaleDateString("en-GB", {
                  day: 'numeric',
                  month: 'short'
                })
              }}
            />
            <YAxis
              domain={[0, 100]}
              unit="%"
              tick={{ fill: "currentColor", fontSize: 12 }}
              stroke="currentColor"
              tickLine={false}
              axisLine={false}
              tickCount={5}
              allowDataOverflow={false}
            />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="basis"
              dataKey="used"
              fill="url(#fillUsed)"
              stroke={COLORS.used}
              name="Used Capacity"
              strokeWidth={2}
              connectNulls={true}
              baseValue={0}
              dot={false}
              isAnimationActive={true}
              animationDuration={300}
              animationEasing="ease-in-out"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default MiniCapacityChart