import { Button } from "../ui/button"

const Suggestions = ({ suggestions, onSuggestionClick }) => {
  if (!suggestions || suggestions.length === 0) return null

  return (
    <div className="mt-2 flex flex-wrap gap-2">
      {suggestions.map((suggestion, index) => (
        <Button
          key={index}
          variant="secondary"
          size="sm"
          onClick={() => onSuggestionClick(suggestion.value)}
          className="rounded-full"
        >
          {suggestion.text}
        </Button>
      ))}
    </div>
  )
}

export default Suggestions