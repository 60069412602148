import { useState, useRef, useEffect } from "react";
import axiosInstance from "../../api/axiosInstance";
import { Alert, AlertDescription } from "../ui/alert";
import { Button } from "../ui/button";
import { Loader2, Trash2 } from "lucide-react";
import Message from "./Message";
import ChatInput from "./ChatInput";
import WelcomeScreen from "./WelcomeScreen";
import ThinkingIndicator from "../../components/chat/ThinkingIndicator";

const DorisChat = () => {
  // 1. Store all messages (user + assistant) in local state
  const [messages, setMessages] = useState(() => {
    const savedMessages = sessionStorage.getItem("doris_messages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [input, setInput] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [usage, setUsage] = useState(null);
  const [hasStartedChat, setHasStartedChat] = useState(() => {
    return sessionStorage.getItem("doris_started") === "true";
  });

  // Refs for automatic scrolling
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  /**
   * Clears local conversation state and resets session storage.
   */
  const resetChatState = () => {
    sessionStorage.removeItem("doris_messages");
    sessionStorage.removeItem("doris_started");
    setMessages([]);
    setHasStartedChat(false);
    setIsProcessing(false);
    setUsage(null);
  };

  /**
   * Sync messages with session storage.
   */
  useEffect(() => {
    sessionStorage.setItem("doris_messages", JSON.stringify(messages));
  }, [messages]);

  /**
   * Sync “started chat” flag with session storage.
   */
  useEffect(() => {
    sessionStorage.setItem("doris_started", hasStartedChat.toString());
  }, [hasStartedChat]);

  /**
   * Scroll to latest message whenever `messages` changes.
   */
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  /**
   * Optionally parse suggestions if your backend returns them in some format.
   */
  const parseSuggestions = (suggestionsStr) => {
    if (!suggestionsStr || !suggestionsStr.startsWith("[SUGGESTIONS]")) return null;
    const options = suggestionsStr.replace("[SUGGESTIONS]", "").trim().split("|");
    return options.map((option) => ({
      text: option.trim(),
      value: option.trim(),
    }));
  };

  /**
   * Main submit handler: sends user message + entire conversation history to the backend.
   */
  const handleSubmit = async (e, forcedMessage = null) => {
    e.preventDefault();
    const messageToSend = forcedMessage || input;
    if (!messageToSend.trim() || isProcessing) return;

    setInput("");
    setIsProcessing(true);
    setHasStartedChat(true);

    // Add user message to state
    const userMsgObj = { content: messageToSend, isUser: true };
    setMessages((prev) => [...prev, userMsgObj]);

    try {
      // Prepare conversation for backend
      const conversation_history = messages.map((m) => ({
        role: m.isUser ? "user" : "assistant",
        content: m.content,
      }));

      // POST to your updated /api/chat endpoint with function-calling
      const response = await axiosInstance.post("/api/chat", {
        message: messageToSend,
        history: conversation_history,
      });

      // If usage data is returned
      if (response.data.usage) {
        setUsage(response.data.usage);
      }

      // Add assistant reply to messages
      setMessages((prev) => [
        ...prev,
        {
          content: response.data.message,
          isUser: false,
          suggestions: response.data.suggestions
            ? parseSuggestions(response.data.suggestions)
            : null,
        },
      ]);
    } catch (err) {
      console.error("Chat error:", err);
      setError(err.message || "Connection error");
      setMessages((prev) => [
        ...prev,
        {
          content: "An error occurred while processing your message. Please try again.",
          isUser: false,
          error: true,
        },
      ]);
    } finally {
      setIsProcessing(false);
    }
  };

  /**
   * For any pre-set actions on the welcome screen.
   */
  const handleActionClick = (actionText) => {
    setHasStartedChat(true);
    // Force-run handleSubmit with the prefilled action text
    handleSubmit({ preventDefault: () => {} }, actionText);
  };

  /**
   * For handling suggestions returned by the backend.
   */
  const handleSuggestionClick = (suggestionValue) => {
    handleSubmit({ preventDefault: () => {} }, suggestionValue);
  };

  /**
   * “Delete” or “Clear” chat by resetting local state.
   */
  const deleteChat = () => {
    resetChatState();
  };

  /**
   * Render the ChatInput separately, so we can pass it to the WelcomeScreen.
   */
  const chatInput = (
    <ChatInput
      value={input}
      onChange={(e) => setInput(e.target.value)}
      onSubmit={handleSubmit}
      isProcessing={isProcessing}
      onDeleteChat={deleteChat}
      usage={usage}
    />
  );

  return (
    <div className="relative flex h-full w-full flex-col">
      {!hasStartedChat ? (
        // Show Welcome Screen plus the chat input if you want input available immediately
        <WelcomeScreen onActionClick={handleActionClick} chatInput={chatInput} />
      ) : (
        <>
          <div
            ref={chatContainerRef}
            className="flex-1 w-full overflow-y-auto p-6"
          >
            {messages.map((message, index) => (
              <Message
                key={index}
                content={message.content}
                isUser={message.isUser}
                error={message.error}
                suggestions={message.suggestions}
                onSuggestionClick={handleSuggestionClick}
              />
            ))}
            {isProcessing && <ThinkingIndicator />}
            <div ref={messagesEndRef} />
          </div>
          <div className="flex w-full justify-center border-t border-border bg-background p-4">
            {chatInput}
          </div>
        </>
      )}

      {error && (
        <Alert
          variant="destructive"
          className="fixed bottom-4 left-4 right-4 mx-auto max-w-md"
        >
          <AlertDescription>
            {error}
            <Button
              variant="outline"
              size="sm"
              className="ml-2"
              onClick={() => setError(null)}
            >
              Dismiss
            </Button>
          </AlertDescription>
        </Alert>
      )}
    </div>
  );
};

export default DorisChat;
