import React from 'react';
import ReactDOM from 'react-dom/client';
// Import RBC CSS before anything else
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { ThemeProvider } from './ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import { StyledEngineProvider } from '@mui/material/styles'; // Import this

const root = ReactDOM.createRoot(document.getElementById('root'));

function RootApp() {
  return (
    // Wrap everything with StyledEngineProvider and set injectFirst
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

root.render(<RootApp />);
