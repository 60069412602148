import React, { useState, useEffect, useMemo } from 'react';
import { useAuth } from "../../contexts/AuthContext"
import axiosInstance from "../../api/axiosInstance"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card"
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
    Label,
} from 'recharts'

const COLORS = {
  bar1: "hsl(var(--chart-1))",
  bar2: "hsl(var(--chart-2))",
  bar3: "hsl(var(--chart-3))",
  bar4: "hsl(var(--chart-4))",
  bar5: "hsl(var(--chart-5))",
};

const CustomLegend = ({ payload }) => {
  return (
    <div className="mt-4 flex flex-wrap justify-center gap-4">
      {payload.map((entry) => (
        <div key={entry.value} className="flex items-center gap-2">
          <div
            className="h-2 w-2 rounded-full"
            style={{ backgroundColor: entry.color }}
          />
          <span className="text-sm text-muted-foreground">
            {entry.value}
          </span>
        </div>
      ))}
    </div>
  );
};

const TimeByTagChart = () => {
  const { auth } = useAuth()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      if (!auth.isSubscribed) {
        // Set dummy data for non-subscribed users
        const dummyData = {
          tag_totals: [
            { tag: "Work", total_minutes: 480, percentage: 40 },
            { tag: "Study", total_minutes: 360, percentage: 30 },
            { tag: "Personal", total_minutes: 240, percentage: 20 },
            { tag: "Exercise", total_minutes: 120, percentage: 10 }
          ]
        };
        setData(dummyData);
        setLoading(false);
        return;
      }

      try {
        const response = await axiosInstance.get("/api/analytics/historic/time_by_tag", {
          params: {
            days: 30,
            type: 'all'
          }
        });
        setData(response.data);
        setError(null);
      } catch (err) {
        console.error("Error fetching time by tag data:", err);
        setError("Failed to load time distribution data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [auth.isSubscribed]);

  const totalMinutes = useMemo(() => {
    return data?.tag_totals?.reduce((acc, curr) => acc + curr.total_minutes, 0) || 0;
  }, [data]);

  const totalHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  if (loading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Historic time by tag</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex h-[400px] items-center justify-center">
            <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Historic time by tag</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex h-[400px] items-center justify-center text-muted-foreground">
            {error}
          </div>
        </CardContent>
      </Card>
    );
  }

  const chartData = data?.tag_totals || [];

  if (chartData.length === 0) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Historic time by tag</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[400px] w-full flex items-center justify-center text-muted-foreground">
            No tag data available
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Historic time by tag</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="h-[400px] w-full">
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={chartData}
                dataKey="total_minutes"
                nameKey="tag"
                cx="50%"
                cy="50%"
                innerRadius="60%"
                outerRadius="80%"
                paddingAngle={2}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[`bar${(index % 5) + 1}`]}
                    className="stroke-background"
                    strokeWidth={2}
                  />
                ))}
                <Label
                  content={({ viewBox }) => {
                    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                      return (
                        <text
                          x={viewBox.cx}
                          y={viewBox.cy}
                          textAnchor="middle"
                          dominantBaseline="middle"
                        >
                          <tspan
                            x={viewBox.cx}
                            y={viewBox.cy}
                            className="fill-foreground text-2xl font-bold"
                          >
                            {totalHours}h {remainingMinutes}m
                          </tspan>
                          <tspan
                            x={viewBox.cx}
                            y={(viewBox.cy || 0) + 20}
                            className="fill-muted-foreground text-sm"
                          >
                            Total Time
                          </tspan>
                        </text>
                      );
                    }
                  }}
                />
              </Pie>
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const data = payload[0].payload;
                    const minutes = data.total_minutes;
                    const hours = Math.floor(minutes / 60);
                    const remainingMinutes = minutes % 60;

                    return (
                      <div className="rounded-lg border bg-card p-3 shadow-sm backdrop-blur">
                        <p className="font-medium">{data.tag}</p>
                        <p className="text-sm text-muted-foreground">
                          Time: {hours}h {remainingMinutes}m
                        </p>
                        <p className="text-sm text-muted-foreground">
                          Percentage: {data.percentage.toFixed(1)}%
                        </p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Legend content={<CustomLegend />} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default TimeByTagChart;